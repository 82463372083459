import { Loader } from "@progress/kendo-react-indicators";
import { PanelBar, PanelBarItem } from "@progress/kendo-react-layout";
import { useEffect, useState } from "react";
import useAuth from "../../../hooks/useAuth";
import customerService from "../../../services/customer.service";
import { Customer } from "../../../types/customer/Customer";
import { AxiosError } from "axios";
import { Dictionary } from "../../../types/Dictionary";
import useLocale from "../../../hooks/useLocale";
import useSwal from "../../../hooks/useSwal";

const PlatformLicenses = () => {
  const auth = useAuth();
  const swal = useSwal();
  const localeCtx = useLocale();
  const [customerInfo, setCustomerInfo] = useState<Customer>();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>();
  const [translationsLoading, setTranslationsLoading] =
    useState<boolean>(false);
  const [translations, setTranslations] = useState<
    Dictionary<string> | undefined
  >(
    localeCtx?.selectedLocale?.current.componentTranslations["PlatformLicenses"]
  );

  useEffect(() => {
    if (
      !localeCtx?.selectedLocale?.current.componentTranslations[
        "PlatformLicenses"
      ]
    ) {
      fetchTranslations();
    }
  }, [localeCtx?.selectedLocale]);

  useEffect(() => {
    const getCustomerInfo = async () => {
      const customerId = auth?.tokenPayload?.CustomerId;
      if (customerId !== undefined && customerId > 0) {
        try {
          setLoading(true);
          const customer = await customerService.getCustomerDetailsById(
            customerId
          );
          setCustomerInfo(customer);
        } catch (err) {
          if (err instanceof AxiosError) {
            console.log(err);
            setError(err.response?.data?.error ?? err.message);
          }
        } finally {
          setLoading(false);
        }
      }
    };

    getCustomerInfo();
  }, []);

  const fetchTranslations = async () => {
    try {
      setTranslationsLoading(true);
      const resp = await localeCtx?.setComponentTranslations(
        "PlatformLicenses"
      );
      setTranslations(resp);
    } catch (err) {
      console.error(err);
      setTranslations(
        localeCtx?.selectedLocale?.previous.componentTranslations[
          "PlatformLicenses"
        ]
      );
      localeCtx?.setPreviousAppLocale("PlatformLicenses");
      if (localeCtx?.localeSwitchFailed) {
        swal.fire({
          icon: "error",
          title: "Error",
          text: "Couldn't Switch Language",
        });
      }
    } finally {
      setTimeout(() => {
        setTranslationsLoading(false);
      }, 100);
    }
  };

  const fetchLabelKeyTranslation = (
    key: string,
    defaultValue: string
  ): string => {
    return translations && translations[key] ? translations[key] : defaultValue;
  };

  return (
    <div className="m-b-10">
      <div className="row">
        <div className="col-md-12">
          <div className="trk-container lcsBox">
            <div className="trk-inr p-b-30">
              <div className="trk-t text-black-14 border-bottom-solid border-w-1 border-black-1">
                <span className="text-primary">
                  {" "}
                  {`${
                    translationsLoading
                      ? "License Details"
                      : fetchLabelKeyTranslation(
                          "PlatformLicensesTitle",
                          "License Details"
                        )
                  }`}
                </span>
              </div>
              <div className="accordionData float-left w-100">
                {loading && (
                  <div className="d-flex align-items-center justify-content-center m-t-30">
                    <Loader type={"infinite-spinner"} />
                  </div>
                )}
                {!loading && error && (
                  <div className="float-left w-100 m-t-10">
                    <div className="selectScorecard">
                      <div>
                        <span className="tx-red fs-14">{error}</span>
                      </div>
                    </div>
                  </div>
                )}
                {!loading && !error && (
                  <div className="panelbar-wrapper">
                    <PanelBar animation={true}>
                      {customerInfo?.platformDetails.map((p) => {
                        return (
                          <PanelBarItem
                            key={p.id}
                            title={p.platform}
                            expanded={true}
                            icon="folder"
                          >
                            <div className="trk-row border-bottom-solid border-w-1 border-black-1">
                              <div className="trkCol">
                                <div className="trkCol-h font-weight-semi w-35 text-black-8">
                                  {`${
                                    translationsLoading
                                      ? "License Name"
                                      : fetchLabelKeyTranslation(
                                          "PlatformLicensesTableCol1",
                                          "License Name"
                                        )
                                  }`}
                                </div>
                                <div className="trkCol-p w-35 text-black-8">
                                  {`${
                                    translationsLoading
                                      ? "Count"
                                      : fetchLabelKeyTranslation(
                                          "PlatformLicensesTableCol2",
                                          "Count"
                                        )
                                  }`}
                                </div>
                              </div>
                            </div>
                            <div
                              className="scroller float-left w-100"
                              style={{
                                maxHeight: "285px",
                                minHeight: "285px",
                                overflow: "hidden auto",
                              }}
                            >
                              {p.licenseCount.map((l) => {
                                return (
                                  <div
                                    key={l.license.id}
                                    className="trk-row border-bottom-solid border-w-1 border-black-1"
                                  >
                                    <div className="trkCol">
                                      <div className="trkCol-h font-weight-semi w-35">
                                        {l.license.licenseName}
                                      </div>
                                      {/* <div className="trkCol-dot">:</div> */}
                                      <div className="trkCol-p w-35 fs-18">
                                        {l.consumed}
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                          </PanelBarItem>
                        );
                      })}
                    </PanelBar>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PlatformLicenses;
