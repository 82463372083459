import { Popup } from "@progress/kendo-react-popup";
import React, { Fragment, useEffect } from "react";
import { useRef, useState } from "react";
import useLocale from "../../hooks/useLocale";
import { LocalizationCode } from "../../types/locale/LocalizationCode";
import { defaultLocaleCode } from "../../contexts/LocaleProvider";

const LanguageSelector: React.FC = () => {
  const localeCtx = useLocale();

  const LanguageAnchor = useRef<any>(null);
  const [showDropDown, setShowDropDown] = useState<boolean>(false);
  const [selectedLanguage, setSelectedLanguage] =
    useState<LocalizationCode>(defaultLocaleCode);

  useEffect(() => {
    if (localeCtx?.selectedLocale) {
      setSelectedLanguage(
        localeCtx?.localeSwitchFailed
          ? localeCtx?.selectedLocale?.previous.locale
          : localeCtx?.selectedLocale?.current.locale
      );
    }
  }, [localeCtx?.selectedLocale]);

  useEffect(() => {
    if (showDropDown) {
      const body = document.getElementById("root");
      const eventHandler = () => {
        setShowDropDown(false);
      };
      if (body != null) {
        body.addEventListener("click", eventHandler);
      }
      return () => body?.removeEventListener("click", eventHandler);
    }
  }, [showDropDown]);

  const onUserSelectLocale = (locale: LocalizationCode) => {
    localeCtx?.setUserLocale(locale);
    setShowDropDown(false);
  };

  return (
    <div className="filterButton">
      <div
        className="k-rounded-4"
        onClick={() => setShowDropDown(!showDropDown)}
        ref={LanguageAnchor}
      >
        <div
          className="popup-btn-link nav-link mt-1 cursor-pointer"
          style={{ paddingLeft: "5px" }}
        >
          <i className="bi bi-globe p-l-3 fs-15 m-r-5"></i>
          <span className="langText">{selectedLanguage?.displayName}</span>
          <i className="bi bi-chevron-down rotate-icon p-l-3"></i>
        </div>
      </div>
      <Popup
        className="kPopNone"
        popupClass={"popup-content"}
        show={showDropDown}
        anchor={LanguageAnchor.current}
      >
        <ul
          className="dropMenu droplist-ul"
          style={{
            maxWidth: "180px",
            width: "160px",
            top: "0",
            maxHeight: "300px",
            overflowY: "scroll",
            overflowX: "hidden",
          }}
        >
          {localeCtx?.supportedLocales.map((locale) => {
            return (
              <li
                key={locale.id}
                className={`droplist-li`}
                onClick={(e) => onUserSelectLocale(locale)}
              >
                <span
                  className={`droplist-a ${
                    selectedLanguage?.id === locale.id ? "activeFilter" : ""
                  }`}
                >
                  {locale.displayName}
                </span>
              </li>
            );
          })}
        </ul>
      </Popup>
    </div>
  );
};

export default LanguageSelector;
