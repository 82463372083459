import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import {
  Field,
  FormElement,
  Form,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { Switch, SwitchChangeEvent } from "@progress/kendo-react-inputs";
import React, { useEffect, useState } from "react";
import { useContext, useRef } from "react";
import CustomComboBox from "../../../../components/custom/form/CustomComboBox";
import CustomInput from "../../../../components/custom/form/CustomInput";
import CustomMultiSelect from "../../../../components/custom/form/CustomMultiSelect";
import CustomUserGridInfo from "../../../../components/custom/grid/CustomUserGridInfo";
import { AuthorizationProfile } from "../../../../types/authorization-profile/AuthorizationProfile";
import { TinyObj } from "../../../../types/TinyObj";
import { GroupRelation, TinyUser, User } from "../../../../types/user";
import useLocale from "../../../../hooks/useLocale";
import { Dictionary } from "../../../../types/Dictionary";
import { ListItemProps } from "@progress/kendo-react-dropdowns";
import userGroupsService from "../../../../services/usergroups.service";
import usersService from "../../../../services/users.service";
import authorizationProfileService from "../../../../services/authorizationprofile.service";
import useMasterData from "../../../../hooks/useMasterData";
import { EmailValidator } from "../../../../utils/validator";
import { Loader } from "@progress/kendo-react-indicators";
import { UpsertUser } from "../../../../types/user/UpsertUser";
import { Button } from "@progress/kendo-react-buttons";
import "./addUserDialog.css";
import useSwal from "../../../../hooks/useSwal";

interface IAddUserDialogProps {
  toggleDialog: () => void;
  addNewUser: (newUser: User) => void;
}

const AddUserDialog: React.FC<IAddUserDialogProps> = ({
  toggleDialog,
  addNewUser,
}) => {
  const masterData = useMasterData();
  const localeCtx = useLocale();
  const Swal = useSwal();
  const fetchTranslations = async () => {
    try {
      setTranslationsLoading(true);
      const resp = await localeCtx?.setComponentTranslations("AddUserDialog");
      setTranslations(resp);
    } catch (err) {
      console.error(err);
      setTranslations(
        localeCtx?.selectedLocale?.previous.componentTranslations[
          "AddUserDialog"
        ]
      );
      localeCtx?.setPreviousAppLocale("AddUserDialog");
      if (localeCtx?.localeSwitchFailed) {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Couldn't Switch Language",
        });
      }
    } finally {
      setTimeout(() => {
        setTranslationsLoading(false);
      }, 100);
    }
  };
  const fetchLabelKeyTranslation = (
    key: string,
    defaultValue: string
  ): string => {
    return translations && translations[key] ? translations[key] : defaultValue;
  };

  const [translationsLoading, setTranslationsLoading] =
    useState<boolean>(false);
  const [translations, setTranslations] = useState<
    Dictionary<string> | undefined
  >(localeCtx?.selectedLocale?.current.componentTranslations["AddUserDialog"]);

  useEffect(() => {
    if (
      !localeCtx?.selectedLocale?.current.componentTranslations["AddUserDialog"]
    ) {
      fetchTranslations();
    }
  }, [localeCtx?.selectedLocale]);

  const nameValidator = (value: string) => {
    if (!value) {
      return `${
        translationsLoading
          ? "Field cannot be empty"
          : fetchLabelKeyTranslation(
              "NameValidatorEmpty",
              "Field cannot be empty"
            )
      }`;
    }
    if (value.length > 100) {
      return `${
        translationsLoading
          ? "Field length cannot be more than 100!"
          : fetchLabelKeyTranslation(
              "NameValidatorCapacity",
              "Field length cannot be more than 100!"
            )
      }`;
    }

    return "";
  };

  const emailValidator = (email: string) => {
    if (EmailValidator(email)) {
      return "";
    }
    return `${
      translationsLoading
        ? "Enter Correct Email"
        : fetchLabelKeyTranslation("EmailValidator", "Enter Correct Email")
    }`;
  };

  const authProfileValidator = (authProfile: any) => {
    if (!authProfile) {
      return `${
        translationsLoading
          ? "Field cannot be empty"
          : fetchLabelKeyTranslation(
              "NameValidatorEmpty",
              "Field cannot be empty"
            )
      }`;
    }
    return "";
  };

  const formRef = useRef<any>();
  const [authProfiles, setAuthProfiles] = useState<
    AuthorizationProfile[] | undefined
  >(undefined);
  const [userGroups, setUserGroups] = useState<TinyObj[] | undefined>(
    undefined
  );
  const [loginEnabled, setLoginEnabled] = useState<boolean>(false);

  const updateLoginEnabled = (e: SwitchChangeEvent) => {
    setLoginEnabled(e.value);
  };

  useEffect(() => {
    LoadProfiles();
    LoadUserGroups();
  }, []);

  const LoadUserGroups = async () => {
    try {
      const data = await userGroupsService.getUserGroups();
      const groupData = data?.map((ele) => {
        let gp: TinyObj = {
          id: ele.id,
          name: ele.name,
        };
        return gp;
      });
      setUserGroups(groupData);
    } catch (ex) {
      if (ex instanceof Error) {
        console.log(ex);
      }
    } finally {
    }
  };

  const LoadProfiles = async () => {
    try {
      const data = await authorizationProfileService.getAuthorizationProfiles();
      setAuthProfiles(data);
    } catch (ex) {
      if (ex instanceof Error) {
        console.log(ex);
      }
    } finally {
    }
  };

  const submitHandler = async () => {
    const form = formRef.current as Form;
    const groupRelation: GroupRelation = {
      adminOf: [],
      memberOf: [],
      creatorOf: [],
      playbackNetwork: form.values.addViaGroup,
    };
    const newUser: UpsertUser = {
      email: form.values.email,
      firstName: form.values.firstName,
      lastName: form.values.lastName,
      managerEmailAddress: form.values.manager?.email,
      loginEnabled: loginEnabled,
      devices: [],
      authorizationProfileName: form.values.authProfile?.name,
      recordingNetworkAccessibility: form.values.addViaPeople?.name,
    };
    await usersService
      .insertUser(newUser)
      .then(async (user) => {
        await updateRecordingNetwork(user.id);
        Swal.fire({
          icon: "success",
          title: `${
            translationsLoading
              ? "User Added"
              : fetchLabelKeyTranslation("SwtAltUserAdded", "User Added")
          }`,
          confirmButtonText: `${
            translationsLoading
              ? "OK"
              : fetchLabelKeyTranslation("OKText", "OK")
          }`,
        });
        form.resetForm();
        addNewUser(user);
        toggleDialog();
      })
      .catch((err) => {
        console.log(err.response.status);
        if (err.response.status === 404) {
          Swal.fire({
            customClass: {
              container: "my-swal",
            },
            icon: "error",
            title: `${
              translationsLoading
                ? "Oops…"
                : fetchLabelKeyTranslation("SwtAltTitle", "Oops…")
            }`,
            text: `${
              translationsLoading
                ? "User Registration Failed"
                : fetchLabelKeyTranslation(
                    "SwtAltURFailed",
                    "User Registration Failed"
                  )
            }`,
            confirmButtonText: `${
              translationsLoading
                ? "OK"
                : fetchLabelKeyTranslation("OKText", "OK")
            }`,
          });
        } else if (err.response.status === 403) {
          console.log("403 Recieved");
          Swal.fire({
            customClass: {
              container: "my-swal",
            },
            icon: "error",
            title: `${
              translationsLoading
                ? "Oops…"
                : fetchLabelKeyTranslation("SwtAltTitle", "Oops…")
            }`,
            text: `${
              translationsLoading
                ? "Email Already Registered"
                : fetchLabelKeyTranslation(
                    "SwtAltEARegistered",
                    "Email Already Registered"
                  )
            }`,
            confirmButtonText: `${
              translationsLoading
                ? "OK"
                : fetchLabelKeyTranslation("OKText", "OK")
            }`,
          });
        } else if (err instanceof Error) {
          Swal.fire({
            customClass: {
              container: "my-swal",
            },
            icon: "error",
            title: `${
              translationsLoading
                ? "Oops…"
                : fetchLabelKeyTranslation("SwtAltTitle", "Oops…")
            }`,
            text: `${
              translationsLoading
                ? "Something went wrong! Please Try again…"
                : fetchLabelKeyTranslation(
                    "SwtAltWrong",
                    "Something went wrong! Please Try again…"
                  )
            }`,
            confirmButtonText: `${
              translationsLoading
                ? "OK"
                : fetchLabelKeyTranslation("OKText", "OK")
            }`,
          });
        }
      });
  };

  const updateRecordingNetwork = async (userId: number) => {
    const form = formRef.current as Form;
    if (form.values.addViaGroup !== null) {
      let updatedNetworkGroup = await usersService
        .updateRecordingNetworkViaGroup(form.values.addViaGroup, userId)
        .then((data) => {})
        .catch((err) => {
          console.log(err);
        });
    }
  };

  //   function generatePassword() {
  //     var length = 8,
  //         charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789",
  //         retVal = "";
  //     for (var i = 0, n = charset.length; i < length; ++i) {
  //         retVal += charset.charAt(Math.floor(Math.random() * n));
  //     }
  //     return retVal;
  // }

  const itemRender = (
    li: React.ReactElement<HTMLLIElement>,
    itemProps: ListItemProps
  ) => {
    const userInfo: TinyUser = {
      id: itemProps.dataItem.id,
      loginUserId: itemProps.dataItem.loginUserId,
      firstName: itemProps.dataItem.firstName,
      lastName: itemProps.dataItem.lastName,
      email: itemProps.dataItem.email,
      phoneNo: itemProps.dataItem.phoneNo,
      image: itemProps.dataItem.image,
      isArchived: itemProps.dataItem.isArchived,
    };
    const checkedclass = itemProps.selected ? "liChecked" : "";
    const itemChildren = (
      <span className={checkedclass}>
        <CustomUserGridInfo userInfo={userInfo} selected={itemProps.selected} />
      </span>
    );
    return React.cloneElement(li, li.props, itemChildren);
  };

  return (
    <Form
      ref={formRef}
      initialValues={{
        firstName: "",
        lastName: "",
        manager: null,
        email: "",
        authProfile: null,
        addViaPeople: null,
        addViaGroup: [],
      }}
      render={(formRenderProps: FormRenderProps) => (
        <FormElement style={{ maxWidth: 650 }}>
          <Dialog
            title={`${
              translationsLoading
                ? "Add User"
                : fetchLabelKeyTranslation("AddUserTitle", "Add User")
            }`}
            onClose={toggleDialog}
          >
            <div
              className="addUserBox"
              style={{ minWidth: "500px", maxWidth: "500px" }}
            >
              <div
                className="detailCol"
                style={{
                  maxHeight: "560px",
                  overflowY: "auto",
                  overflowX: "hidden",
                }}
              >
                <div className="detailColBox" style={{ maxHeight: "70vh" }}>
                  <div className="UserBoxLabel float-left w-100">
                    <div className="formBoxRow fs-14 font-weight-bold">
                      {`${
                        translationsLoading
                          ? "Basic Details"
                          : fetchLabelKeyTranslation(
                              "BasicDetailsTitle",
                              "Basic Details"
                            )
                      }`}
                    </div>
                    <div className="formBoxRow">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-col p-b-5">
                            <div className="form-group">
                              <div className="formLabel fs-14 text-black-11 p-b-3">
                                {`${
                                  translationsLoading
                                    ? "First Name"
                                    : fetchLabelKeyTranslation(
                                        "FirstNameTitle",
                                        "First Name"
                                      )
                                }`}
                              </div>
                              <div className="formControl">
                                <Field
                                  id="firstName"
                                  name="firstName"
                                  style={{ height: "32px" }}
                                  value={formRenderProps.valueGetter(
                                    "firstName"
                                  )}
                                  placeholder={`${
                                    translationsLoading
                                      ? "Enter First Name Here…."
                                      : fetchLabelKeyTranslation(
                                          "FirstNamePlaceHolder",
                                          "Enter First Name Here…."
                                        )
                                  }`}
                                  component={CustomInput}
                                  validator={nameValidator}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-col p-b-5">
                            <div className="form-group">
                              <div className="formLabel fs-14 text-black-11 p-b-3">
                                {`${
                                  translationsLoading
                                    ? "Last Name"
                                    : fetchLabelKeyTranslation(
                                        "LastNameTitle",
                                        "Last Name"
                                      )
                                }`}
                              </div>
                              <div className="formControl">
                                <Field
                                  id="lastName"
                                  name="lastName"
                                  style={{ height: "32px" }}
                                  value={formRenderProps.valueGetter(
                                    "lastName"
                                  )}
                                  placeholder={`${
                                    translationsLoading
                                      ? "Enter Last Name Here…."
                                      : fetchLabelKeyTranslation(
                                          "LastNamePlaceHolder",
                                          "Enter Last Name Here…."
                                        )
                                  }`}
                                  component={CustomInput}
                                  validator={nameValidator}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="formBoxRow">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-col p-b-5">
                            <div className="formBoxLabel fs-14">
                              {`${
                                translationsLoading
                                  ? "Line Manager"
                                  : fetchLabelKeyTranslation(
                                      "LineManagerTitle",
                                      "Line Manager"
                                    )
                              }`}
                            </div>
                            <div className="formBoxAction">
                              <div className="formInput">
                                {masterData?.users.length === 0 ? (
                                  <Loader
                                    size="small"
                                    type="infinite-spinner"
                                  />
                                ) : (
                                  <Field
                                    id="manager"
                                    name="manager"
                                    data={masterData?.users}
                                    style={{ height: "32px" }}
                                    value={formRenderProps.valueGetter(
                                      "manager"
                                    )}
                                    component={CustomComboBox}
                                    itemRender={itemRender}
                                    textField="email"
                                    filtering={true}
                                  />
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-col p-b-5">
                            <div className="formBoxLabel fs-14">{`${
                              translationsLoading
                                ? "Email"
                                : fetchLabelKeyTranslation(
                                    "EmailTitle",
                                    "Email"
                                  )
                            }`}</div>
                            <div className="formBoxAction">
                              <div className="formInput">
                                <Field
                                  id="email"
                                  name="email"
                                  style={{ height: "32px" }}
                                  value={formRenderProps.valueGetter("email")}
                                  placeholder={`${
                                    translationsLoading
                                      ? "Enter Email here....."
                                      : fetchLabelKeyTranslation(
                                          "EmailPlaceHolder",
                                          "Enter Email here....."
                                        )
                                  }`}
                                  component={CustomInput}
                                  validator={emailValidator}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="UserBoxLabel float-left w-100">
                    <div className="formBoxRow fs-14 font-weight-bold">
                      {`${
                        translationsLoading
                          ? "Login Details"
                          : fetchLabelKeyTranslation(
                              "LoginDetailsTitle",
                              "Login Details"
                            )
                      }`}
                    </div>
                    <div className="formBoxRow d-flex justify-content-between p-t-7 p-b-7">
                      <div className="formBoxLabel fs-14">{`${
                        translationsLoading
                          ? "Enable Login"
                          : fetchLabelKeyTranslation(
                              "EnableLoginTitle",
                              "Enable Login"
                            )
                      }`}</div>
                      <div className="formBoxAction">
                        <div className="switchButton">
                          <Switch
                            checked={loginEnabled}
                            onChange={updateLoginEnabled}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="UserBoxLabel float-left w-100 m-b-8">
                    <div className="formBoxRow fs-14 font-weight-bold">
                      {`${
                        translationsLoading
                          ? "Permissions"
                          : fetchLabelKeyTranslation(
                              "PermissionsTitle",
                              "Permissions"
                            )
                      }`}
                    </div>
                    <div className="formBoxRow p-t-5 p-b-5">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-col p-b-5">
                            <div className="formBoxLabel fs-14">
                              {`${
                                translationsLoading
                                  ? "Authorization Profile"
                                  : fetchLabelKeyTranslation(
                                      "AuthorizationProfileTitle",
                                      "Authorization Profile"
                                    )
                              }`}
                            </div>
                            <div className="formBoxAction">
                              <div className="formInput">
                                {!authProfiles ? (
                                  <Loader
                                    size="small"
                                    type="infinite-spinner"
                                  />
                                ) : (
                                  <Field
                                    id="authProfile"
                                    name="authProfile"
                                    data={authProfiles}
                                    style={{ height: "32px" }}
                                    value={formRenderProps.valueGetter(
                                      "authProfile"
                                    )}
                                    component={CustomComboBox}
                                    textField="name"
                                    filtering={true}
                                    validator={authProfileValidator}
                                  />
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="UserBoxLabel float-left w-100">
                    <div className="formBox">
                      <div className="formBoxRow fs-14 font-weight-bold">
                        {`${
                          translationsLoading
                            ? "Add More People To Playback Network"
                            : fetchLabelKeyTranslation(
                                "AddMorePeopleToPlaybackNetworkTitle",
                                "Add More People To Playback Network"
                              )
                        }`}
                      </div>
                      <div className="formBoxRow p-t-5 p-b-1">
                        <div className="formBoxLabel fs-14">{`${
                          translationsLoading
                            ? "Add Via People"
                            : fetchLabelKeyTranslation(
                                "AddViaPeopleTitle",
                                "Add Via People"
                              )
                        }`}</div>
                        <div className="formBoxAction">
                          <div className="formInput">
                            <Field
                              id="addViaPeople"
                              name="addViaPeople"
                              data={
                                masterData?.data?.recordingNetworkAccessibilties
                              }
                              style={{ height: "32px" }}
                              value={formRenderProps.valueGetter(
                                "addViaPeople"
                              )}
                              component={CustomComboBox}
                              textField="name"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="formBoxRow p-t-5 p-b-15">
                        <div className="formBoxLabel fs-14">
                          {`${
                            translationsLoading
                              ? "Add Via User Group"
                              : fetchLabelKeyTranslation(
                                  "AddViaUserGroupTitle",
                                  "Add Via User Group"
                                )
                          }`}
                        </div>
                        <div className="formBoxAction">
                          <div className="formInput">
                            {!userGroups ? (
                              <Loader size="small" type="infinite-spinner" />
                            ) : (
                              <Field
                                id="addViaGroup"
                                name="addViaGroup"
                                data={userGroups}
                                style={{ height: "32px" }}
                                value={formRenderProps.valueGetter(
                                  "addViaGroup"
                                )}
                                component={CustomMultiSelect}
                                textField="name"
                                filtering={true}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <DialogActionsBar>
              <Button className="btn bg-black-5" onClick={toggleDialog}>
                {`${
                  translationsLoading
                    ? "Cancel"
                    : fetchLabelKeyTranslation("CancelButton", "Cancel")
                }`}
              </Button>
              <Button
                className={`btn bg-primary text-white ${
                  !formRenderProps.valid ? "disabledBtn" : ""
                }`}
                onClick={submitHandler}
                disabled={!formRenderProps.valid}
              >
                {`${
                  translationsLoading
                    ? "Add"
                    : fetchLabelKeyTranslation("AddButton", "Add")
                }`}
              </Button>
            </DialogActionsBar>
          </Dialog>
        </FormElement>
      )}
    />
  );
};

export default AddUserDialog;
