import * as React from "react";
import * as ReactDOM from "react-dom";
import { Button } from "@progress/kendo-react-buttons";
import { useEffect, useState } from "react";
import { Tooltip } from "@progress/kendo-react-tooltip";
import {
  PanelBar,
  PanelBarItem,
  PanelBarUtils,
} from "@progress/kendo-react-layout";
import {
  TabStrip,
  TabStripSelectEventArguments,
  TabStripTab,
} from "@progress/kendo-react-layout";
import {
  Input,
  TextArea,
  MaskedTextBox,
  NumericTextBox,
  Slider,
  RangeSlider,
  Rating,
  ColorGradient,
  ColorPalette,
  ColorPicker,
  Switch,
  Checkbox,
  RadioButton,
  RadioGroup,
} from "@progress/kendo-react-inputs";

interface SystemSettingStorageProps {
  shared?: boolean;
}

const SystemSettingStorage: React.FC<SystemSettingStorageProps> = ({
  shared,
}) => {
  // storageTab
  const [selected, setSelected] = React.useState<number>(1);
  const handleSelect = (e: TabStripSelectEventArguments) => {
    setSelected(e.selected);
  };
  return (
    <div className="m-b-10">
      <div className="row">
        <div className="col-md-12">
          <div className="trk-container">
            <div className="trk-inr p-b-30">
              <div className="trk-t text-black-14 border-bottom-solid border-w-1 border-black-1">
                <span className="text-primary">Storage Details</span>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="float-left w-100 p-t-20 p-b-20">
                    <div className="border-w-2 border-c-2 border-black-4 border-dashed radius-5 p-15 p-l-20 d-flex align-items-center">
                      <div className="itmeBoxBtn float-left w-100">
                        <TabStrip selected={selected} onSelect={handleSelect}>
                          <TabStripTab title="AWS">
                            <div className="float-left w-100">
                              <div className="float-left w-100 fs-15 font-weight-bold text-black-11">
                                Add Details
                              </div>
                              <div className="float-left w-100"></div>
                            </div>
                          </TabStripTab>
                          <TabStripTab title="Google Cloud">
                            <div className="float-left w-100">
                              <div className="float-left w-100 fs-14 font-weight-bold text-black-11">
                                Add Details
                              </div>
                              <div className="float-left w-100">
                                <div className="addStorageForm">
                                  <div className="editableDataDiv float-left w-100">
                                    <div className="row">
                                      <div className="col-md-12">
                                        <div className="trk-row">
                                          <div className="trkCol d-flex align-items-center">
                                            <div
                                              className="trkCol-h font-weight-normal"
                                              style={{ minWidth: "150px" }}
                                            >
                                              Container Name
                                            </div>
                                            <div className="trkCol-dot">:</div>
                                            <div className="trkCol-p border-none">
                                              <span>
                                                <Input
                                                  style={{
                                                    height: "30px",
                                                    minWidth: "320px",
                                                  }}
                                                  type="text"
                                                />
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="trk-row">
                                          <div className="trkCol d-flex align-items-center">
                                            <div
                                              className="trkCol-h font-weight-normal"
                                              style={{ minWidth: "150px" }}
                                            >
                                              Email
                                            </div>
                                            <div className="trkCol-dot">:</div>
                                            <div className="trkCol-p border-none">
                                              <span>
                                                <Input
                                                  style={{
                                                    height: "30px",
                                                    minWidth: "320px",
                                                  }}
                                                  type="text"
                                                />
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="trk-row">
                                          <div className="trkCol d-flex align-items-center">
                                            <div
                                              className="trkCol-h font-weight-normal"
                                              style={{ minWidth: "150px" }}
                                            >
                                              Password
                                            </div>
                                            <div className="trkCol-dot">:</div>
                                            <div className="trkCol-p border-none">
                                              <span>
                                                <Input
                                                  style={{
                                                    height: "30px",
                                                    minWidth: "320px",
                                                  }}
                                                  type="password"
                                                />
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className="col-md-12 text-right p-b-5">
                                        <div className="btnRightDiv">
                                          <Button className="btn bg-black-5 m-r-5">
                                            Cancel
                                          </Button>
                                          <Button className="btn bg-primary text-white">
                                            Add
                                          </Button>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </TabStripTab>
                          <TabStripTab title="Azure">
                            <div>
                              <p>
                                Tallinn is the capital and largest city of
                                Estonia. It is situated on the northern coast of
                                the country, on the shore of the Gulf of
                              </p>
                            </div>
                          </TabStripTab>
                          <TabStripTab title="Webex" disabled={true} />
                        </TabStrip>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="accordionData float-left w-100">
                <div className="panelbar-wrapper">
                  <PanelBar animation={true}>
                    <PanelBarItem title="Azure" expanded={false} icon="folder">
                      <div className="float-left w-100 p-t-5 p-l-5 p-r-5">
                        <div className="visibleDataDiv float-left w-100">
                          <div className="row">
                            <div className="col-md-12">
                              <div className="float-left w-100 text-right p-t-5">
                                <Button
                                  className="btn bg-transparent text-black-12 border-primary text-primary m-r-5 p-r-7 p-l-7"
                                  style={{ height: "26px" }}
                                >
                                  <span className="p-r-5">Edit</span>
                                  <i className="bi bi-pencil"></i>
                                </Button>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-12">
                              <div className="trRowData float-left w-100 p-b-5">
                                <div className="trk-row">
                                  <div className="trkCol d-flex align-items-center">
                                    <div
                                      className="trkCol-h font-weight-semi"
                                      style={{ minWidth: "150px" }}
                                    >
                                      Container Name
                                    </div>
                                    <div className="trkCol-dot">:</div>
                                    <div className="trkCol-p border-none">
                                      <span>Tollring Support Data</span>
                                    </div>
                                  </div>
                                </div>
                                <div className="trk-row">
                                  <div className="trkCol d-flex align-items-center">
                                    <div
                                      className="trkCol-h font-weight-semi"
                                      style={{ minWidth: "150px" }}
                                    >
                                      Email
                                    </div>
                                    <div className="trkCol-dot">:</div>
                                    <div className="trkCol-p border-none">
                                      <span>labuser3.tollring@4com.im</span>
                                    </div>
                                  </div>
                                </div>
                                <div className="trk-row">
                                  <div className="trkCol d-flex align-items-center">
                                    <div
                                      className="trkCol-h font-weight-semi"
                                      style={{ minWidth: "150px" }}
                                    >
                                      Password
                                    </div>
                                    <div className="trkCol-dot">:</div>
                                    <div className="trkCol-p border-none">
                                      <span>nT83*******</span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="editableDataDiv float-left w-100">
                          <div className="row">
                            <div className="col-md-12">
                              <div className="trk-row">
                                <div className="trkCol d-flex align-items-center">
                                  <div
                                    className="trkCol-h font-weight-semi"
                                    style={{ minWidth: "150px" }}
                                  >
                                    Container Name
                                  </div>
                                  <div className="trkCol-dot">:</div>
                                  <div className="trkCol-p border-none">
                                    <span>
                                      <Input
                                        style={{
                                          height: "30px",
                                          minWidth: "320px",
                                        }}
                                        type="text"
                                      />
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div className="trk-row">
                                <div className="trkCol d-flex align-items-center">
                                  <div
                                    className="trkCol-h font-weight-semi"
                                    style={{ minWidth: "150px" }}
                                  >
                                    Email
                                  </div>
                                  <div className="trkCol-dot">:</div>
                                  <div className="trkCol-p border-none">
                                    <span>
                                      <Input
                                        style={{
                                          height: "30px",
                                          minWidth: "320px",
                                        }}
                                        type="text"
                                      />
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div className="trk-row">
                                <div className="trkCol d-flex align-items-center">
                                  <div
                                    className="trkCol-h font-weight-semi"
                                    style={{ minWidth: "150px" }}
                                  >
                                    Password
                                  </div>
                                  <div className="trkCol-dot">:</div>
                                  <div className="trkCol-p border-none">
                                    <span>
                                      <Input
                                        style={{
                                          height: "30px",
                                          minWidth: "320px",
                                        }}
                                        type="password"
                                      />
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-12 text-right p-b-5">
                              <div className="btnRightDiv">
                                <Button className="btn bg-black-5 m-r-5">
                                  Cancel
                                </Button>
                                <Button className="btn bg-primary text-white">
                                  Save
                                </Button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </PanelBarItem>
                    <PanelBarItem title="Webex" animation={true} icon="folder">
                      <div className="float-left w-100 p-t-5 p-l-5 p-r-5">
                        <div className="visibleDataDiv float-left w-100">
                          <div className="row">
                            <div className="col-md-12">
                              <div className="float-left w-100 text-right p-t-5">
                                <Button
                                  className="btn bg-transparent text-black-12 border-primary text-primary m-r-5 p-r-7 p-l-7"
                                  style={{ height: "26px" }}
                                >
                                  <span className="p-r-5">Edit</span>
                                  <i className="bi bi-pencil"></i>
                                </Button>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-12">
                              <div className="trRowData float-left w-100 p-b-5">
                                <div className="trk-row">
                                  <div className="trkCol d-flex align-items-center">
                                    <div
                                      className="trkCol-h font-weight-semi"
                                      style={{ minWidth: "150px" }}
                                    >
                                      Container Name
                                    </div>
                                    <div className="trkCol-dot">:</div>
                                    <div className="trkCol-p border-none">
                                      <span>Tollring Support Data</span>
                                    </div>
                                  </div>
                                </div>
                                <div className="trk-row">
                                  <div className="trkCol d-flex align-items-center">
                                    <div
                                      className="trkCol-h font-weight-semi"
                                      style={{ minWidth: "150px" }}
                                    >
                                      Email
                                    </div>
                                    <div className="trkCol-dot">:</div>
                                    <div className="trkCol-p border-none">
                                      <span>labuser3.tollring@4com.im</span>
                                    </div>
                                  </div>
                                </div>
                                <div className="trk-row">
                                  <div className="trkCol d-flex align-items-center">
                                    <div
                                      className="trkCol-h font-weight-semi"
                                      style={{ minWidth: "150px" }}
                                    >
                                      Password
                                    </div>
                                    <div className="trkCol-dot">:</div>
                                    <div className="trkCol-p border-none">
                                      <span>nT83*******</span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </PanelBarItem>
                    <PanelBarItem
                      title="Zoom Meeting"
                      animation={true}
                      icon="folder"
                    >
                      <div className="float-left w-100 p-t-5 p-l-5 p-r-5">
                        <div className="visibleDataDiv float-left w-100">
                          <div className="row">
                            <div className="col-md-12">
                              <div className="float-left w-100 text-right p-t-5">
                                <Button
                                  className="btn bg-transparent text-black-12 border-primary text-primary m-r-5 p-r-7 p-l-7"
                                  style={{ height: "26px" }}
                                >
                                  <span className="p-r-5">Edit</span>
                                  <i className="bi bi-pencil"></i>
                                </Button>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-12">
                              <div className="trRowData float-left w-100 p-b-5">
                                <div className="trk-row">
                                  <div className="trkCol d-flex align-items-center">
                                    <div
                                      className="trkCol-h font-weight-semi"
                                      style={{ minWidth: "150px" }}
                                    >
                                      Container Name
                                    </div>
                                    <div className="trkCol-dot">:</div>
                                    <div className="trkCol-p border-none">
                                      <span>Tollring Support Data</span>
                                    </div>
                                  </div>
                                </div>
                                <div className="trk-row">
                                  <div className="trkCol d-flex align-items-center">
                                    <div
                                      className="trkCol-h font-weight-semi"
                                      style={{ minWidth: "150px" }}
                                    >
                                      Email
                                    </div>
                                    <div className="trkCol-dot">:</div>
                                    <div className="trkCol-p border-none">
                                      <span>labuser3.tollring@4com.im</span>
                                    </div>
                                  </div>
                                </div>
                                <div className="trk-row">
                                  <div className="trkCol d-flex align-items-center">
                                    <div
                                      className="trkCol-h font-weight-semi"
                                      style={{ minWidth: "150px" }}
                                    >
                                      Password
                                    </div>
                                    <div className="trkCol-dot">:</div>
                                    <div className="trkCol-p border-none">
                                      <span>nT83*******</span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </PanelBarItem>
                    <PanelBarItem
                      title="HostingApplication"
                      animation={true}
                      icon="folder"
                    >
                      <div className="float-left w-100 p-t-5 p-l-5 p-r-5">
                        <div className="visibleDataDiv float-left w-100">
                          <div className="row">
                            <div className="col-md-12">
                              <div className="float-left w-100 text-right p-t-5">
                                <Button
                                  className="btn bg-transparent text-black-12 border-primary text-primary m-r-5 p-r-7 p-l-7"
                                  style={{ height: "26px" }}
                                >
                                  <span className="p-r-5">Edit</span>
                                  <i className="bi bi-pencil"></i>
                                </Button>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-12">
                              <div className="trRowData float-left w-100 p-b-5">
                                <div className="trk-row">
                                  <div className="trkCol d-flex align-items-center">
                                    <div
                                      className="trkCol-h font-weight-semi"
                                      style={{ minWidth: "150px" }}
                                    >
                                      Container Name
                                    </div>
                                    <div className="trkCol-dot">:</div>
                                    <div className="trkCol-p border-none">
                                      <span>Tollring Support Data</span>
                                    </div>
                                  </div>
                                </div>
                                <div className="trk-row">
                                  <div className="trkCol d-flex align-items-center">
                                    <div
                                      className="trkCol-h font-weight-semi"
                                      style={{ minWidth: "150px" }}
                                    >
                                      Email
                                    </div>
                                    <div className="trkCol-dot">:</div>
                                    <div className="trkCol-p border-none">
                                      <span>labuser3.tollring@4com.im</span>
                                    </div>
                                  </div>
                                </div>
                                <div className="trk-row">
                                  <div className="trkCol d-flex align-items-center">
                                    <div
                                      className="trkCol-h font-weight-semi"
                                      style={{ minWidth: "150px" }}
                                    >
                                      Password
                                    </div>
                                    <div className="trkCol-dot">:</div>
                                    <div className="trkCol-p border-none">
                                      <span>nT83*******</span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </PanelBarItem>
                  </PanelBar>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SystemSettingStorage;
