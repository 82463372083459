import { Button } from "@progress/kendo-react-buttons";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { Loader } from "@progress/kendo-react-indicators";
import React, { useEffect, useRef, useState } from "react";
import CustomFormTextArea from "../../../components/custom/form/CustomFormTextArea";
import CustomInput from "../../../components/custom/form/CustomInput";
import useLocale from "../../../hooks/useLocale";
import { CallResult } from "../../../types/call";
import { Dictionary } from "../../../types/Dictionary";
import { axiosWithAuth } from "../../../utils/customAxios";
import { CallResultWithReasonCode } from "./CallResult";
import { ReasonCodeInstance } from "../../../types/call/CallResultReasonCode";
import {
  ListItemProps,
  MultiSelect,
  MultiSelectChangeEvent,
} from "@progress/kendo-react-dropdowns";
import { Tooltip } from "@progress/kendo-react-tooltip";
import CustomMultiSelectFilteringWithoutForm from "../../../components/custom/form/CustomMultiSelectFilteringWithoutForm";
import useSwal from "../../../hooks/useSwal";
import { SweetAlertOptions } from "sweetalert2";

interface ICallResultDialogProps {
  toggleDialog: () => void;
  callResult?: CallResultWithReasonCode;
  upsertCallResult: (
    isUpdated: boolean,
    callResult: CallResultWithReasonCode
  ) => void;
  reasonCodes: ReasonCodeInstance[] | undefined;
}

const UpsertCallResultDialog: React.FC<ICallResultDialogProps> = ({
  toggleDialog,
  callResult,
  upsertCallResult,
  reasonCodes,
}) => {
  const localeCtx = useLocale();
  const swal = useSwal();
  const formRef = useRef<any>();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, seterror] = useState<string | undefined>();
  const [translationsLoading, setTranslationsLoading] =
    useState<boolean>(false);
  const [translations, setTranslations] = useState<
    Dictionary<string> | undefined
  >(
    localeCtx?.selectedLocale?.current.componentTranslations[
      "UpsertCallResultDialog"
    ]
  );
  const [reasonCodesDropdownValue, setReasonCodesDropdownValue] = useState<
    ReasonCodeInstance[] | undefined
  >(callResult?.reasonCode);

  useEffect(() => {
    if (
      !localeCtx?.selectedLocale?.current.componentTranslations[
        "UpsertCallResultDialog"
      ]
    ) {
      fetchTranslations();
    }
  }, [localeCtx?.selectedLocale]);

  const fetchTranslations = async () => {
    try {
      setTranslationsLoading(true);
      const resp = await localeCtx?.setComponentTranslations(
        "UpsertCallResultDialog"
      );
      setTranslations(resp);
    } catch (err) {
      console.error(err);
      setTranslations(
        localeCtx?.selectedLocale?.previous.componentTranslations[
          "UpsertCallResultDialog"
        ]
      );
      localeCtx?.setPreviousAppLocale("UpsertCallResultDialog");
      if (localeCtx?.localeSwitchFailed) {
        const swalOptions: SweetAlertOptions<any, any> = {
          icon: "error",
          title: "Error",
          text: "Couldn't Switch Language",
        };
        swal.fire(swalOptions);
      }
    } finally {
      setTimeout(() => {
        setTranslationsLoading(false);
      }, 100);
    }
  };

  const fetchLabelKeyTranslation = (
    key: string,
    defaultValue: string
  ): string => {
    return translations && translations[key] ? translations[key] : defaultValue;
  };

  const handleUpsert = (callResultVal?: CallResult) => {
    seterror(undefined);
    setLoading(true);
    const data: string[] = [];
    reasonCodesDropdownValue?.forEach((rc) => data.push(rc.name));
    callResult
      ? axiosWithAuth
          .put(`callResult/${callResult.callResult.id}`, callResultVal)
          .then((response) => {
            axiosWithAuth
              .put(`CallResult/${callResultVal?.id}/mapping`, data)
              .then((reasonCodesMapping) => {
                const swalOptions: SweetAlertOptions<any, any> = {
                  icon: "success",
                  title: `${
                    translationsLoading
                      ? "Updated"
                      : fetchLabelKeyTranslation("SwtAltUpdateTitle", "Updated")
                  }`,
                  text: `${
                    translationsLoading
                      ? "Outcome has been updated Successfully"
                      : fetchLabelKeyTranslation(
                          "SwtAltUpdateText",
                          "Outcome has been updated Successfully"
                        )
                  }`,
                  confirmButtonText: `${
                    translationsLoading
                      ? "OK"
                      : fetchLabelKeyTranslation("OKText", "OK")
                  }`,
                };
                swal.fire(swalOptions);
                upsertCallResult(true, {
                  callResult: response.data,
                  reasonCode: reasonCodesMapping.data,
                });
                toggleDialog();
              })
              .catch((error) => {
                seterror(
                  `${
                    translationsLoading
                      ? "Something went wrong"
                      : fetchLabelKeyTranslation(
                          "TextFailed",
                          "Something went wrong"
                        )
                  }`
                );
              });
            setLoading(false);
          })
          .catch((err) => {
            if (err.response.status === 405) {
              toggleDialog();
              const swalOptions: SweetAlertOptions<any, any> = {
                icon: "info",
                title: `${
                  translationsLoading
                    ? "Outcome cannot be updated"
                    : fetchLabelKeyTranslation(
                        "SwtAltUpdateFailedTitle",
                        "Outcome cannot be updated"
                      )
                }`,
                text: `${
                  translationsLoading
                    ? "You can archive the outcome"
                    : fetchLabelKeyTranslation(
                        "SwtAltUpdateFailedText",
                        "You can archive the outcome"
                      )
                }`,
                showCancelButton: true,
                confirmButtonText: `${
                  translationsLoading
                    ? "Yes, Archive it!"
                    : fetchLabelKeyTranslation(
                        "SwtAltCfnArchiveText",
                        "Yes, Archive it!"
                      )
                }`,
                cancelButtonText: `${
                  translationsLoading
                    ? "Cancel"
                    : fetchLabelKeyTranslation("CancelText", "Cancel")
                }`,
              };
              swal.fire(swalOptions).then(async (result) => {
                if (result.isConfirmed) {
                  const updatedCallResult: CallResultWithReasonCode =
                    callResult;
                  updatedCallResult.callResult.isArchived = true;
                  const result = await axiosWithAuth
                    .put(
                      `/callResult/${callResult.callResult.id}`,
                      updatedCallResult
                    )
                    .then((response) => {
                      const swalOptions: SweetAlertOptions<any, any> = {
                        icon: "success",
                        title: `${
                          translationsLoading
                            ? "Outcome Archived "
                            : fetchLabelKeyTranslation(
                                "SwtArchiveSuccessTitle",
                                "Outcome Archived "
                              )
                        }`,
                        text: `${
                          translationsLoading
                            ? "Outcome has been archived Sucessfully"
                            : fetchLabelKeyTranslation(
                                "SwtArchiveSuccessText",
                                "Outcome has been archived Sucessfully"
                              )
                        }`,
                        confirmButtonText: `${
                          translationsLoading
                            ? "OK"
                            : fetchLabelKeyTranslation("OKText", "OK")
                        }`,
                      };
                      swal.fire(swalOptions);
                      upsertCallResult(true, response.data);
                      toggleDialog();
                    });
                }
              });
            } else if (err.response.data.error === "Name already exists.") {
              seterror(
                `${
                  translationsLoading
                    ? "Outcome with same name already exists."
                    : fetchLabelKeyTranslation(
                        "InvalidNameTitle",
                        "Outcome with same name already exists."
                      )
                }`
              );
            } else {
              seterror(
                `${
                  translationsLoading
                    ? "Something went wrong"
                    : fetchLabelKeyTranslation(
                        "TextFailed",
                        "Something went wrong"
                      )
                }`
              );
            }
            setLoading(false);
          })
      : axiosWithAuth
          .post(`callResult`, callResultVal)
          .then((response) => {
            axiosWithAuth
              .put(`CallResult/${response.data.id}/mapping`, data)
              .then((reasonCodesMapping) => {
                const swalOptions: SweetAlertOptions<any, any> = {
                  icon: "success",
                  title: `${
                    translationsLoading
                      ? "Created"
                      : fetchLabelKeyTranslation(
                          "SwtAltCreatedTitle",
                          "Created"
                        )
                  }`,
                  text: `${
                    translationsLoading
                      ? "Outcome has been created successfully"
                      : fetchLabelKeyTranslation(
                          "SwtAltCreatedText",
                          "Outcome has been created successfully"
                        )
                  }`,
                  confirmButtonText: `${
                    translationsLoading
                      ? "OK"
                      : fetchLabelKeyTranslation("OKText", "OK")
                  }`,
                };
                swal.fire(swalOptions);
                upsertCallResult(false, {
                  callResult: response.data,
                  reasonCode: reasonCodesMapping.data,
                });
                toggleDialog();
              })
              .catch((error) => {
                seterror(
                  `${
                    translationsLoading
                      ? "Something went wrong"
                      : fetchLabelKeyTranslation(
                          "TextFailed",
                          "Something went wrong"
                        )
                  }`
                );
              });
            setLoading(false);
          })
          .catch((err) => {
            if (err.response.data.error === "Name already exists.") {
              seterror(
                `${
                  translationsLoading
                    ? "Outcome with same name already exists."
                    : fetchLabelKeyTranslation(
                        "InvalidNameTitle",
                        "Outcome with same name already exists."
                      )
                }`
              );
            } else {
              seterror(
                `${
                  translationsLoading
                    ? "Something went wrong"
                    : fetchLabelKeyTranslation(
                        "TextFailed",
                        "Something went wrong"
                      )
                }`
              );
            }
            setLoading(false);
          });
  };

  const submitHandler = () => {
    const form = formRef.current as Form;
    const callResultVal: CallResult = {
      id: callResult ? callResult.callResult.id : 0,
      text: form.values.text,
    };
    handleUpsert(callResultVal);
  };

  const nameValidator = (value: string) => {
    if (!value.trim()) {
      return `${
        translationsLoading
          ? "Outcome text cannot be empty"
          : fetchLabelKeyTranslation(
              "CallResNameEmptyTxt",
              "Outcome text cannot be empty"
            )
      }`;
    }
    if (value.length > 100) {
      return `${
        translationsLoading
          ? "Outcome text length cannot be more than 100!"
          : fetchLabelKeyTranslation(
              "CallResNameLenghtTxt",
              "Outcome text length cannot be more than 100!"
            )
      }`;
    }

    return "";
  };

  const itemRenderUser = (
    li: React.ReactElement<HTMLLIElement>,
    itemProps: ListItemProps
  ) => {
    const checkedclass = itemProps.selected ? "liChecked" : "";
    const itemChildren = (
      <span className={checkedclass}>
        <Tooltip anchorElement="target" parentTitle={true} position="top">
          <div className="keywordRow">
            <div className="keywordTrk bg-darkkhaki">
              <span>{itemProps.dataItem.name}</span>
            </div>
          </div>
        </Tooltip>
      </span>
    );
    return React.cloneElement(li, li.props, itemChildren);
  };

  const handleReasonCodeDropdown = (event: MultiSelectChangeEvent) => {
    setReasonCodesDropdownValue(event.value);
  };

  return (
    <Form
      ref={formRef}
      initialValues={{
        text: callResult?.callResult?.text ?? "",
      }}
      render={(formRenderProps: FormRenderProps) => (
        <FormElement style={{ maxWidth: 650 }}>
          <Dialog
            title={
              callResult
                ? `${
                    translationsLoading
                      ? "Edit Outcome"
                      : fetchLabelKeyTranslation(
                          "CallResTitle1",
                          "Edit Outcome"
                        )
                  }`
                : `${
                    translationsLoading
                      ? "Add Outcome"
                      : fetchLabelKeyTranslation("CallResTitle2", "Add Outcome")
                  }`
            }
            onClose={toggleDialog}
          >
            <div className="formAdd" style={{ marginBottom: "0px" }}>
              {error && <span className="tx-red">{error}</span>}
              <div className="formBoxRow p-t-5 p-b-5">
                <div className="formBoxLabel fs-14">
                  {`${
                    translationsLoading
                      ? "Text"
                      : fetchLabelKeyTranslation("TextField", "Text")
                  }`}{" "}
                </div>
                <div className="formBoxAction">
                  <div className="formInput">
                    <Field
                      id="scorecardName"
                      name="text"
                      style={{ height: "32px" }}
                      value={formRenderProps.valueGetter("scorecardName")}
                      placeholder={`${
                        translationsLoading
                          ? "Enter Outcome text..."
                          : fetchLabelKeyTranslation(
                              "TextFieldPlaceholder",
                              "Enter Outcome text..."
                            )
                      }`}
                      component={CustomInput}
                      validator={nameValidator}
                    />
                  </div>
                </div>
              </div>
              <div className="inputRow p-b-5">
                <div className="inputLabel">
                  {loading
                    ? "Reason Code"
                    : fetchLabelKeyTranslation("ReasonCodeText", "Reason Code")}
                </div>
                <div className="multiselectBox">
                  {!reasonCodes ? (
                    <Loader size="small" type="infinite-spinner" />
                  ) : (
                    <CustomMultiSelectFilteringWithoutForm
                      data={reasonCodes}
                      value={reasonCodesDropdownValue}
                      onChange={handleReasonCodeDropdown}
                      textField="name"
                      itemRender={itemRenderUser}
                      dataItemKey="id"
                    />
                  )}
                </div>
              </div>
            </div>
            <DialogActionsBar>
              <Button className={`btn bg-black-5`} onClick={toggleDialog}>
                {`${
                  translationsLoading
                    ? "Cancel"
                    : fetchLabelKeyTranslation("CancelText", "Cancel")
                }`}
              </Button>
              <Button
                className={`btn bg-primary text-white ${
                  !formRenderProps.valid ? "disabledBtn" : ""
                }`}
                onClick={submitHandler}
                disabled={!formRenderProps.valid}
              >
                {loading ? (
                  <Loader
                    themeColor={"primary"}
                    size={"small"}
                    type={"infinite-spinner"}
                  />
                ) : (
                  `${
                    callResult
                      ? `${
                          translationsLoading
                            ? "Update"
                            : fetchLabelKeyTranslation("TextUpdate", "Update")
                        }`
                      : `${
                          translationsLoading
                            ? "Add"
                            : fetchLabelKeyTranslation("TextAdd", "Add")
                        }`
                  }`
                )}
              </Button>
            </DialogActionsBar>
          </Dialog>
        </FormElement>
      )}
    />
  );
};

export default UpsertCallResultDialog;
