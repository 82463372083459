import React, { useContext, useEffect, useState } from "react";
import { Switch, Input } from "@progress/kendo-react-inputs";
import { Button } from "@progress/kendo-react-buttons";
import { ListItemProps } from "@progress/kendo-react-dropdowns";
import "./UserProfile.scss";
import { PlatformUserDetail, TinyUser, User } from "../../../../../types/user";
import CustomUserGridInfo from "../../../../../components/custom/grid/CustomUserGridInfo";
import { Loader } from "@progress/kendo-react-indicators";
import { AccessPermissionEnum } from "../../../../../enums/accessPermissionEnum";

import useLocale from "../../../../../hooks/useLocale";
import { Dictionary } from "../../../../../types/Dictionary";
import useAuth from "../../../../../hooks/useAuth";
import useMasterData from "../../../../../hooks/useMasterData";
import usersService from "../../../../../services/users.service";
import { EmailValidator } from "../../../../../utils/validator";
import { Error } from "@progress/kendo-react-labels";
import CustomComboBoxFilteringWithoutForm from "../../../../../components/custom/form/CustomComboBoxFilteringWithoutForm";
import { UpsertUser, UpsertUserDevices } from "../../../../../types/user/UpsertUser";
import AddDeviceDialog from "./AddDeviceDialog";
import DeviceDetails from "./DeviceDetails";
import { Customer } from "../../../../../types/customer";
import useSwal from "../../../../../hooks/useSwal";

interface IUserProfileProps {
  user: User;
  customerInfo: Customer | undefined;
  setChanged: Function;
  updateUserDetails: () => void;
}

const UserProfile: React.FC<IUserProfileProps> = ({
  user,
  customerInfo,
  setChanged,
  updateUserDetails,
}) => {
  const auth = useAuth();
  const Swal = useSwal()
  const localeCtx = useLocale();
  const masterData = useMasterData();
  const [loading, setLoading] = useState<boolean>(false);
  const [userDetails, setUserDetails] = useState<User>(user);
  const [valuesChanged, setValuesChanged] = useState<boolean>(false);
  const [validation, setValidation] = useState<boolean>(true);
  const [addDeviceVisible, setAddDeviceVisible] = React.useState<boolean>(false);
  const [translationsLoading, setTranslationsLoading] =
    useState<boolean>(false);
  const [translations, setTranslations] = useState<
    Dictionary<string> | undefined
  >(localeCtx?.selectedLocale?.current.componentTranslations["UserProfile"]);

  useEffect(() => {
    if (
      !localeCtx?.selectedLocale?.current.componentTranslations["UserProfile"]
    ) {
      fetchTranslations();
    }
  }, [localeCtx?.selectedLocale]);

  const fetchTranslations = async () => {
    try {
      setTranslationsLoading(true);
      const resp = await localeCtx?.setComponentTranslations("UserProfile");
      setTranslations(resp);
    } catch (err) {
      console.error(err);
      setTranslations(
        localeCtx?.selectedLocale?.previous.componentTranslations["UserProfile"]
      );
      localeCtx?.setPreviousAppLocale("UserProfile");
      if (localeCtx?.localeSwitchFailed) {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Couldn't Switch Language",
        });
      }
    } finally {
      setTimeout(() => {
        setTranslationsLoading(false);
      }, 100);
    }
  };

  const fetchLabelKeyTranslation = (
    key: string,
    defaultValue: string
  ): string => {
    return translations && translations[key] ? translations[key] : defaultValue;
  };

  useEffect(() => {
    if (JSON.stringify(userDetails) === JSON.stringify(user)) {
      setValuesChanged(false);
      setChanged(false);
    } else {
      setValuesChanged(true);
      setChanged(true);
    }
  }, [userDetails]);


  const reset = () => {
    setUserDetails(user);
  };

  const toggleAddDeviceDialog = () => {
    setAddDeviceVisible(!addDeviceVisible);
  };

  const handleUpdatedDeviceDetails = (details: PlatformUserDetail) => {
    var _devices: PlatformUserDetail[] = [];
    userDetails.devices.forEach((ele) => {
      if (ele.id === details.id) {
        _devices.push(details);
      } else {
        _devices.push(ele);
      }
    });
    setUserDetails({ ...userDetails, devices: _devices });
  };

  const onSaveClicked = async () => {
    if (!validation) {
      Swal.fire({
        icon: "error",
        title: "Email address",
        text: "Please enter a valid email address",
        confirmButtonText: `${translationsLoading ? "OK" : fetchLabelKeyTranslation("OKText", "OK")
          }`,
      });
    } else {
      setLoading(true);
      Promise.all([updateUserInfo()])
        .then((responses) => {
          setLoading(false);
          Swal.fire({
            icon: "success",
            title: `${translationsLoading
              ? "Updated"
              : fetchLabelKeyTranslation("SwtAltUpdatedTitle", "Updated")
              }`,
            text: `${translationsLoading
              ? "User has been updated"
              : fetchLabelKeyTranslation(
                "SwtAltUpdate",
                "User has been updated"
              )
              }`,
            confirmButtonText: `${translationsLoading
              ? "OK"
              : fetchLabelKeyTranslation("OKText", "OK")
              }`,
          });
          updateUserDetails();
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
          if (err.response.status === 403) {
            Swal.fire({
              icon: "error",
              title: `${translationsLoading
                ? "Error"
                : fetchLabelKeyTranslation("SwtAltErrorTitle", "Error")
                }`,
              text: `${translationsLoading
                ? "Email Already Register"
                : fetchLabelKeyTranslation(
                  "SwtAltEARegistered",
                  "Email Already Register"
                )
                }`,
              confirmButtonText: `${translationsLoading
                ? "OK"
                : fetchLabelKeyTranslation("OKText", "OK")
                }`,
            });
          } else if (err.response.status === 404) {
            Swal.fire({
              icon: "error",
              title: `${translationsLoading
                ? "Error"
                : fetchLabelKeyTranslation("SwtAltErrorTitle", "Error")
                }`,
              text: `${translationsLoading
                ? "User Registration failed"
                : fetchLabelKeyTranslation(
                  "SwtAltURFailed",
                  "User Registration failed"
                )
                }`,
              confirmButtonText: `${translationsLoading
                ? "OK"
                : fetchLabelKeyTranslation("OKText", "OK")
                }`,
            });
          } else {
            Swal.fire({
              icon: "error",
              title: `${translationsLoading
                ? "Error"
                : fetchLabelKeyTranslation("SwtAltErrorTitle", "Error")
                }`,
              text: `${translationsLoading
                ? "Error Updating User"
                : fetchLabelKeyTranslation(
                  "SwtAltUpdateFailed",
                  "Error Updating User"
                )
                }`,
              confirmButtonText: `${translationsLoading
                ? "OK"
                : fetchLabelKeyTranslation("OKText", "OK")
                }`,
            });
          }
        });
    }
  };

  const updateUserInfo = async () => {
    const upsertUser: UpsertUser = {
      email: userDetails.email,
      firstName: userDetails.firstName,
      lastName: userDetails.lastName,
      managerEmailAddress: userDetails.manager?.email,
      countryName: userDetails.country?.name,
      timezoneIdentifier: userDetails.timezone?.timezoneIdentifier,
      localeCode: userDetails.locale?.code,
      loginEnabled: userDetails.loginEnabled,
      devices: userDetails.devices.map(ele => {
        var upsertDevice: UpsertUserDevices = {
          devicePlatform: ele.platform,
          deviceIdentifier: ele.deviceIdentifier,
          license: ele.license?.licenseName,
          recordingPolicy: ele.recordingPolicy ? {
            isIncludedForRecording: ele.recordingPolicy?.isIncludedForRecording,
            callSubsetTypeName: ele.recordingPolicy?.callSubset?.name ?? 'All Calls'
          }
            : undefined
        }
        return upsertDevice;
      }),
      authorizationProfileName: userDetails.authorizationProfile?.name,
      recordingNetworkAccessibility: userDetails.recordingNetworkAccessibilty?.name
    };
    let updatedUser = await usersService
      .updateUser(upsertUser, user.id)
      .then((data) => { })
      .catch((err) => {
        throw err;
      });
  };


  const itemRender = (
    li: React.ReactElement<HTMLLIElement>,
    itemProps: ListItemProps
  ) => {
    const userInfo: TinyUser = {
      id: itemProps.dataItem.id,
      loginUserId: itemProps.dataItem.loginUserId, 
      firstName: itemProps.dataItem.firstName,
      lastName: itemProps.dataItem.lastName,
      email: itemProps.dataItem.email,
      phoneNo: itemProps.dataItem.phoneNo,
      image: itemProps.dataItem.image,
      isArchived: itemProps.dataItem.isArchived
    };
    const itemChildren = (
      <div>
        <CustomUserGridInfo userInfo={userInfo} />
      </div>
    );
    return React.cloneElement(li, li.props, itemChildren);
  };


  return (
    <div className="tabInner">
      <div className="row">
        <div className="col-md-12">
          <div className="detailSection p-t-16 p-r-15 p-l-25">
            <div className="detailRow">
              <div className="row">
                <div className="col-md-4">
                  <div className="detailCol">
                    <div className="detailColTitle fs-15 text-default font-weight-semi p-b-5 m-b-10 border-bottom-solid border-w-1 border-black-1">
                      {translationsLoading ? "User Details"
                        : fetchLabelKeyTranslation("UserDetailsTitle", "User Details")
                      }
                    </div>
                    <div className="detailColBox">
                      <div className="formBox">
                        <div className="formBoxRow d-flex justify-content-between p-t-7 p-b-7">
                          <div className="formBoxLabel fs-14">
                            {translationsLoading ? "Enable Login"
                              : fetchLabelKeyTranslation("EnableLoginTitle", "Enable Login")
                            }
                          </div>
                          <div className="formBoxAction">
                            <div className="switchButton">
                              <Switch
                                checked={userDetails.loginEnabled}
                                onChange={(e) => {
                                  setUserDetails({
                                    ...userDetails,
                                    loginEnabled: e.value,
                                  });
                                }}
                                disabled={
                                  !auth?.checkUserAccess(
                                    AccessPermissionEnum.ManageUsersAndGroups
                                  )
                                }
                              />
                            </div>
                          </div>
                        </div>
                        <div className="formBoxRow p-t-5 p-b-5">
                          <div className="formBoxLabel fs-14">
                            {translationsLoading ? "Email"
                              : fetchLabelKeyTranslation("EmailTitle", "Email")
                            }
                          </div>
                          <div className="formBoxAction">
                            <div className="formInput">
                              <Input
                                onBlur={() =>
                                  setValidation(EmailValidator(userDetails.email))
                                }
                                value={userDetails.email}
                                style={{ height: "32px" }}
                                onChange={(e) => {
                                  setUserDetails({
                                    ...userDetails,
                                    email: e.value,
                                  });
                                }}
                                disabled={
                                  !auth?.checkUserAccess(
                                    AccessPermissionEnum.ManageUsersAndGroups
                                  )
                                }
                              />
                              {!validation ? (
                                <Error>Please provide a valid email address</Error>
                              ) : null}
                            </div>
                          </div>
                        </div>
                        <div className="formBoxRow p-t-5 p-b-5">
                          <div className="row">
                            <div className="col-md-6">
                              <div className="form-col p-b-15">
                                <div className="form-group">
                                  <div className="formLabel fs-14 text-black-11 p-b-3">
                                    {translationsLoading ? "First Name"
                                      : fetchLabelKeyTranslation("FirstNameTitle", "First Name")
                                    }
                                  </div>
                                  <div className="formControl">
                                    <Input
                                      value={userDetails.firstName}
                                      style={{ height: "32px" }}
                                      onChange={(e) => {
                                        setUserDetails({
                                          ...userDetails,
                                          firstName: e.value,
                                        });
                                      }}
                                      disabled={
                                        !auth?.checkUserAccess(
                                          AccessPermissionEnum.ManageUsersAndGroups
                                        )
                                      }
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-col p-b-15">
                                <div className="form-group">
                                  <div className="formLabel fs-14 text-black-11 p-b-3">
                                    {translationsLoading ? "Last Name"
                                      : fetchLabelKeyTranslation("LastNameTitle", "Last Name")
                                    }
                                  </div>
                                  <div className="formControl">
                                    <Input
                                      value={userDetails.lastName}
                                      style={{ height: "32px" }}
                                      onChange={(e) => {
                                        setUserDetails({
                                          ...userDetails,
                                          lastName: e.value,
                                        });
                                      }}
                                      disabled={
                                        !auth?.checkUserAccess(
                                          AccessPermissionEnum.ManageUsersAndGroups
                                        )
                                      }
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="formBoxRow p-t-5 p-b-5">
                          <div className="formBoxLabel fs-14">
                            {translationsLoading ? "Line Manager"
                              : fetchLabelKeyTranslation("LineManagerTitle", "Line Manager")
                            }
                          </div>
                          <div className="formBoxAction">
                            <div className="formInput">
                              {masterData?.users.length === 0 ? (
                                <Loader size="small" type="infinite-spinner" />
                              ) : (
                                <CustomComboBoxFilteringWithoutForm
                                  dataItemKey="id"
                                  data={masterData?.users}
                                  itemRender={itemRender}
                                  value={
                                    userDetails.manager ? userDetails.manager : ""
                                  }
                                  onChange={(e) => {
                                    setUserDetails({
                                      ...userDetails,
                                      manager: e.value,
                                    });
                                  }}
                                  textField="email"
                                  disabled={
                                    !auth?.checkUserAccess(
                                      AccessPermissionEnum.ManageUsersAndGroups
                                    )
                                  }
                                />
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-8 cardLab">
                  <div className="detailColOuterLabel h-100 w-100 float-left p-l-15 border-l-1 border-solid-black border-left-solid border-left-w-1 border-black-1">
                    <div className="detailCol">
                      <div className="float-left w-100 p-l-10 p-r-10">
                        <div className="detailColTitle float-left w-100 fs-15 text-default font-weight-semi p-b-5 border-bottom-solid border-w-1 border-black-1">
                          <div className="d-flex justify-content-between align-items-center">
                            <span>
                              {translationsLoading ? "Device"
                                : fetchLabelKeyTranslation("DeviceTab", "Device")
                              }
                            </span>
                            <Button
                              onClick={toggleAddDeviceDialog}
                              className={`btn bg-primary text-white fs-13 fw-normal 
                              ${!auth?.checkUserAccess(AccessPermissionEnum.ManageUsersAndGroups)
                                  ? "disabledBtn"
                                  : ""
                                }`}
                              style={{ height: "29px", margin: "-1px 0 0 0" }}
                              disabled={!auth?.checkUserAccess(AccessPermissionEnum.ManageUsersAndGroups)}
                              title={`${translationsLoading
                                ? "Attach Device"
                                : fetchLabelKeyTranslation("AttachDeviceButtonTitle", "Attach Device")
                                }`}
                            >
                              <i className="bi bi-plus fs-20"></i>
                              {translationsLoading ? "Attach Device"
                                : fetchLabelKeyTranslation("AttachDeviceButtonTitle", "Attach Device")
                              }
                            </Button>
                            {addDeviceVisible && (
                              <AddDeviceDialog
                                toggleDialog={toggleAddDeviceDialog}
                                userDevices={[...userDetails.devices]}
                                upsertDevice={(updateddevice) => {
                                  setUserDetails({
                                    ...userDetails,
                                    devices: updateddevice
                                  })
                                }}
                                user={user}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="detailColBox float-left w-100">
                        <div className="formBox">
                          <div className="dvcList">
                            <div
                              className="listOverflowDvc float-left w-100 p-12"
                              style={{ maxHeight: "68vh", overflowY: "auto" }}
                            >
                              {userDetails.devices && userDetails.devices.length > 0 ?
                                userDetails.devices.map((userDevice) => {
                                  return (
                                    <DeviceDetails
                                      licenseCount={
                                        customerInfo?.platformDetails.find(
                                          (el) => el.platform === userDevice.platform
                                        )?.licenseCount}
                                      details={userDevice}
                                      updatedDeviceDetails={handleUpdatedDeviceDetails}
                                    />
                                  )
                                })
                                :
                                <div className="col-md-12 p-l-5">
                                  <div className="dashedBox p-10 m-b-20 float-left w-100 radius-6 border-black-3 border-w-2 border-dashed d-flex align-items-center justify-content-center">
                                    <span className="fs-13 text-black-9 p-t-20 p-b-20">
                                      {translationsLoading ? "No Devices Available"
                                        : fetchLabelKeyTranslation("NoDevicesAvailableTxt", "No Devices Available")
                                      }
                                    </span>
                                  </div>
                                </div>
                              }
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {valuesChanged && (
              <div className="row">
                <div className="col-md-12">
                  <div className="float-left w-100 p-t-12 p-b-6 p-l-15 p-r-15 m-t-10 text-right border-top-solid border-w-1 border-black-1">
                    <Button
                      className={`btn bg-black-5 fs-15 padding-6 p-l-15 p-r-15 ${loading ? "disabledBtn" : ""
                        }`}
                      disabled={loading}
                      onClick={() => reset()}
                    >
                      {`${translationsLoading
                        ? "Cancel"
                        : fetchLabelKeyTranslation("CancelButton", "Cancel")
                        }`}
                    </Button>
                    <Button
                      className={`btn bg-primary fs-15 text-white padding-6 p-l-15 p-r-15 ${loading ? "disabledBtn" : ""
                        }`}
                      disabled={loading}
                      onClick={onSaveClicked}
                    >
                      {loading ? (
                        <Loader
                          themeColor="light"
                          size="small"
                          type="infinite-spinner"
                        />
                      ) : (
                        "Save"
                      )}
                    </Button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserProfile;