import * as React from "react";
import * as ReactDOM from "react-dom";
import { Button } from "@progress/kendo-react-buttons";
import { useEffect, useState } from "react";
import { Tooltip } from "@progress/kendo-react-tooltip";

interface SystemSettingLicenseProps {
  shared?: boolean;
}

const SystemSettingLicense: React.FC<SystemSettingLicenseProps> = ({
  shared,
}) => {
  return (
    <div className="m-b-10">
      <div className="row">
        <div className="col-md-12">
          <div className="trk-container">
            <div className="trk-inr p-b-30">
              <div className="trk-t text-black-14 border-bottom-solid border-w-1 border-black-1">
                <span className="text-primary">License Details</span>
              </div>
              <div className="trk-row border-bottom-solid border-w-1 border-black-1">
                <div className="trkCol">
                  <div className="trkCol-h font-weight-semi w-30 text-black-8">
                    License Name
                  </div>
                  <div className="trkCol-p w-30 text-black-8">User Count</div>
                </div>
              </div>
              <div
                className="scroller float-left w-100"
                style={{
                  maxHeight: "285px",
                  minHeight: "285px",
                  overflow: "hidden auto",
                }}
              >
                <div className="trk-row border-bottom-solid border-w-1 border-black-1">
                  <div className="trkCol">
                    <div className="trkCol-h font-weight-semi w-30">
                      Broadworks
                    </div>
                    {/* <div className="trkCol-dot">:</div> */}
                    <div className="trkCol-p w-30 fs-18">750</div>
                  </div>
                </div>
                <div className="trk-row border-bottom-solid border-w-1 border-black-1">
                  <div className="trkCol">
                    <div className="trkCol-h font-weight-semi w-30">Webex</div>
                    {/* <div className="trkCol-dot">:</div> */}
                    <div className="trkCol-p w-30 fs-18">147</div>
                  </div>
                </div>
                <div className="trk-row border-bottom-solid border-w-1 border-black-1">
                  <div className="trkCol">
                    <div className="trkCol-h font-weight-semi w-30">
                      Microsoft
                    </div>
                    {/* <div className="trkCol-dot">:</div> */}
                    <div className="trkCol-p w-30 fs-18">2495</div>
                  </div>
                </div>
                <div className="trk-row border-bottom-solid border-w-1 border-black-1">
                  <div className="trkCol">
                    <div className="trkCol-h font-weight-semi w-30">
                      Zoom calling
                    </div>
                    {/* <div className="trkCol-dot">:</div> */}
                    <div className="trkCol-p w-30 fs-18">380</div>
                  </div>
                </div>
                <div className="trk-row border-bottom-solid border-w-1 border-black-1">
                  <div className="trkCol">
                    <div className="trkCol-h font-weight-semi w-30">
                      Video Recording
                    </div>
                    {/* <div className="trkCol-dot">:</div> */}
                    <div className="trkCol-p w-30 fs-18">75</div>
                  </div>
                </div>
                <div className="trk-row border-bottom-solid border-w-1 border-black-1">
                  <div className="trkCol">
                    <div className="trkCol-h font-weight-semi w-30">
                      Support Management
                    </div>
                    {/* <div className="trkCol-dot">:</div> */}
                    <div className="trkCol-p w-30 fs-18">247</div>
                  </div>
                </div>
                <div className="trk-row border-bottom-solid border-w-1 border-black-1">
                  <div className="trkCol">
                    <div className="trkCol-h font-weight-semi w-30">
                      Recruitment
                    </div>
                    {/* <div className="trkCol-dot">:</div> */}
                    <div className="trkCol-p w-30 fs-18">83</div>
                  </div>
                </div>
                <div className="trk-row border-bottom-solid border-w-1 border-black-1">
                  <div className="trkCol">
                    <div className="trkCol-h font-weight-semi w-30">
                      Chatbox Viewer
                    </div>
                    {/* <div className="trkCol-dot">:</div> */}
                    <div className="trkCol-p w-30 fs-18">109</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SystemSettingLicense;
