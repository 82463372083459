import { filterBy } from "@progress/kendo-data-query";
import {
  MultiSelect,
  MultiSelectFilterChangeEvent,
} from "@progress/kendo-react-dropdowns";
import { FieldRenderProps } from "@progress/kendo-react-form";
import { Hint, Error } from "@progress/kendo-react-labels";
import { useEffect, useState } from "react";
import { sortDataFunc } from "../../../utils/dropdownSort";

const CustomMultiSelect = (fieldRenderProps: FieldRenderProps) => {
  const {
    validationMessage,
    touched,
    label,
    id,
    valid,
    disabled,
    hint,
    type,
    optional,
    max,
    rows,
    value,
    showTextLimitHint,
    loading,
    data,
    ...others
  } = fieldRenderProps;

  const [sortData, setSortData] = useState(data?.slice());
  const [filterData, setFilterData] = useState(data?.slice());


  const filterChange = (event: MultiSelectFilterChangeEvent) => {
    setFilterData(filterBy(sortData.slice(), event.filter));
  };

  useEffect(() => {
    setSortData(sortDataFunc(data, fieldRenderProps.textField));
    setFilterData(sortData?.slice());
  }, [sortData]);

  const showValidationMessage = touched && validationMessage;
  const showHint = !showValidationMessage && hint;
  const hindId = showHint ? `${id}_hint` : "";
  const errorId = showValidationMessage ? `${id}_error` : "";
  return (
    <div className={"k-form-field-wrap"}>
      <MultiSelect
        onFilterChange={filterChange}
        filterable={fieldRenderProps.filtering}
        loading={loading}
        data={fieldRenderProps.filtering ? filterData : sortData}
        value={value}
        {...others}
      />

      {showTextLimitHint && (
        <Hint direction={"end"}>
          {value.length} / {max}
        </Hint>
      )}
      {showHint && <Hint id={hindId}>{hint}</Hint>}
      {showValidationMessage && <Error id={errorId}>{validationMessage}</Error>}
    </div>
  );
};

export default CustomMultiSelect;
