import * as React from "react";
import * as ReactDOM from "react-dom";
import { Button } from "@progress/kendo-react-buttons";
import {
  AutoComplete,
  ComboBox,
  MultiColumnComboBox,
  DropDownList,
  MultiSelect,
  DropDownTree,
} from "@progress/kendo-react-dropdowns";
import { useEffect, useState } from "react";
import { Tooltip } from "@progress/kendo-react-tooltip";
import {
  Input,
  TextArea,
  MaskedTextBox,
  NumericTextBox,
  Slider,
  RangeSlider,
  Rating,
  ColorGradient,
  ColorPalette,
  ColorPicker,
  Switch,
  Checkbox,
  RadioButton,
  RadioGroup,
} from "@progress/kendo-react-inputs";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";

const langAccount = ["English UK", "English US", "French FR", "Dutch NL"];

interface SystemSettingEmailProps {
  shared?: boolean;
}

const SystemSettingEmail: React.FC<SystemSettingEmailProps> = ({ shared }) => {
  // Dialog
  const [visible, setVisible] = React.useState<boolean>(false);
  const toggleDialog = () => {
    setVisible(!visible);
  };

  return (
    <div className="m-b-10">
      <div className="row">
        <div className="col-md-12">
          <div className="trk-container">
            <div className="trk-inr p-b-30">
              <div className="trk-t text-black-14 border-bottom-solid border-w-1 border-black-1">
                <span className="text-primary">Email Details</span>
              </div>
              <div className="float-left w-100 m-t-10">
                <div className="selectScorecard">
                  <div className="selectScorecardSelect">
                    <Button
                      onClick={toggleDialog}
                      className="bg-black-5 text-black-9"
                      style={{ height: "40px", width: "150px" }}
                    >
                      Add
                      <i className="bi bi-plus fs-24"></i>
                    </Button>
                    {visible && (
                      <Dialog title={"Email Details"} onClose={toggleDialog}>
                        <div className="modalInfo modalMD">
                          <div className="heightMob float-left w-100">
                            <div className="trk-container">
                              <div className="trk-inr p-b-30">
                                <div className="float-left w-100 p-t-10">
                                  <div className="trk-row border-bottom-solid border-w-1 border-black-1">
                                    <div className="trkCol justify-content-between">
                                      <div className="trkCol-h font-weight-semi">
                                        Host
                                      </div>
                                      <div className="trkCol-dot">:</div>
                                      <div className="trkCol-p w-100">
                                        <span>
                                          <Input
                                            style={{
                                              width: "100%",
                                              height: "30px",
                                            }}
                                            placeholder="Please type"
                                          />
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="trk-row border-bottom-solid border-w-1 border-black-1">
                                    <div className="trkCol justify-content-between">
                                      <div className="trkCol-h font-weight-semi">
                                        Port
                                      </div>
                                      <div className="trkCol-dot">:</div>
                                      <div className="trkCol-p w-100">
                                        <span>
                                          <Input
                                            style={{
                                              width: "100%",
                                              height: "30px",
                                            }}
                                            placeholder="Please type"
                                          />
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="trk-row border-bottom-solid border-w-1 border-black-1">
                                    <div className="trkCol justify-content-between">
                                      <div className="trkCol-h font-weight-semi">
                                        User Name
                                      </div>
                                      <div className="trkCol-dot">:</div>
                                      <div className="trkCol-p w-100">
                                        <span>
                                          <Input
                                            style={{
                                              width: "100%",
                                              height: "30px",
                                            }}
                                            placeholder="Please type"
                                          />
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="trk-row border-bottom-solid border-w-1 border-black-1">
                                    <div className="trkCol justify-content-between">
                                      <div className="trkCol-h font-weight-semi">
                                        Password
                                      </div>
                                      <div className="trkCol-dot">:</div>
                                      <div className="trkCol-p w-100">
                                        <span>
                                          <Input
                                            style={{
                                              width: "100%",
                                              height: "30px",
                                            }}
                                            placeholder="Please type"
                                          />
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="trk-row border-bottom-solid border-w-1 border-black-1">
                                    <div className="trkCol justify-content-between">
                                      <div className="trkCol-h font-weight-semi d-flex align-items-center">
                                        Notification from Address
                                      </div>
                                      <div className="trkCol-dot d-flex align-items-center">
                                        :
                                      </div>
                                      <div className="trkCol-p w-100">
                                        <span>
                                          <Input
                                            style={{
                                              width: "100%",
                                              height: "30px",
                                            }}
                                            placeholder="Please type"
                                          />
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="trk-row border-bottom-solid border-w-1 border-black-1">
                                    <div className="trkCol justify-content-between">
                                      <div className="trkCol-h font-weight-semi d-flex align-items-center">
                                        Notification Display Name
                                      </div>
                                      <div className="trkCol-dot d-flex align-items-center">
                                        :
                                      </div>
                                      <div className="trkCol-p w-100">
                                        <span>
                                          <Input
                                            style={{
                                              width: "100%",
                                              height: "30px",
                                            }}
                                            placeholder="Please type"
                                          />
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="float-left w-100 p-t-30 ">
                                  <div className="trk-row">
                                    <div className="trkCol justify-content-between align-items-center">
                                      <div
                                        className="trkCol-h font-weight-semi d-flex align-items-center"
                                        style={{
                                          minWidth: "70px",
                                        }}
                                      >
                                        Email to
                                      </div>
                                      <div className="trkCol-dot d-flex align-items-center">
                                        :
                                      </div>
                                      <div className="trkCol-p w-100">
                                        <span>
                                          <Input
                                            style={{
                                              width: "100%",
                                              height: "30px",
                                            }}
                                            placeholder="Please type"
                                          />
                                        </span>
                                      </div>
                                      <div className="float-left text-align-right p-r-5">
                                        <Button className="bg-black-5 text-black-9 float-right">
                                          Send Test Eamil
                                        </Button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <DialogActionsBar>
                          <button
                            className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                            onClick={toggleDialog}
                          >
                            Cancel
                          </button>
                          <button
                            className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base bg-primary text-white"
                            onClick={toggleDialog}
                          >
                            Add
                          </button>
                        </DialogActionsBar>
                      </Dialog>
                    )}
                    <div className="text-black-9 text-center p-10">
                      Please click on the Add button to verify your email
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <div className="trk-container">
            <div className="trk-inr p-b-30">
              <div className="trk-t text-black-14 border-bottom-solid border-w-1 border-black-1">
                <span className="text-primary">Email Details</span>
                <span className="float-right">
                  <Button
                    className="btn bg-transparent text-black-12 border-primary text-primary m-r-5 p-r-7 p-l-7"
                    style={{ height: "26px" }}
                  >
                    <span className="p-r-5">Edit</span>
                    <i className="bi bi-pencil"></i>
                  </Button>
                </span>
              </div>
              <div className="trk-row border-bottom-solid border-w-1 border-black-1">
                <div className="trkCol">
                  <div className="trkCol-h font-weight-semi">Host</div>
                  <div className="trkCol-dot">:</div>
                  <div className="trkCol-p" contentEditable>
                    Microsoft Corporation Limited
                  </div>
                </div>
              </div>
              <div className="trk-row border-bottom-solid border-w-1 border-black-1">
                <div className="trkCol">
                  <div className="trkCol-h font-weight-semi">Port</div>
                  <div className="trkCol-dot">:</div>
                  <div className="trkCol-p" contentEditable>
                    Lorem ipsum dummy text
                  </div>
                </div>
              </div>
              <div className="trk-row border-bottom-solid border-w-1 border-black-1">
                <div className="trkCol">
                  <div className="trkCol-h font-weight-semi">User Name</div>
                  <div className="trkCol-dot">:</div>
                  <div className="trkCol-p" contentEditable>
                    <span>Robert John</span>
                  </div>
                </div>
              </div>
              <div className="trk-row border-bottom-solid border-w-1 border-black-1">
                <div className="trkCol">
                  <div className="trkCol-h font-weight-semi">Password</div>
                  <div className="trkCol-dot">:</div>
                  <div className="trkCol-p" contentEditable>
                    <span>6TKJ8dh*****</span>
                  </div>
                </div>
              </div>
              <div className="trk-row border-bottom-solid border-w-1 border-black-1">
                <div className="trkCol">
                  <div className="trkCol-h font-weight-semi d-flex align-items-center">
                    Notification from Address
                  </div>
                  <div className="trkCol-dot d-flex align-items-center">:</div>
                  <div className="trkCol-p">
                    <span>
                      <Input
                        style={{ width: "300px", height: "30px" }}
                        placeholder="Please type"
                      />
                    </span>
                  </div>
                </div>
              </div>
              <div className="trk-row border-bottom-solid border-w-1 border-black-1">
                <div className="trkCol">
                  <div className="trkCol-h font-weight-semi d-flex align-items-center">
                    Notification Display Name
                  </div>
                  <div className="trkCol-dot d-flex align-items-center">:</div>
                  <div className="trkCol-p">
                    <span>
                      <Input
                        style={{ width: "300px", height: "30px" }}
                        placeholder="Please type"
                      />
                    </span>
                  </div>
                </div>
              </div>
              <div className="trk-row border-bottom-solid border-w-1 border-black-1">
                <div className="trkCol">
                  <div className="trkCol-h font-weight-semi d-flex align-items-center">
                    Other
                  </div>
                  <div className="trkCol-dot d-flex align-items-center">:</div>
                  <div className="trkCol-p">
                    {/* <span>English UK</span> */}
                    <span>
                      <ComboBox
                        style={{ width: "300px", height: "30px" }}
                        data={langAccount}
                        defaultValue="English UK"
                        placeholder="Select Language"
                      />
                    </span>
                  </div>
                </div>
              </div>
              <div className="trk-row border-bottom-solid border-w-1 border-black-1">
                <div className="trkCol">
                  <div className="trkCol-h font-weight-semi d-flex align-items-center">
                    Other
                  </div>
                  <div className="trkCol-dot d-flex align-items-center">:</div>
                  <div className="trkCol-p">
                    <span>
                      <Input
                        style={{ width: "300px", height: "30px" }}
                        placeholder="Please type"
                      />
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12 text-right">
          <Button className="btn bg-black-5 m-r-5">Cancel</Button>
          <Button className="btn bg-primary text-white">Save</Button>
        </div>
      </div>
    </div>
  );
};

export default SystemSettingEmail;
