import React, { useEffect, useState, useRef, useContext } from "react";
import "./UserProfile.scss";
import {
  Checkbox,
  CheckboxChangeEvent,
  RadioButtonProps,
  RadioGroup,
  RadioGroupChangeEvent,
} from "@progress/kendo-react-inputs";
import { PlatformUserDetail, RecordingPolicy } from "../../../../../types/user";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { LicenseCount } from "../../../../../types/customer/LicenseCount";
import { AccessPermissionEnum } from "../../../../../enums/accessPermissionEnum";
import { Button } from "@progress/kendo-react-buttons";
import { Dictionary } from "../../../../../types/Dictionary";
import useLocale from "../../../../../hooks/useLocale";
import useAuth from "../../../../../hooks/useAuth";
import { CallSubsetType } from "../../../../../types/CallSubsetType/CallSubsetType";
import useMasterData from "../../../../../hooks/useMasterData";
import {
  DropDownList,
  DropDownListChangeEvent,
} from "@progress/kendo-react-dropdowns";
import useSwal from "../../../../../hooks/useSwal";

const rpLocaleKeyName: { [key: string]: string } = {
  "Record Received Calls": "RecordReceivedCallsName",
  "Do Not Record Received Calls": "DoNotRecordReceivedCallsName",
  "Record Dialled Calls": "RecordDialledCallsName",
  "Do Not Record Dialled Calls": "DoNotRecordDialledCallsName",
  "Record External Calls": "RecordExternalCallsName",
  "Do Not Record External Calls": "DoNotRecordExternalCallsName",
  "Record Internal Calls": "RecordInternalCallsName",
  "Do Not Record Internal Calls": "DoNotRecordInternalCallsName",
  "Record Received Calls From External Numbers only": "RRCFENOName",
  "Do Not Record Received Calls From External Numbers only": "DNRRCFENOName",
  "Record Received Calls From Internal numbers only": "RRCFINOName",
  "Do Not Record Received Calls From Internal numbers only": "DNRRCFINOName",
  "Record Dialled Calls to External Numbers only": "RDCTENOName",
  "Do Not Record Dialled Calls to External Numbers only": "DNRDCTENOName",
  "Record Dialled Calls to Internal Numbers only": "RDCTINOName",
  "Do Not Record Dialled Calls to Internal Numbers only": "DNRDCTINOName",
  "Record All Calls": "RecordAllCallsName",
  "Do Not Record Any Call": "DoNotRecordAnyCallName",
};

interface IRecordingPolicyDropdown {
  id: number;
  title: string;
  include: boolean;
}

const recordingPolicyDropdownData: IRecordingPolicyDropdown[] = [
  { id: 0, include: true, title: "Include below calls" },
  { id: 1, include: false, title: "Exclude below calls" },
];

interface IDeviceDetailsProps {
  licenseCount: LicenseCount[] | undefined;
  details: PlatformUserDetail;
  updatedDeviceDetails: (details: PlatformUserDetail) => void;
}

const DeviceDetails: React.FC<IDeviceDetailsProps> = ({
  licenseCount,
  details,
  updatedDeviceDetails,
}) => {
  const auth = useAuth();
  const localeCtx = useLocale();
  const Swal = useSwal();
  const masterData = useMasterData();
  const [translationsLoading, setTranslationsLoading] =
    useState<boolean>(false);
  const [translations, setTranslations] = useState<
    Dictionary<string> | undefined
  >(localeCtx?.selectedLocale?.current.componentTranslations["DeviceDetail"]);
  const [licenseDialogVisible, setLicenseDialogVisible] =
    useState<boolean>(false);
  const [recPolicyDialogVisible, setRecPolicyDialogVisible] =
    useState<boolean>(false);
  const [platformDetails, setPlatformDetails] =
    useState<PlatformUserDetail>(details);
  const [recordingPolicy, setRecordingPolicy] = useState<RecordingPolicy>(
    details.recordingPolicy
  );
  const [dialedChecked, setDialedChecked] = useState<boolean>(false);
  const [receivedChecked, setReceivedChecked] = useState<boolean>(false);
  const [internalChecked, setInternalChecked] = useState<boolean>(false);
  const [externalChecked, setExternalChecked] = useState<boolean>(false);
  const [excludeCallSubset, setExcludeCallSubset] = useState<boolean>(
    details.recordingPolicy === null
      ? false
      : !details.recordingPolicy.isIncludedForRecording
  );
  const [callSubset, setCallSubset] = useState<CallSubsetType | undefined>(
    details.recordingPolicy == null
      ? masterData?.data?.callSubsetTypes.find((e) => e.name === "All Calls")
      : details.recordingPolicy.callSubset
  );

  useEffect(() => {
    setPlatformDetails(details);
  }, [details]);

  useEffect(() => {
    updatedDeviceDetails(platformDetails);
    setRecordingPolicyCheckedBoxesValue();
  }, [platformDetails]);

  useEffect(() => {
    if (
      !localeCtx?.selectedLocale?.current.componentTranslations["DeviceDetail"]
    ) {
      fetchTranslations();
    }
  }, [localeCtx?.selectedLocale]);

  const fetchTranslations = async () => {
    try {
      setTranslationsLoading(true);
      const resp = await localeCtx?.setComponentTranslations("DeviceDetail");
      setTranslations(resp);
    } catch (err) {
      console.error(err);
      setTranslations(
        localeCtx?.selectedLocale?.previous.componentTranslations[
        "DeviceDetail"
        ]
      );
      localeCtx?.setPreviousAppLocale("DeviceDetail");
      if (localeCtx?.localeSwitchFailed) {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Couldn't Switch Language",
        });
      }
    } finally {
      setTimeout(() => {
        setTranslationsLoading(false);
      }, 100);
    }
  };

  const fetchLabelKeyTranslation = (
    key: string,
    defaultValue: string
  ): string => {
    return translations && translations[key] ? translations[key] : defaultValue;
  };

  const updateInternal = (e: CheckboxChangeEvent) => {
    setInternalChecked(e.value);
    setNewRecordingPolicy(
      dialedChecked,
      receivedChecked,
      e.value,
      externalChecked,
      excludeCallSubset
    );
  };
  const updateExternal = (e: CheckboxChangeEvent) => {
    setExternalChecked(e.value);
    setNewRecordingPolicy(
      dialedChecked,
      receivedChecked,
      internalChecked,
      e.value,
      excludeCallSubset
    );
  };
  const updateDialed = (e: CheckboxChangeEvent) => {
    setDialedChecked(e.value);
    setNewRecordingPolicy(
      e.value,
      receivedChecked,
      internalChecked,
      externalChecked,
      excludeCallSubset
    );
  };
  const updateReceived = (e: CheckboxChangeEvent) => {
    setReceivedChecked(e.value);
    setNewRecordingPolicy(
      dialedChecked,
      e.value,
      internalChecked,
      externalChecked,
      excludeCallSubset
    );
  };

  const handleRecordingPolicyDropdownData = (
    event: DropDownListChangeEvent
  ) => {
    setExcludeCallSubset(!event.value.include);
    setNewRecordingPolicy(
      dialedChecked,
      receivedChecked,
      internalChecked,
      externalChecked,
      !event.value.include
    );
  };

  const setNewRecordingPolicy = (
    dialedCheckedVal: boolean,
    receivedCheckedVal: boolean,
    internalCheckedVal: boolean,
    externalCheckedVal: boolean,
    excludeCallSubsetVal: boolean
  ) => {
    let subset = undefined;
    let callSubSets = masterData?.data?.callSubsetTypes?.filter((e) => {
      if (dialedCheckedVal !== receivedCheckedVal) {
        if (
          dialedCheckedVal &&
          e.rules.findIndex(
            (r) => r.ruleField.name === "CallDirection" && r.value === "Dialled"
          ) === -1
        ) {
          return false;
        }
        if (
          receivedCheckedVal &&
          e.rules.findIndex(
            (r) =>
              r.ruleField.name === "CallDirection" && r.value === "Received"
          ) === -1
        ) {
          return false;
        }
      }
      if (internalCheckedVal !== externalCheckedVal) {
        if (
          internalCheckedVal &&
          e.rules.findIndex(
            (r) => r.ruleField.name === "CallType" && r.value === "Internal"
          ) === -1
        ) {
          return false;
        }
        if (
          externalCheckedVal &&
          e.rules.findIndex(
            (r) => r.ruleField.name === "CallType" && r.value === "External"
          ) === -1
        ) {
          return false;
        }
      }
      return true;
    });

    if (
      dialedCheckedVal &&
      receivedCheckedVal &&
      internalCheckedVal &&
      externalCheckedVal
    ) {
      subset = masterData?.data?.callSubsetTypes.find(
        (e) => e.name === "All Calls"
      );
    } else if (
      !dialedCheckedVal &&
      !receivedCheckedVal &&
      !internalCheckedVal &&
      !externalCheckedVal
    ) {
      subset = undefined;
    } else if (
      callSubSets !== undefined &&
      callSubSets !== null &&
      callSubSets.length > 0
    ) {
      subset = callSubSets[0];
    } else {
      subset = undefined;
    }

    let newPolicy: RecordingPolicy = {
      id:
        platformDetails.recordingPolicy === null
          ? 0
          : platformDetails.recordingPolicy.id,
      isIncludedForRecording: !excludeCallSubsetVal,
      callSubset:
        subset ??
        masterData?.data?.callSubsetTypes.find((e) => e.name === "All Calls"),
    };

    setCallSubset(subset);
    setRecordingPolicy(newPolicy);
  };

  const setRecordingPolicyCheckedBoxesValue = () => {
    var _dialed = true;
    var _received = true;
    var _internal = true;
    var _external = true;
    if (
      platformDetails.recordingPolicy === null ||
      platformDetails.recordingPolicy.callSubset?.name === "All Calls"
    ) {
      _dialed = true;
      _received = true;
      _internal = true;
      _external = true;
    } else {
      _dialed = false;
      _received = false;
      _internal = false;
      _external = false;
      var _rules = platformDetails.recordingPolicy.callSubset?.rules;
      if (
        _rules &&
        _rules.findIndex(
          (r) => r.ruleField.name === "CallDirection" && r.value === "Dialled"
        ) !== -1
      ) {
        _dialed = true;
      }
      if (
        _rules &&
        _rules.findIndex(
          (r) => r.ruleField.name === "CallDirection" && r.value === "Received"
        ) !== -1
      ) {
        _received = true;
      }
      if (
        _rules &&
        _rules.findIndex(
          (r) => r.ruleField.name === "CallType" && r.value === "Internal"
        ) !== -1
      ) {
        _internal = true;
      }
      if (
        _rules &&
        _rules.findIndex(
          (r) => r.ruleField.name === "CallType" && r.value === "External"
        ) !== -1
      ) {
        _external = true;
      }
      if (_dialed === _received) {
        _dialed = true;
        _received = true;
      }
      if (_internal === _external) {
        _internal = true;
        _external = true;
      }
    }
    setDialedChecked(_dialed);
    setReceivedChecked(_received);
    setInternalChecked(_internal);
    setExternalChecked(_external);
  };

  const toggleRecPolicyDialog = () => {
    setRecPolicyDialogVisible(!recPolicyDialogVisible);
  };

  const toggleLicenseDialog = () => {
    setLicenseDialogVisible(!licenseDialogVisible);
  };

  const updateRecordingPolicy = () => {
    setPlatformDetails({
      ...platformDetails,
      recordingPolicy: recordingPolicy,
    });
    toggleRecPolicyDialog();
  };

  const getRecordingPolicyName = (
    subsetType: CallSubsetType | undefined,
    _excludeCallSubsetVal: boolean
  ) => {
    if (subsetType === undefined) {
      return `${translationsLoading
        ? "Not A Valid Selection"
        : fetchLabelKeyTranslation(
          "NotAValidSelectionMsg",
          "Not A Valid Selection"
        )
        }`;
    }
    let _policyName = `Record ${subsetType.name}`;
    if (_excludeCallSubsetVal) {
      if (subsetType.name === "All Calls") {
        _policyName = `Do Not Record Any Call`;
      } else {
        _policyName = `Do Not ${_policyName}`;
      }
    }
    return `${translationsLoading
      ? _policyName
      : fetchLabelKeyTranslation(rpLocaleKeyName[_policyName], _policyName)
      }`;
  };

  const LicenseDialog = () => {
    const licenseData: RadioButtonProps[] = [
      { label: "None", value: undefined },
      ...(licenseCount?.map((lc) => {
        return { label: lc.license.licenseName, value: lc.license.id };
      }) ?? []),
    ];
    const [assignedLicense, setAssignedLicense] = useState(
      platformDetails.license?.id
    );
    const [licenseError, setLicenseError] = useState<string>("");

    const handleLicenseChange = (event: RadioGroupChangeEvent) => {
      setAssignedLicense(event.value);
    };

    const updatePlatformLicense = () => {
      var _licenseCount = licenseCount?.find(
        (lc) => lc.license.id === assignedLicense
      );
      if (platformDetails.license?.id !== _licenseCount?.license.id) {
        if (
          _licenseCount !== undefined &&
          _licenseCount.isAutoLearnEnabled == false &&
          _licenseCount.consumed >= _licenseCount.purchased
        ) {
          setLicenseError(
            translationsLoading
              ? "All Licenses Consumed! Please buy new licenses on Voice AI Store."
              : fetchLabelKeyTranslation(
                "SwtAltInsufficientText",
                "All Licenses Consumed! Please buy new licenses on Voice AI Store."
              )
          );
          return;
        } else {
          setLicenseError("");
        }
        setPlatformDetails({
          ...platformDetails,
          license: _licenseCount?.license,
        });
      }
      setLicenseDialogVisible(false);
    };

    return (
      <Dialog
        title={
          translationsLoading
            ? "License"
            : fetchLabelKeyTranslation("LicenseTitle", "License")
        }
        onClose={toggleLicenseDialog}
      >
        <div
          style={{
            textAlign: "center",
            padding: "10px",
            maxWidth: "600px",
            minWidth: "400px",
            minHeight: "100px",
            maxHeight: "80vh",
          }}
        >
          {licenseError !== "" && <div className="tx-red">{licenseError}</div>}
          <RadioGroup
            data={licenseData}
            value={assignedLicense}
            onChange={handleLicenseChange}
          />
        </div>
        <DialogActionsBar>
          <button
            className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
            onClick={toggleLicenseDialog}
          >
            {`${translationsLoading
              ? "Cancel"
              : fetchLabelKeyTranslation("CancelButton", "Cancel")
              }`}
          </button>
          <button
            className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
            disabled={
              !auth?.checkUserAccess(AccessPermissionEnum.ManageUsersAndGroups)
            }
            onClick={(e) => updatePlatformLicense()}
          >
            {`${translationsLoading
              ? "Update"
              : fetchLabelKeyTranslation("UpdateButton", "Update")
              }`}
          </button>
        </DialogActionsBar>
      </Dialog>
    );
  };

  return (
    <div className="dvcListCol">
      <div
        className="dvcListHead border-bottom-solid border-w-1 border-black-1 p-b-10 m-b-7"
        style={{ minHeight: "30px" }}
      >
        <div className="dvcHeadTitle float-left fs-15 font-weight-semi">
          {platformDetails.customerPlatformName}
        </div>
        <span className="dvcHeadTitleBtn d-flex">
          {platformDetails.license?.isRecordingEnabled && (
            <span className="boxLabelAI bg-primary text-white">REC</span>
          )}
          {platformDetails.license?.isAIEnabled && (
            <span className="boxLabelAI bg-primary text-white">AI</span>
          )}
        </span>
      </div>
      <div className="dvcListBody">
        <div className="row">
          <div className="col-md-6">
            <div className="dvcPointLg">
              <div className="dvcPointLgRow">
                <div className="dvcPointLgCol point-p">
                  {translationsLoading
                    ? "Device ID"
                    : fetchLabelKeyTranslation("DeviceIdTitle", "Device ID")}
                </div>
                <div className="dvcPointLgCol point-dot">:</div>
                <div className="dvcPointLgCol point-h font-weight-semi wordBreakAll">
                  {platformDetails.deviceIdentifier}
                </div>
              </div>
              <div className="dvcPointLgRow">
                <div className="dvcPointLgCol point-p">
                  {translationsLoading
                    ? "License"
                    : fetchLabelKeyTranslation("LicenseTitle", "License")}
                </div>
                <div className="dvcPointLgCol point-dot">:</div>
                <div className="dvcPointLgCol point-h font-weight-semi">
                  {platformDetails?.license
                    ? platformDetails?.license?.licenseName
                    : translationsLoading
                      ? "None"
                      : fetchLabelKeyTranslation("LicenseNone", "None")}
                  <Button
                    className={`btn btn-link p-l-2 ${!auth?.checkUserAccess(
                      AccessPermissionEnum.ManageUsersAndGroups
                    )
                      ? "disabledIconBtn"
                      : "iconBtn"
                      }`}
                    onClick={toggleLicenseDialog}
                    disabled={
                      !auth?.checkUserAccess(
                        AccessPermissionEnum.ManageUsersAndGroups
                      )
                    }
                  >
                    <i className="bi bi-pencil p-l-1"></i>
                  </Button>
                  {licenseDialogVisible && <LicenseDialog />}
                </div>
              </div>
              <div className="dvcPointLgRow">
                <div className="dvcPointLgCol point-p">
                  {translationsLoading
                    ? "Recording Policy"
                    : fetchLabelKeyTranslation(
                      "RecordingPolicyTitle",
                      "Recording Policy"
                    )}
                </div>
                <div className="dvcPointLgCol point-dot">:</div>
                <div className="dvcPointLgCol point-h font-weight-semi">
                  {details?.recordingPolicy
                    ? getRecordingPolicyName(
                      details?.recordingPolicy?.callSubset,
                      !details?.recordingPolicy?.isIncludedForRecording
                    )
                    : getRecordingPolicyName(
                      masterData?.data?.callSubsetTypes.find(
                        (e) => e.name === "All Calls"
                      ),
                      false
                    )}
                  <Button
                    className={`btn btn-link p-l-2 ${!auth?.checkUserAccess(
                      AccessPermissionEnum.ManageUsersAndGroups
                    )
                      ? "disabledIconBtn"
                      : "iconBtn"
                      }`}
                    onClick={toggleRecPolicyDialog}
                    disabled={
                      !auth?.checkUserAccess(
                        AccessPermissionEnum.ManageUsersAndGroups
                      )
                    }
                  >
                    <span className="linkLbl-i p-l-1">
                      <i className="bi bi-pencil"></i>
                    </span>
                  </Button>
                  {recPolicyDialogVisible && (
                    <Dialog
                      title={`${translationsLoading
                        ? "Recording Policy"
                        : fetchLabelKeyTranslation(
                          "RecordingPolicyDailogTitle",
                          "Recording Policy"
                        )
                        }`}
                      onClose={toggleRecPolicyDialog}
                    >
                      <div
                        style={{
                          textAlign: "center",
                          padding: "15px 0 0 0",
                          maxWidth: "300px",
                          minWidth: "300px",
                          minHeight: "65px",
                        }}
                      >
                        <p className="font-weight-bold">
                          {getRecordingPolicyName(
                            callSubset,
                            excludeCallSubset
                          )}
                        </p>
                        <div>
                          <DropDownList
                            className="recordingpolicy-dropdown font-weight-bold"
                            data={recordingPolicyDropdownData}
                            value={recordingPolicyDropdownData.find(
                              (data: IRecordingPolicyDropdown) =>
                                data.include === !excludeCallSubset
                            )}
                            textField="title"
                            dataItemKey="id"
                            onChange={handleRecordingPolicyDropdownData}
                          />
                        </div>
                      </div>
                      <div>
                        <div className="row">
                          <div className="col-md-6">
                            <div>
                              <Checkbox
                                className="p-r-5"
                                checked={dialedChecked}
                                onChange={updateDialed}
                              />
                              <label className="font-weight-bold text-black-11">
                                {`${translationsLoading
                                  ? "Dialed"
                                  : fetchLabelKeyTranslation(
                                    "DialedRow",
                                    "Dialed"
                                  )
                                  }`}
                              </label>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div>
                              <Checkbox
                                className="p-r-5"
                                checked={receivedChecked}
                                onChange={updateReceived}
                              />
                              <label className="font-weight-bold text-black-11">
                                {`${translationsLoading
                                  ? "Received"
                                  : fetchLabelKeyTranslation(
                                    "ReceivedRow",
                                    "Received"
                                  )
                                  }`}
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-6">
                            <div>
                              <Checkbox
                                className="p-r-5"
                                checked={internalChecked}
                                onChange={updateInternal}
                              />
                              <label className="font-weight-bold text-black-11">
                                {`${translationsLoading
                                  ? "Internal"
                                  : fetchLabelKeyTranslation(
                                    "InternalRow",
                                    "Internal"
                                  )
                                  }`}
                              </label>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div>
                              <Checkbox
                                className="p-r-5"
                                checked={externalChecked}
                                onChange={updateExternal}
                              />
                              <label className="font-weight-bold text-black-11">
                                {`${translationsLoading
                                  ? "External"
                                  : fetchLabelKeyTranslation(
                                    "ExternalRow",
                                    "External"
                                  )
                                  }`}
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <DialogActionsBar>
                        <button
                          className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                          onClick={toggleRecPolicyDialog}
                        >
                          {`${translationsLoading
                            ? "Cancel"
                            : fetchLabelKeyTranslation(
                              "CancelButton",
                              "Cancel"
                            )
                            }`}
                        </button>
                        <button
                          className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
                          disabled={callSubset === undefined ? true : false}
                          onClick={updateRecordingPolicy}
                        >
                          {`${translationsLoading
                            ? "Update"
                            : fetchLabelKeyTranslation(
                              "UpdateButton",
                              "Update"
                            )
                            }`}
                        </button>
                      </DialogActionsBar>
                    </Dialog>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            {details.platform === "Broadworks" && (
              <div className="dvcPointLg pointSm">
                <div className="dvcPointLgRow">
                  <div className="dvcPointLgCol point-p">
                    {translationsLoading
                      ? "Broadworks User Id"
                      : fetchLabelKeyTranslation(
                        "BroadworksUserIdTitle",
                        "Broadworks User Id"
                      )}
                  </div>
                  <div className="dvcPointLgCol point-dot">:</div>
                  <div className="dvcPointLgCol point-h wordBreakAll">
                    {platformDetails.deviceDetails.bwUserId
                      ? platformDetails.deviceDetails.bwUserId
                      : ""}
                  </div>
                </div>
                <div className="dvcPointLgRow">
                  <div className="dvcPointLgCol point-p">
                    {translationsLoading
                      ? "DDI"
                      : fetchLabelKeyTranslation("DDITitle", "DDI")}
                  </div>
                  <div className="dvcPointLgCol point-dot">:</div>
                  <div className="dvcPointLgCol point-h">
                    {platformDetails.deviceDetails.ddi
                      ? platformDetails.deviceDetails.ddi
                      : ""}
                  </div>
                </div>
                <div className="dvcPointLgRow">
                  <div className="dvcPointLgCol point-p">
                    {translationsLoading
                      ? "Name"
                      : fetchLabelKeyTranslation("NameTitle", "Name")}
                  </div>
                  <div className="dvcPointLgCol point-dot">:</div>
                  <div className="dvcPointLgCol point-h">
                    {`${platformDetails.deviceDetails.bwFirstName
                      ? platformDetails.deviceDetails.bwFirstName
                      : ""
                      } 
                        ${platformDetails.deviceDetails.bwLastName
                        ? platformDetails.deviceDetails.bwLastName
                        : ""
                      } `.trim()}
                  </div>
                </div>
                <div className="dvcPointLgRow">
                  <div className="dvcPointLgCol point-p">
                    {translationsLoading
                      ? "Email"
                      : fetchLabelKeyTranslation("EmailTitle", "Email")}
                  </div>
                  <div className="dvcPointLgCol point-dot">:</div>
                  <div className="dvcPointLgCol point-h wordBreakAll">
                    {platformDetails.deviceDetails.email
                      ? platformDetails.deviceDetails.email
                      : ""}
                  </div>
                </div>
                <div className="dvcPointLgRow">
                  <div className="dvcPointLgCol point-p">
                    {translationsLoading
                      ? "Extension Number"
                      : fetchLabelKeyTranslation(
                        "ExtensionNumberTitle",
                        "Extension Number"
                      )}
                  </div>
                  <div className="dvcPointLgCol point-dot">:</div>
                  <div className="dvcPointLgCol point-h">
                    {platformDetails.deviceDetails.extensionNo
                      ? platformDetails.deviceDetails.extensionNo
                      : ""}
                  </div>
                </div>
                <div className="dvcPointLgRow">
                  <div className="dvcPointLgCol point-p">
                    {translationsLoading
                      ? "Recording Mode"
                      : fetchLabelKeyTranslation(
                        "RecordingModeTitle",
                        "Recording Mode"
                      )}
                  </div>
                  <div className="dvcPointLgCol point-dot">:</div>
                  <div className="dvcPointLgCol point-h">
                    {platformDetails.deviceDetails.recordingMode
                      ? platformDetails.deviceDetails.recordingMode
                      : ""}
                  </div>
                </div>

                <div className="dvcPointLgRow">
                  <div className="dvcPointLgCol point-p">
                    {translationsLoading
                      ? "Pause Resume Notify Method"
                      : fetchLabelKeyTranslation(
                        "PauseResumeNotifyMethodTitle",
                        "Pause Resume Notify Method"
                      )}
                  </div>
                  <div className="dvcPointLgCol point-dot">:</div>
                  <div className="dvcPointLgCol point-h">
                    {platformDetails.deviceDetails.pauseResumeNotifyMethod
                      ? platformDetails.deviceDetails.pauseResumeNotifyMethod
                      : ""}
                  </div>
                </div>
                <div className="dvcPointLgRow">
                  <div className="dvcPointLgCol point-p">
                    {translationsLoading
                      ? "Record Voice Messaging"
                      : fetchLabelKeyTranslation(
                        "RecordVoiceMessaging",
                        "Record Voice Messaging"
                      )}
                  </div>
                  <div className="dvcPointLgCol point-dot">:</div>
                  <div className="dvcPointLgCol point-h">
                    {platformDetails.deviceDetails.recordVoiceMessaging != undefined
                      ? platformDetails.deviceDetails.recordVoiceMessaging.toString()
                      : ""}
                  </div>
                </div>
              </div>
            )}
            {details.platform === "SBC-MsTeams" && (
              <div className="dvcPointLg pointSm">
                <div className="dvcPointLgRow">
                  <div className="dvcPointLgCol point-p">
                    {translationsLoading
                      ? "Microsoft Email"
                      : fetchLabelKeyTranslation(
                        "MicrosoftEmailTitle",
                        "Microsoft Email"
                      )}
                  </div>
                  <div className="dvcPointLgCol point-dot">:</div>
                  <div className="dvcPointLgCol point-h wordBreakAll">
                    {platformDetails.deviceDetails.microsoftEmail
                      ? platformDetails.deviceDetails.microsoftEmail
                      : ""}
                  </div>
                </div>
                <div className="dvcPointLgRow">
                  <div className="dvcPointLgCol point-p">
                    {translationsLoading
                      ? "Name"
                      : fetchLabelKeyTranslation("NameTitle", "Name")}
                  </div>
                  <div className="dvcPointLgCol point-dot">:</div>
                  <div className="dvcPointLgCol point-h">
                    {platformDetails.deviceDetails.givenName
                      ? platformDetails.deviceDetails.givenName
                      : ""}
                  </div>
                </div>
              </div>
            )}

            {details.platform === "SBC-Others" && (
              <div className="dvcPointLg pointSm">
                <div className="dvcPointLgRow">
                  <div className="dvcPointLgCol point-p">
                    {translationsLoading
                      ? "Name"
                      : fetchLabelKeyTranslation("NameTitle", "Name")}
                  </div>
                  <div className="dvcPointLgCol point-dot">:</div>
                  <div className="dvcPointLgCol point-h">
                    {platformDetails.deviceDetails.sbcOthersName
                      ? platformDetails.deviceDetails.sbcOthersName
                      : ""}
                  </div>
                </div>

                <div className="dvcPointLgRow">
                  <div className="dvcPointLgCol point-p">
                    {translationsLoading
                      ? "Type"
                      : fetchLabelKeyTranslation("TypeTitle", "Type")}
                  </div>
                  <div className="dvcPointLgCol point-dot">:</div>
                  <div className="dvcPointLgCol point-h">
                    {platformDetails.deviceDetails.sbcOthersType
                      ? platformDetails.deviceDetails.sbcOthersType
                      : ""}
                  </div>
                </div>

                <div className="dvcPointLgRow">
                  <div className="dvcPointLgCol point-p">
                    {translationsLoading
                      ? "DDI"
                      : fetchLabelKeyTranslation("DDITitle", "DDI")}
                  </div>
                  <div className="dvcPointLgCol point-dot">:</div>
                  <div className="dvcPointLgCol point-h">
                    {platformDetails.deviceDetails.sbcOthersDDI
                      ? platformDetails.deviceDetails.sbcOthersDDI
                      : ""}
                  </div>
                </div>

                <div className="dvcPointLgRow">
                  <div className="dvcPointLgCol point-p">
                    {translationsLoading
                      ? "Location"
                      : fetchLabelKeyTranslation("LocationTitle", "Location")}
                  </div>
                  <div className="dvcPointLgCol point-dot">:</div>
                  <div className="dvcPointLgCol point-h">
                    {platformDetails.deviceDetails.sbcOthersLocation
                      ? platformDetails.deviceDetails.sbcOthersLocation
                      : ""}
                  </div>
                </div>
              </div>
            )}

            {details.platform === "Telepo" && (
              <div className="dvcPointLg pointSm">
                <div className="dvcPointLgRow">
                  <div className="dvcPointLgCol point-p">
                    {translationsLoading
                      ? "Telepo User Id"
                      : fetchLabelKeyTranslation(
                        "TelepoUserIdTitle",
                        "Telepo User Id"
                      )}
                  </div>
                  <div className="dvcPointLgCol point-dot">:</div>
                  <div className="dvcPointLgCol point-h wordBreakAll">
                    {platformDetails.deviceDetails.telepoUserId
                      ? platformDetails.deviceDetails.telepoUserId
                      : ""}
                  </div>
                </div>

                <div className="dvcPointLgRow">
                  <div className="dvcPointLgCol point-p">
                    {translationsLoading
                      ? "Given Name"
                      : fetchLabelKeyTranslation(
                        "TelepoGivenNameTitle",
                        "Given Name"
                      )}
                  </div>
                  <div className="dvcPointLgCol point-dot">:</div>
                  <div className="dvcPointLgCol point-h wordBreakAll">
                    {platformDetails.deviceDetails.givenName
                      ? platformDetails.deviceDetails.givenName
                      : ""}
                  </div>
                </div>

                <div className="dvcPointLgRow">
                  <div className="dvcPointLgCol point-p">
                    {translationsLoading
                      ? "Family Name"
                      : fetchLabelKeyTranslation(
                        "TelepoFamilyNameTitle",
                        "Family Name"
                      )}
                  </div>
                  <div className="dvcPointLgCol point-dot">:</div>
                  <div className="dvcPointLgCol point-h wordBreakAll">
                    {platformDetails.deviceDetails.familyName
                      ? platformDetails.deviceDetails.familyName
                      : ""}
                  </div>
                </div>

                <div className="dvcPointLgRow">
                  <div className="dvcPointLgCol point-p">
                    {translationsLoading
                      ? "Email"
                      : fetchLabelKeyTranslation("EmailTitle", "Email")}
                  </div>
                  <div className="dvcPointLgCol point-dot">:</div>
                  <div className="dvcPointLgCol point-h wordBreakAll">
                    {platformDetails.deviceDetails.telepoPlatformEmail
                      ? platformDetails.deviceDetails.telepoPlatformEmail
                      : ""}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeviceDetails;
