import * as React from "react";
import { useState, useEffect, useCallback } from "react";
import { Button, ButtonGroup } from "@progress/kendo-react-buttons";
import {
  Input,
  InputChangeEvent,
  RadioButton,
  RadioButtonChangeEvent,
} from "@progress/kendo-react-inputs";
import { DialogActionsBar } from "@progress/kendo-react-dialogs";
import {
  ComboBox,
  ComboBoxChangeEvent,
  ListItemProps,
  MultiSelectChangeEvent,
} from "@progress/kendo-react-dropdowns";
import {
  DatePicker,
  DatePickerChangeEvent,
} from "@progress/kendo-react-dateinputs";
import { Dictionary } from "../../../types/Dictionary";
import useLocale from "../../../hooks/useLocale";
import useSwal from "../../../hooks/useSwal";
import {
  CreateNotificationRule,
  GetNotificationRule,
  NotificationEvaluation,
  NotificationTopic,
} from "../../../types/notification-setup/NotificationSetup";
import CustomMultiSelectFilteringWithoutForm from "../../../components/custom/form/CustomMultiSelectFilteringWithoutForm";
import { TinyUser } from "../../../types/user";
import usersService from "../../../services/users.service";
import { Error } from "@progress/kendo-react-labels";
import { axiosWithAuth } from "../../../utils/customAxios";
import "./notification-setup.css";
import { AxiosError } from "axios";
import useMasterData from "../../../hooks/useMasterData";
import {
  CallDirection,
  CallType,
  ColorCategory,
  Sentiment,
} from "../../../types/master-data";
import { TinyObj } from "../../../types/TinyObj";
import { Topic } from "../../../types/topic";

interface ErrorField {
  field: string;
  error: string;
}

interface UpsertNotificationSetupData {
  toggleDialog: () => void;
  upsertNotificationRule: (
    insert: boolean,
    notificationData: GetNotificationRule
  ) => void;
  notificationUpdateData?: GetNotificationRule;
}

const UpsertNotificationSetup: React.FC<UpsertNotificationSetupData> = ({
  toggleDialog,
  upsertNotificationRule,
  notificationUpdateData,
}) => {
  const localeCtx = useLocale();
  const master = useMasterData();
  const Swal = useSwal()

  const [notificationName, setNotificationName] = useState<string>(
    notificationUpdateData ? notificationUpdateData.name : ""
  );
  const [topicsData, setTopicsData] = useState<TinyObj[]>([]);
  const [topics, setTopics] = useState<TinyObj[]>(
    notificationUpdateData ? notificationUpdateData.filterCriteria.topics : []
  );
  const [sentiments, setSentiments] = useState<Sentiment[]>(
    notificationUpdateData
      ? notificationUpdateData.filterCriteria.sentiments
      : []
  );
  const [colorCategories, setColorCategories] = useState<ColorCategory[]>(
    notificationUpdateData
      ? notificationUpdateData.filterCriteria.colorCategories
      : []
  );
  const [evaluations, setEvaluations] = useState<NotificationEvaluation[]>(
    notificationUpdateData
      ? notificationUpdateData.filterCriteria.evaluations
      : []
  );

  const [callDirection, setCallDirection] = useState<CallDirection | undefined>(
    notificationUpdateData
      ? notificationUpdateData.filterCriteria.callDirection
      : undefined
  );

  const [callType, setCallType] = useState<CallType | undefined>(
    notificationUpdateData
      ? notificationUpdateData.filterCriteria.callType
      : undefined
  );
  const [userEmailsData, setUserEmailsData] = useState<TinyUser[] | undefined>(
    undefined
  );
  const [userEmails, setUserEmails] = useState<TinyUser[]>(
    notificationUpdateData ? notificationUpdateData.filterCriteria.users : []
  );
  const [userGroupsData, setUserGroupsData] = useState<TinyObj[] | undefined>(
    undefined
  );
  const [userGroups, setUserGroups] = useState<TinyObj[]>(
    notificationUpdateData
      ? notificationUpdateData.filterCriteria.userGroups
      : []
  );
  const [expiryDate, setExpiryDate] = useState<Date | null>(
    notificationUpdateData
      ? !notificationUpdateData.expiryDate
        ? null
        : new Date(notificationUpdateData.expiryDate)
      : new Date()
  );
  const [radioButtonValue, setRadioButtonValue] = useState<string>(
    notificationUpdateData
      ? !notificationUpdateData.expiryDate
        ? "Yes"
        : "No"
      : "No"
  );

  const [errorFields, setErrorFields] = useState<ErrorField[]>([]);
  const [loader, setLoader] = useState(false);

  const [translationsLoading, setTranslationsLoading] =
    useState<boolean>(false);
  const [translations, setTranslations] = useState<
    Dictionary<string> | undefined
  >(
    localeCtx?.selectedLocale?.current.componentTranslations[
      "UpsertNotificationSetup"
    ]
  );

  useEffect(() => {
    if (
      !localeCtx?.selectedLocale?.current.componentTranslations[
        "UpsertNotificationSetup"
      ]
    ) {
      fetchTranslations();
    }
  }, [localeCtx?.selectedLocale]);

  useEffect(() => {
    getAllData();
  }, []);

  const fetchTranslations = async () => {
    try {
      setTranslationsLoading(true);
      const resp = await localeCtx?.setComponentTranslations(
        "UpsertNotificationSetup"
      );
      setTranslations(resp);
    } catch (err) {
      console.error(err);
      setTranslations(
        localeCtx?.selectedLocale?.previous.componentTranslations[
          "UpsertNotificationSetup"
        ]
      );
      localeCtx?.setPreviousAppLocale("UpsertNotificationSetup");
      if (localeCtx?.localeSwitchFailed) {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Couldn't Switch Language",
        });
      }
    } finally {
      setTimeout(() => {
        setTranslationsLoading(false);
      }, 100);
    }
  };

  const fetchLabelKeyTranslation = (
    key: string,
    defaultValue: string
  ): string => {
    return translations && translations[key] ? translations[key] : defaultValue;
  };

  const getAllData = async () => {
    try {
      const allTopics: TinyObj[] = [];
      const data = await usersService.getCurrentUser();
      data.topics.forEach((topic: Topic) =>
        allTopics.push({
          id: topic.id,
          name: topic.topicName,
          isPublic: topic.isPublic,
        })
      );
      setTopicsData(allTopics);
      setUserGroupsData(data.associatedGroups?.playbackNetwork ?? []);
      setUserEmailsData(data.recordingNetwork);
    } catch (error) {
      console.log(error);
      setTopicsData([]);
      setUserGroupsData([]);
      setUserEmailsData([]);
    }
  };

  const itemrenderEmails = (
    li: React.ReactElement<HTMLLIElement>,
    itemProps: ListItemProps
  ) => {
    const itemChildren = (
      <span>
        <i className="bi bi-person m-r-6"></i>
        <span>{itemProps.dataItem.email}</span>
      </span>
    );
    return React.cloneElement(li, li.props, itemChildren);
  };

  const itemrenderGroups = (
    li: React.ReactElement<HTMLLIElement>,
    itemProps: ListItemProps
  ) => {
    const itemChildren = (
      <span>
        <i className="bi bi-people m-r-6"></i>
        <span>{itemProps.dataItem.name}</span>
      </span>
    );
    return React.cloneElement(li, li.props, itemChildren);
  };

  const itemrenderTopics = (
    li: React.ReactElement<HTMLLIElement>,
    itemProps: ListItemProps
  ) => {
    const itemChildren = (
      <span>
        <i
          className={`bi-${
            itemProps.dataItem.isPublic ? "people" : "lock-fill"
          } m-r-6`}
        ></i>
        <span>{itemProps.dataItem.name}</span>
      </span>
    );
    return React.cloneElement(li, li.props, itemChildren);
  };

  const itemrenderCallTypes = (
    li: React.ReactElement<HTMLLIElement>,
    itemProps: ListItemProps
  ) => {
    const itemChildren = (
      <span>
        <span>{itemProps.dataItem.name}</span>
      </span>
    );
    return React.cloneElement(li, li.props, itemChildren);
  };

  const itemrenderCallDirection = (
    li: React.ReactElement<HTMLLIElement>,
    itemProps: ListItemProps
  ) => {
    const itemChildren = (
      <span>
        <span>{itemProps.dataItem.name}</span>
      </span>
    );
    return React.cloneElement(li, li.props, itemChildren);
  };

  const handleChange = useCallback(
    (e: RadioButtonChangeEvent) => {
      if (e.value === "Yes") {
        setExpiryDate(null);
      } else {
        setExpiryDate(new Date());
      }
      setRadioButtonValue(e.value);
    },
    [radioButtonValue]
  );

  const onChangeUserEmails = (event: MultiSelectChangeEvent) => {
    setUserEmails([...event.value]);
  };

  const onChangeUserGroups = (event: MultiSelectChangeEvent) => {
    setUserGroups([...event.value]);
  };

  const onChangeNotificationName = (event: InputChangeEvent) => {
    if (event.value.length > 100) {
      setErrorFields([
        ...errorFields,
        {
          field: "notificationName",
          error: translationsLoading
            ? "Notification Name cannot be more than 100 characters"
            : fetchLabelKeyTranslation(
                "FieldError1",
                "Notification Name cannot be more than 100 characters"
              ),
        },
      ]);
    } else {
      setErrorFields([
        ...errorFields.filter(
          (error: ErrorField) => error.field !== "notificationName"
        ),
      ]);
    }
    setNotificationName(event.value);
  };

  const onChangeCallType = (event: ComboBoxChangeEvent) => {
    setCallType(event.value);
  };

  const onChangeCallDirection = (event: ComboBoxChangeEvent) => {
    setCallDirection(event.value);
  };

  const onChangeTopics = (event: MultiSelectChangeEvent) => {
    setTopics([...event.value]);
  };

  const onChangeSentiments = (type?: Sentiment) => {
    const findSentiment: Sentiment | undefined = sentiments?.find(
      (s: Sentiment) => s.name.toLowerCase() === type?.name.toLowerCase()
    );
    if (type) {
      if (!findSentiment) {
        setSentiments([...sentiments, type]);
      } else {
        setSentiments(
          sentiments.filter(
            (s: Sentiment) => s.name.toLowerCase() !== type?.name.toLowerCase()
          )
        );
      }
    }
  };

  const onChangeFlags = (type?: ColorCategory) => {
    const findColor: ColorCategory | undefined = colorCategories?.find(
      (color: ColorCategory) =>
        color.name.toLowerCase() === type?.name.toLowerCase()
    );
    if (type) {
      if (!findColor) {
        setColorCategories([...colorCategories, type]);
      } else {
        setColorCategories(
          colorCategories.filter(
            (color: ColorCategory) =>
              color.name.toLowerCase() !== type.name.toLowerCase()
          )
        );
      }
    }
  };

  const onChangeEvaluation = (type: NotificationEvaluation) => {
    const findEval: NotificationEvaluation | undefined = evaluations?.find(
      (e: NotificationEvaluation) =>
        e.name.toLowerCase() === type?.name.toLowerCase()
    );
    if (!findEval) {
      setEvaluations([...evaluations, type]);
    } else {
      setEvaluations(
        evaluations.filter(
          (evaluation: NotificationEvaluation) =>
            evaluation.name.toLowerCase() !== type.name.toLowerCase()
        )
      );
    }
  };

  const onChangeExpiryDate = (event: DatePickerChangeEvent) => {
    if (event.value && event.value > new Date()) {
      setExpiryDate(event.value);
    }
  };

  const submitHandler = async () => {
    if (notificationName === "") {
      setErrorFields([
        ...errorFields,
        {
          field: "notificationName",
          error: translationsLoading
            ? "Notification Name is required"
            : fetchLabelKeyTranslation(
                "FieldError2",
                "Notification Name is required"
              ),
        },
      ]);
    } else {
      const Updatedtopics: NotificationTopic[] = [];
      topics.map((topic: TinyObj) =>
        Updatedtopics.push({
          name: topic.name,
          isPublic: topic.isPublic ?? false,
        })
      );
      const data: CreateNotificationRule = {
        name: notificationName,
        topics: Updatedtopics,
        sentiments: sentiments.map((sentiment: Sentiment) => {
          return sentiment.name;
        }),
        colorCategories: colorCategories.map((color: ColorCategory) => {
          return color.name;
        }),
        evaluations: evaluations.map((evaluation: NotificationEvaluation) => {
          return evaluation.name;
        }),
        callDirection: callDirection?.name,
        callType: callType?.name ?? undefined,
        userEmails: userEmails.map((email: TinyUser) => {
          return email.email;
        }),
        userGroups: userGroups.map((group: TinyObj) => {
          return group.name;
        }),
        expiryDate: !expiryDate ? null : expiryDate.toISOString(),
      };
      setLoader(true);
      try {
        const response = notificationUpdateData
          ? await axiosWithAuth.put(
              `/NotificationRule/${notificationUpdateData.id}`,
              data
            )
          : await axiosWithAuth.post("/NotificationRule", data);
        const swalOptions: any = {
          customClass: {
            container: "my-swal",
          },
          title: `${
            notificationUpdateData
              ? translationsLoading
                ? `Notification Rule updated`
                : fetchLabelKeyTranslation(
                    "SwalUpdateTitle",
                    `Notification Rule updated`
                  )
              : translationsLoading
              ? "Notification Rule created"
              : fetchLabelKeyTranslation(
                  "SwalCreateTitle",
                  "Notification Rule created"
                )
          }`,
          text: `${
            notificationUpdateData
              ? translationsLoading
                ? `Your notification rule has been updated`
                : fetchLabelKeyTranslation(
                    "SwalUpdateText",
                    `Your notification rule has been updated`
                  )
              : translationsLoading
              ? `Your notification rule has been created`
              : fetchLabelKeyTranslation(
                  "SwalCreateText",
                  `Your notification rule has been created`
                )
          }`,
          icon: "success",
          showCancelButton: false,
          confirmButtonColor: "#3085d6",
          confirmButtonText: translationsLoading
            ? "Ok"
            : fetchLabelKeyTranslation("SwalConfirmText", "Ok"),
        };
        await Swal.fire(swalOptions);
        upsertNotificationRule(
          notificationUpdateData ? false : true,
          response.data
        );
        toggleDialog();
        setLoader(false);
      } catch (error) {
        if (error instanceof AxiosError) {
          const swalOptions: any = {
            customClass: {
              container: "my-swal",
            },
            title: `${
              notificationUpdateData
                ? translationsLoading
                  ? "Notification Rule updation failed"
                  : fetchLabelKeyTranslation(
                      "SwalUpdateTitleFailed",
                      "Notification Rule updation failed"
                    )
                : translationsLoading
                ? "Notification Rule creation failed"
                : fetchLabelKeyTranslation(
                    "SwalCreateTitleFailed",
                    "Notification Rule creation failed"
                  )
            }`,
            text: `${
              notificationUpdateData
                ? translationsLoading
                  ? "Notification Rule cannot be updated. Please try again later."
                  : fetchLabelKeyTranslation(
                      "SwalUpdateTextFailed",
                      "Notification Rule cannot be updated. Please try again later."
                    )
                : translationsLoading
                ? "Notification Rule cannot be created. Please try again later."
                : fetchLabelKeyTranslation(
                    "SwalCreateTextFailed",
                    "Notification Rule cannot be created. Please try again later."
                  )
            }`,
            icon: "error",
            showCancelButton: false,
            confirmButtonColor: "#3085d6",
            confirmButtonText: translationsLoading
              ? "Ok"
              : fetchLabelKeyTranslation("SwalConfirmText", "Ok"),
          };
          await Swal.fire(swalOptions);
        }
        setLoader(false);
      }
    }
  };

  return (
    <>
      <div
        className="addNotificationForm"
        style={{
          overflowY: "auto",
          overflowX: "hidden",
          padding: "17px 6px 3px 6px",
        }}
      >
        <div className="row">
          <div className="col-md-12">
            <div className="noteFormRow rowNoteName d-flex align-items-center p-b-18">
              <div
                className="noteFormCol fs-13 font-weight-semi"
                style={{
                  width: "165px",
                  padding: "0 5px 0 0",
                }}
              >
                {translationsLoading
                  ? "Notification Name"
                  : fetchLabelKeyTranslation(
                      "UpsertNotificationSetupTitle",
                      "Notification Name"
                    )}
              </div>
              <div className="noteFormCol w-100">
                <Input
                  className="noteFormColIput fs-13"
                  placeholder={
                    translationsLoading
                      ? "Enter notification name"
                      : fetchLabelKeyTranslation(
                          "NotificationNamePlaceholder",
                          "Enter notification name"
                        )
                  }
                  name="search"
                  value={notificationName}
                  id="search"
                  type="text"
                  style={{
                    height: "30px",
                  }}
                  onChange={onChangeNotificationName}
                />
                <Error>
                  {
                    errorFields.find(
                      (error: ErrorField) => error.field === "notificationName"
                    )?.error
                  }
                </Error>
              </div>
            </div>
            <div className="boxLabOuter">
              <div className="row">
                <div className="col-md-6 m-b-20">
                  <div className="boxLab">
                    <div className="labHeading">
                      {translationsLoading
                        ? "Calls for this notification"
                        : fetchLabelKeyTranslation(
                            "CallTypeHeading",
                            "Calls for this notification"
                          )}
                    </div>
                    <div className="labSubHeading">
                      {translationsLoading
                        ? "Choose the call types you wish to monitor for this notification."
                        : fetchLabelKeyTranslation(
                            "CallTypeSubHeading",
                            "Choose the call types you wish to monitor for this notification."
                          )}
                    </div>
                    <div className="labInputBox">
                      <div className="labInputLbl">
                        {" "}
                        {translationsLoading
                          ? "Call Type"
                          : fetchLabelKeyTranslation(
                              "CallTypeFieldTitle",
                              "Call Type"
                            )}
                      </div>
                      <div className="labInput">
                        <ComboBox
                          style={{ width: "100%" }}
                          data={master?.data?.callTypes}
                          value={callType}
                          onChange={onChangeCallType}
                          placeholder="Please select a call type"
                          textField="name"
                          dataItemKey="name"
                          itemRender={itemrenderCallTypes}
                        />
                      </div>
                    </div>
                    <div className="labInputBox">
                      <div className="labInputLbl">
                        {translationsLoading
                          ? "Call Direction"
                          : fetchLabelKeyTranslation(
                              "CallDirectionFieldTitle",
                              "Call Direction"
                            )}
                      </div>
                      <div className="labInput">
                        <ComboBox
                          style={{ width: "100%" }}
                          data={master?.data?.callDirections}
                          value={callDirection}
                          onChange={onChangeCallDirection}
                          placeholder="Please select call direction"
                          itemRender={itemrenderCallDirection}
                          textField="name"
                          dataItemKey="name"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 m-b-20">
                  <div className="boxLab">
                    <div className="labHeading">
                      {translationsLoading
                        ? "Notification Criteria"
                        : fetchLabelKeyTranslation(
                            "NotificationCriteriaHeading",
                            "Notification Criteria"
                          )}
                    </div>
                    <div className="labSubHeading">
                      {translationsLoading
                        ? "Tailor the notification criteria based on your preferences."
                        : fetchLabelKeyTranslation(
                            "NotificationCriteriaSubHeading",
                            "Tailor the notification criteria based on your preferences."
                          )}
                    </div>
                    <div className="labInputBox">
                      <div className="labInputLbl">
                        {" "}
                        {translationsLoading
                          ? "Topics"
                          : fetchLabelKeyTranslation(
                              "TopicFieldTitle",
                              "Topics"
                            )}
                      </div>
                      <div className="labInput">
                        <CustomMultiSelectFilteringWithoutForm
                          data={topicsData}
                          onChange={onChangeTopics}
                          value={topics}
                          placeholder="Please select ..."
                          itemRender={itemrenderTopics}
                          dataItemKey="name"
                          textField="name"
                        />
                      </div>
                    </div>
                    <div className="optionSelectBox">
                      <div className="row">
                        <div className="col-md-5 m-b-13">
                          <div className="chipBox d-flex align-items-center">
                            <div className="chipBoxLbl">
                              {" "}
                              {translationsLoading
                                ? "Sentiment"
                                : fetchLabelKeyTranslation(
                                    "SentimentFieldTitle",
                                    "Sentiment"
                                  )}
                            </div>
                            <div className="chipBoxBtn">
                              <ButtonGroup>
                                <Button
                                  selected={
                                    !sentiments.find(
                                      (sentiment: Sentiment) =>
                                        sentiment.name.toLowerCase() ===
                                        "positive"
                                    )
                                      ? false
                                      : true
                                  }
                                  onClick={() =>
                                    onChangeSentiments(
                                      master?.data?.sentiments.find(
                                        (s: Sentiment) =>
                                          s.name.toLowerCase() === "positive"
                                      )
                                    )
                                  }
                                  togglable={true}
                                >
                                  <span>
                                    <i className="bi bi-emoji-smile-fill text-success"></i>
                                  </span>
                                </Button>
                                <Button
                                  selected={
                                    !sentiments.find(
                                      (sentiment: Sentiment) =>
                                        sentiment.name.toLowerCase() ===
                                        "neutral"
                                    )
                                      ? false
                                      : true
                                  }
                                  onClick={() =>
                                    onChangeSentiments(
                                      master?.data?.sentiments.find(
                                        (s: Sentiment) =>
                                          s.name.toLowerCase() === "neutral"
                                      )
                                    )
                                  }
                                  togglable={true}
                                >
                                  <span>
                                    <i className="bi bi-emoji-neutral-fill text-warning"></i>
                                  </span>
                                </Button>
                                <Button
                                  selected={
                                    !sentiments.find(
                                      (sentiment: Sentiment) =>
                                        sentiment.name.toLowerCase() ===
                                        "negative"
                                    )
                                      ? false
                                      : true
                                  }
                                  onClick={() =>
                                    onChangeSentiments(
                                      master?.data?.sentiments.find(
                                        (s: Sentiment) =>
                                          s.name.toLowerCase() === "negative"
                                      )
                                    )
                                  }
                                  togglable={true}
                                >
                                  <span>
                                    <i className="bi bi-emoji-frown-fill text-danger"></i>
                                  </span>
                                </Button>
                              </ButtonGroup>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-7 m-b-13">
                          <div className="chipBox d-flex align-items-center float-right">
                            <div className="chipBoxLbl">
                              {translationsLoading
                                ? "Flag"
                                : fetchLabelKeyTranslation(
                                    "FlagFieldTitle",
                                    "Flag"
                                  )}
                            </div>
                            <div className="chipBoxBtn">
                              <ButtonGroup>
                                {master?.data?.colorCategories.map(
                                  (colorCategory) => {
                                    return (
                                      <Button
                                        selected={
                                          !colorCategories.find(
                                            (color: ColorCategory) =>
                                              color.name.toLowerCase() ===
                                              colorCategory.name.toLowerCase()
                                          )
                                            ? false
                                            : true
                                        }
                                        onClick={() =>
                                          onChangeFlags(colorCategory)
                                        }
                                        togglable={true}
                                      >
                                        <span
                                          className="flagRed"
                                          style={{
                                            color: colorCategory.hexCode,
                                          }}
                                        >
                                          <i className="bi bi-flag-fill"></i>
                                        </span>
                                      </Button>
                                    );
                                  }
                                )}
                              </ButtonGroup>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="chipBox d-flex align-items-center">
                            <div className="chipBoxLbl">
                              {translationsLoading
                                ? "Evaluation"
                                : fetchLabelKeyTranslation(
                                    "EvaluationFieldTitle",
                                    "Evaluation"
                                  )}
                            </div>
                            <div className="chipBoxBtn evlBoxBtn">
                              <ButtonGroup>
                                <Button
                                  selected={
                                    !evaluations.find(
                                      (evaluation: NotificationEvaluation) =>
                                        evaluation.name.toLowerCase() === "good"
                                    )
                                      ? false
                                      : true
                                  }
                                  onClick={() =>
                                    onChangeEvaluation({ id: 1, name: "GOOD" })
                                  }
                                  togglable={true}
                                >
                                  <span className="sentimentCircleLbl bg-success text-white fs-11 p-l-4 p-r-4 radius-3">
                                    {translationsLoading
                                      ? "Good"
                                      : fetchLabelKeyTranslation(
                                          "EvaluationFieldOption1",
                                          "Good"
                                        )}
                                  </span>
                                </Button>
                                <Button
                                  selected={
                                    !evaluations.find(
                                      (evaluation: NotificationEvaluation) =>
                                        evaluation.name.toLowerCase() ===
                                        "average"
                                    )
                                      ? false
                                      : true
                                  }
                                  onClick={() =>
                                    onChangeEvaluation({
                                      id: 2,
                                      name: "AVERAGE",
                                    })
                                  }
                                  togglable={true}
                                >
                                  <span className="sentimentCircleLbl bg-warning text-white fs-11 p-l-4 p-r-4 radius-3">
                                    {translationsLoading
                                      ? "Average"
                                      : fetchLabelKeyTranslation(
                                          "EvaluationFieldOption2",
                                          "Average"
                                        )}
                                  </span>
                                </Button>
                                <Button
                                  selected={
                                    !evaluations.find(
                                      (evaluation: NotificationEvaluation) =>
                                        evaluation.name.toLowerCase() === "poor"
                                    )
                                      ? false
                                      : true
                                  }
                                  onClick={() =>
                                    onChangeEvaluation({ id: 3, name: "POOR" })
                                  }
                                  togglable={true}
                                >
                                  <span className="sentimentCircleLbl bg-danger text-white fs-11 p-l-4 p-r-4 radius-3">
                                    {translationsLoading
                                      ? "Poor"
                                      : fetchLabelKeyTranslation(
                                          "EvaluationFieldOption3",
                                          "Poor"
                                        )}
                                  </span>
                                </Button>

                                <Button
                                  selected={
                                    !evaluations.find(
                                      (evaluation: NotificationEvaluation) =>
                                        evaluation.name.toLowerCase() ===
                                        "notevaluated"
                                    )
                                      ? false
                                      : true
                                  }
                                  onClick={() =>
                                    onChangeEvaluation({
                                      id: 4,
                                      name: "NotEvaluated",
                                    })
                                  }
                                  togglable={true}
                                >
                                  <span className="sentimentCircleLbl bg-secondary text-white fs-11 p-l-4 p-r-4 radius-3">
                                    {translationsLoading
                                      ? "Not Evaluated"
                                      : fetchLabelKeyTranslation(
                                          "EvaluationFieldOption4",
                                          "Not Evaluated"
                                        )}
                                  </span>
                                </Button>
                              </ButtonGroup>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <Error style={{ marginTop: "10px" }}>
                      {translationsLoading
                        ? "Note: Please select at least one option from Notification Criteria"
                        : fetchLabelKeyTranslation(
                            "NotificationCriteriaWarningText",
                            "Note: Please select at least one option from Notification Criteria"
                          )}
                    </Error>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 m-b-20">
                  <div className="boxLab">
                    <div className="labHeading">
                      {" "}
                      {translationsLoading
                        ? "Targeted Users"
                        : fetchLabelKeyTranslation(
                            "TargetedUserHeading",
                            "Targeted Users"
                          )}
                    </div>
                    <div className="labSubHeading">
                      {translationsLoading
                        ? "Choose the specific users or user groups to target for this notification."
                        : fetchLabelKeyTranslation(
                            "TargetedUserSubHeading",
                            "Choose the specific users or user groups to target for this notification."
                          )}
                    </div>
                    <div className="labInputBox">
                      <div className="labInputLbl">
                        {translationsLoading
                          ? "User"
                          : fetchLabelKeyTranslation("UserFieldTitle", "User")}
                      </div>
                      <div className="labInput">
                        <CustomMultiSelectFilteringWithoutForm
                          data={userEmailsData}
                          onChange={onChangeUserEmails}
                          value={userEmails}
                          placeholder={
                            translationsLoading
                              ? "Please Select..."
                              : fetchLabelKeyTranslation(
                                  "UserFieldPlaceholder",
                                  "Please Select..."
                                )
                          }
                          itemRender={itemrenderEmails}
                          dataItemKey="email"
                          textField="email"
                        />
                      </div>
                    </div>
                    <div className="labInputBox">
                      <div className="labInputLbl">
                        {translationsLoading
                          ? "User Group"
                          : fetchLabelKeyTranslation(
                              "UserGroupFieldTitle",
                              "User Group"
                            )}
                      </div>
                      <div className="labInput">
                        <CustomMultiSelectFilteringWithoutForm
                          data={userGroupsData}
                          onChange={onChangeUserGroups}
                          value={userGroups}
                          placeholder={
                            translationsLoading
                              ? "Please Select..."
                              : fetchLabelKeyTranslation(
                                  "UserGroupFieldPlaceholder",
                                  "Please Select..."
                                )
                          }
                          itemRender={itemrenderGroups}
                          dataItemKey="name"
                          textField="name"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 m-b-20">
                  <div className="boxLab">
                    <div className="labHeading">
                      {translationsLoading
                        ? "Notification Expiry"
                        : fetchLabelKeyTranslation(
                            "NotificationExpiryHeading",
                            "Notification Expiry"
                          )}
                    </div>
                    <div className="labSubHeading">
                      {translationsLoading
                        ? "Notification remains enabled indefinitely"
                        : fetchLabelKeyTranslation(
                            "NotificationExpirySubHeading",
                            "Notification remains enabled indefinitely"
                          )}
                    </div>
                    <div className="labYesNo p-b-20 p-t-10">
                      <span className="p-r-20">
                        <RadioButton
                          name="group1"
                          value="Yes"
                          checked={radioButtonValue === "Yes"}
                          label={
                            translationsLoading
                              ? "Yes"
                              : fetchLabelKeyTranslation(
                                  "NotificationExpiryRadioButtonLabel1",
                                  "Yes"
                                )
                          }
                          onChange={handleChange}
                        />
                      </span>
                      <span>
                        <RadioButton
                          name="group1"
                          value="No"
                          checked={radioButtonValue === "No"}
                          label={
                            translationsLoading
                              ? "No"
                              : fetchLabelKeyTranslation(
                                  "NotificationExpiryRadioButtonLabel2",
                                  "No"
                                )
                          }
                          onChange={handleChange}
                        />
                      </span>
                    </div>
                    {radioButtonValue === "No" && (
                      <div className="labInputBox">
                        <div className="labInputLbl">
                          {translationsLoading
                            ? "Set Notification Expiry date"
                            : fetchLabelKeyTranslation(
                                "NotificationExpiryTitle",
                                "Set Notification Expiry date"
                              )}
                        </div>
                        <div className="labInput">
                          <DatePicker
                            min={new Date()}
                            value={expiryDate}
                            onChange={onChangeExpiryDate}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <DialogActionsBar>
        <button
          className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
          onClick={toggleDialog}
        >
          {translationsLoading
            ? "Cancel"
            : fetchLabelKeyTranslation("ButtonCancelText", "Cancel")}
        </button>
        <button
          className={`k-button k-button-md k-rounded-md k-button-solid k-button-solid-base bg-primary 
          ${
            errorFields.length > 0 ||
            (topics.length === 0 &&
              sentiments.length === 0 &&
              evaluations.length === 0 &&
              colorCategories.length === 0) ||
            loader
              ? `text-white-7`
              : `text-white`
          } `}
          onClick={submitHandler}
          disabled={
            errorFields.length > 0 ||
            (topics.length === 0 &&
              sentiments.length === 0 &&
              evaluations.length === 0 &&
              colorCategories.length === 0) ||
            loader
          }
        >
          {!notificationUpdateData
            ? translationsLoading
              ? "Add"
              : fetchLabelKeyTranslation("ButtonAddText", "Add")
            : translationsLoading
            ? "Update"
            : fetchLabelKeyTranslation("ButtonUpdateText", "Update")}
        </button>
      </DialogActionsBar>
    </>
  );
};

export default UpsertNotificationSetup;
