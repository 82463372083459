import { ReactNode, useEffect, useState } from "react";
import {
  Drawer,
  DrawerContent,
  DrawerSelectEvent,
} from "@progress/kendo-react-layout";
import { Button } from "@progress/kendo-react-buttons";
import UserManagement from "./user-management/UserManagement";
import UserGroups from "./user-groups/UserGroups";
import AuthorizationProfiles from "./authorization-profile/AuthorizationProfiles";
import { AccessPermissionEnum } from "../../../enums";
import useAuth from "../../../hooks/useAuth";
import UnauthorizedAccess from "../../error/UnauthorizedAccess";

interface INavItem {
  text?: string;
  icon?: string;
  selected?: boolean;
  content?: ReactNode;
}

const items: INavItem[] = [
  {
    text: "Users",
    icon: "bi bi-person",
    selected: true,
    content: <UserManagement />,
  },
  {
    text: "User Groups",
    icon: "bi bi-people",
    selected: false,
    content: <UserGroups />,
  },
  {
    text: "Permission Groups",
    icon: "bi bi-shield-check",
    selected: false,
    content: <AuthorizationProfiles />,
  },
];

interface UserControlProps {}

const UserControl: React.FC<UserControlProps> = () => {
  const auth = useAuth();
  const [expanded, setExpanded] = useState<boolean>(true);
  const [navItems, setNavItems] = useState<INavItem[]>([]);
  const [selectedId, setSelectedId] = useState<number>(
    0
    // items.findIndex((x) => x.selected === true)
  );

  useEffect(() => {
    setNavItems(getTabItems());
  }, []);

  const getTabItems = () => {
    var _navItem = items
      .filter((item) => {
        if (
          (item.text === "Users" &&
            auth?.checkUserAccess(AccessPermissionEnum.ManageUsersAndGroups)) ||
          (item.text === "User Groups" &&
            auth?.checkUserAccess(AccessPermissionEnum.ManageUsersAndGroups)) ||
          (item.text === "Permission Groups" &&
            auth?.checkUserAccess(AccessPermissionEnum.ManageSecurityControl))
        ) {
          return true;
        } else {
          return false;
        }
      })
      .map((item, index) => {
        return {
          ...item,
          selected: index === selectedId,
        };
      });

    return _navItem;
  };

  const handleExpandChange = () => {
    setExpanded((prevState) => !prevState);
  };

  const handleSelectionChange = (event: DrawerSelectEvent) => {
    navItems.forEach((i, idx) => {
      if (idx === event.itemIndex) {
        i.selected = true;
      } else {
        i.selected = false;
      }
    });
    setSelectedId(event.itemIndex);
    setExpanded(true);
  };

  return (
    <>
      {!auth?.checkUserAccess(AccessPermissionEnum.ManageUsersAndGroups) &&
      !auth?.checkUserAccess(AccessPermissionEnum.ManageSecurityControl) ? (
        <UnauthorizedAccess />
      ) : (
        <div className="mainDrawer navPushDown navPush p-t-55">
          <div className="mainDrawerInner">
            <div className="miniButton">
              <Button icon="menu" fillMode="flat" onClick={handleExpandChange}>
                <i className="bi bi-list"></i>
              </Button>
            </div>
            <Drawer
              expanded={expanded}
              position="start"
              mode="push"
              width={220}
              mini={true}
              items={navItems}
              onSelect={handleSelectionChange}
            >
              <DrawerContent>
                <div className="contentPush">
                  {navItems.find((item) => item.selected === true)?.content}
                </div>
              </DrawerContent>
            </Drawer>
          </div>
        </div>
      )}
    </>
  );
};

export default UserControl;
