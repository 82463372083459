import { align } from "@progress/kendo-drawing";
import { Button } from "@progress/kendo-react-buttons";
import { useEffect } from "react";
import useLocale from "../../hooks/useLocale";
import useLocalStorage from "../../hooks/useLocalStorage";

const Footer: React.FC = () => {
  const [userBehaviourTracking, setUserBehaviourTracking] = useLocalStorage<
    string | undefined
  >("UserBehaviourTracking", undefined);

  const AddTrackingScript = () => {
    var userTrackingScriptTag = document.createElement("script");
    userTrackingScriptTag.setAttribute(
      "src",
      "https://cdn.pagesense.io/js/tollring/fd30445c804849179de522922709acda.js"
    );
    document.head.appendChild(userTrackingScriptTag);
  };

  const allowUserTracking = () => {
    setUserBehaviourTracking("Allow")
    AddTrackingScript()
  }

  const denyUserTracking = () => {
    setUserBehaviourTracking("Deny")
  }

  useEffect(() => {
    if(userBehaviourTracking === "Allow")
    {
      AddTrackingScript()
    }
  }, [])

  return (
    <div>
      <table className="footerContent" style={{background:"#fff"}}>
      {userBehaviourTracking === undefined && (
        <tr>
          <td style={{ width: "70%" }}>
            Our website uses cookies to give you the best and most relevant
            experience. By clicking on accept, you give your consent to the use
            of cookies as per our privacy policy.
          </td>
          <td style={{ justifyContent: "center" }}>
            <Button className="btn bg-primary text-white m-r-10" onClick={allowUserTracking}>
              Accept
            </Button>
            <Button className="btn bg-black-5" onClick={denyUserTracking}>Deny</Button>
          </td>
        </tr>
      )}
    </table>
    </div>
  );
};

export default Footer;
