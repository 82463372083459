import { useState } from 'react';
//@ts-ignore
import { Text } from '@visx/text';
import { scaleLog } from '@visx/scale';

import Wordcloud from '@visx/wordcloud/lib/Wordcloud';

interface ExampleProps {
    width: number;
    height: number;
    showControls?: boolean;
    wordcloudData: { text: string, value: number; count: number; }[];
    onMetricClickHandler: (metricKey: string, metricKeyLabel?: string) => void;
}

export interface WordData {
    text: string;
    value: number;
    count: number;
}

const colors = ['#143059', '#2F6B9A', '#82a6c2'];

function getRotationDegree() {
    const rand = Math.random();
    const degree = rand > 0.5 ? 60 : -60;
    return rand * degree;
}

const fixedValueGenerator = () => 0.5;

type SpiralType = 'archimedean' | 'rectangular';

export default function WordcloudWrapper({ width, height, showControls/* = true*/, wordcloudData, onMetricClickHandler }: ExampleProps) {

    const fontScale = scaleLog({
        domain: [Math.min(...wordcloudData.map((w) => w.value)), Math.max(...wordcloudData.map((w) => w.value))],
        range: [10, 50],
    });

    function fontSizeSetter(datum: WordData) {
        return fontScale(datum.value);
        // return Math.sqrt(datum.value);
    }

    const [spiralType, setSpiralType] = useState<SpiralType>('archimedean');
    const [withRotation, setWithRotation] = useState(true);

    const onWordClick = (w: any) => {
        onMetricClickHandler(w.text,'WordCloud');
    }

    const onWordHover = (w: any) => {
        //console.log(`Count : ${w.count}`)
    }

    return (
        <div className="wordcloud border-0 bg-white">
            <Wordcloud
                words={wordcloudData}
                width={width}
                height={height}
                fontSize={fontSizeSetter}
                font={'Impact'}
                padding={1}
                spiral={spiralType}
                rotate={withRotation ? 0 : 0}
                random={fixedValueGenerator}
            >
                {(cloudWords) =>
                    cloudWords.map((w, i) => (
                        <g key={w.text}
                            onClick={() => onWordClick(w)}
                            onMouseOver={() => onWordHover(w)}
                        >
                            <Text
                                key={w.text}
                                fill={colors[i % colors.length]}
                                textAnchor={'middle'}
                                transform={`translate(${w.x}, ${w.y}) rotate(${w.rotate})`}
                                fontSize={w.size}
                                fontFamily={w.font}
                            >
                                {w.text}
                            </Text>
                        </g>
                    ))
                }
            </Wordcloud>
            {showControls && (
                <div>
                    <label>
                        Spiral type &nbsp;
                        <select
                            onChange={(e) => setSpiralType(e.target.value as SpiralType)}
                            value={spiralType}
                        >
                            <option key={'archimedean'} value={'archimedean'}>
                                archimedean
                            </option>
                            <option key={'rectangular'} value={'rectangular'}>
                                rectangular
                            </option>
                        </select>
                    </label>
                    <label>
                        With rotation &nbsp;
                        <input
                            type="checkbox"
                            checked={withRotation}
                            onChange={() => setWithRotation(!withRotation)}
                        />
                    </label>
                    <br />
                </div>
            )}
            <style>{`
        .wordcloud {
          display: flex;
          flex-direction: column;
          user-select: none;
          border: solid 1px black
        }
        .wordcloud g{
          margin: 1rem 0;
          cursor: pointer;
        }

        .wordcloud label {
          display: inline-flex;
          align-items: center;
          font-size: 14px;
          margin-right: 8px;
        }
        .wordcloud textarea {
          min-height: 100px;
        }
      `}</style>
        </div>
    );
}