import {
  ComboBox,
  ComboBoxFilterChangeEvent,
} from "@progress/kendo-react-dropdowns";
import { FieldRenderProps } from "@progress/kendo-react-form";
import { Hint, Error } from "@progress/kendo-react-labels";
import { filterBy, FilterDescriptor } from "@progress/kendo-data-query";
import { useEffect, useState } from "react";
import { sortDataFunc } from "../../../utils/dropdownSort";

const CustomComboBox = (fieldRenderProps: FieldRenderProps) => {
  const {
    validationMessage,
    touched,
    label,
    id,
    valid,
    disabled,
    hint,
    type,
    optional,
    max,
    rows,
    value,
    showTextLimitHint,
    loading,
    data,
    textField,
    ...others
  } = fieldRenderProps;

  const [items, setItems] = useState(data?.slice());
  const [sortData, setSortData] = useState(data?.slice());
  const showValidationMessage = touched && validationMessage;
  const showHint = !showValidationMessage && hint;
  const hindId = showHint ? `${id}_hint` : "";
  const errorId = showValidationMessage ? `${id}_error` : "";
  const [tempCheck, setTempCheck] = useState(data);

  const filterData = (filter: FilterDescriptor) => {
    const newData = sortData.slice();
    return filterBy(newData, filter);
  };

  const filterChange = (event: ComboBoxFilterChangeEvent) => {
    setItems(filterData(event.filter));
  };

  useEffect(() => {
    setSortData(sortDataFunc(data, textField));
    setItems(sortData?.slice());
  }, [data]);

  return (
    <div className={"k-form-field-wrap"}>
      <ComboBox
        filterable={fieldRenderProps.filtering}
        onFilterChange={filterChange}
        loading={loading}
        data={fieldRenderProps.filtering ? items : sortData}
        value={value}
        disabled={disabled}
        textField={textField}
        {...others}
      />
      {showTextLimitHint && (
        <Hint direction={"end"}>
          {value.length} / {max}
        </Hint>
      )}
      {showHint && <Hint id={hindId}>{hint}</Hint>}
      {showValidationMessage && <Error id={errorId}>{validationMessage}</Error>}
    </div>
  );
};

export default CustomComboBox;
