import * as React from "react";
import {
  MultiSelect,
  ListItemProps,
  MultiSelectFilterChangeEvent,
  MultiSelectChangeEvent,
} from "@progress/kendo-react-dropdowns";
import { filterBy } from "@progress/kendo-data-query";

interface MultiSelectData {
  data: any[] | undefined;
  value: any[] | undefined;
  onChange: (event: MultiSelectChangeEvent) => void;
  textField: string;
  itemRender?: (
    li: React.ReactElement<HTMLLIElement>,
    itemProps: ListItemProps
  ) => React.ReactElement<
    HTMLLIElement,
    string | React.JSXElementConstructor<any>
  >;
  dataItemKey: string;
  disabled?: boolean;
  placeholder?: string;
}

const CustomMultiSelectFilteringWithoutForm: React.FC<MultiSelectData> = ({
  data,
  value,
  onChange,
  textField,
  itemRender,
  dataItemKey,
  disabled,
  placeholder,
}) => {
  const [filterData, setFilterData] = React.useState(data?.slice());

  const filterChange = (event: MultiSelectFilterChangeEvent) => {
    if (data) setFilterData(filterBy(data.slice(), event.filter));
  };

  React.useEffect(() => {
    setFilterData(data?.slice());
  }, [data]);

  return (
    <MultiSelect
      data={filterData}
      onChange={onChange}
      value={value}
      dataItemKey={dataItemKey}
      itemRender={itemRender}
      textField={textField}
      filterable={true}
      onFilterChange={filterChange}
      disabled={disabled}
      placeholder={placeholder}
    />
  );
};

export default CustomMultiSelectFilteringWithoutForm;
