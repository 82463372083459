import * as React from "react";
import * as ReactDOM from "react-dom";
import { Button } from "@progress/kendo-react-buttons";
import {
  TabStrip,
  TabStripSelectEventArguments,
  TabStripTab,
} from "@progress/kendo-react-layout";
import { useEffect, useState } from "react";
import { Loader } from "@progress/kendo-react-indicators";
import { Tooltip } from "@progress/kendo-react-tooltip";
import SystemSettingAccounts from "./SystemSettingAccounts";
import SystemSettingStorage from "./SystemSettingStorage";
import SystemSettingLicense from "./SystemSettingLicense";
import SystemSettingEmail from "./SystemSettingEmail";
import SystemSettingSecurity from "./SystemSettingSecurity";

interface SystemSettingProps {
  shared?: boolean;
}

const SystemSetting: React.FC<SystemSettingProps> = ({ shared }) => {
  // tabs
  const [selected, setSelected] = React.useState<number>(0);
  const handleSelect = (e: TabStripSelectEventArguments) => {
    setSelected(e.selected);
  };

  return (
    <div className="m-b-20">
      <div className="row">
        <div className="col-md-12">
          <div className="card cardEffect">
            <div className="card-header bg-white d-flex justify-content-between">
              <span className="detailHeader d-flex align-items-center">
                <span className="fs-16 font-weight-semi p-r-20">
                  System Setting
                </span>
              </span>
            </div>
            <div className="card-body p-0">
              <div className="row">
                <div className="col-md-12">
                  <div className="settingData k-custom-tab p-t-20 p-r-15 p-l-20 p-b-20">
                    <TabStrip
                      tabPosition={"left"}
                      selected={selected}
                      onSelect={handleSelect}
                    >
                      <TabStripTab title="Accounts">
                        <div>
                          <SystemSettingAccounts />
                        </div>
                      </TabStripTab>
                      <TabStripTab title="Storage">
                        <div>
                          <SystemSettingStorage />
                        </div>
                      </TabStripTab>
                      <TabStripTab title="License">
                        <div>
                          <SystemSettingLicense />
                        </div>
                      </TabStripTab>
                      <TabStripTab title="Email Setting">
                        <SystemSettingEmail />
                      </TabStripTab>
                      <TabStripTab title="Security & Privacy">
                        <SystemSettingSecurity />
                      </TabStripTab>
                    </TabStrip>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SystemSetting;
