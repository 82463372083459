import React, { useEffect, useState } from "react";
import helpguideService from "../../services/helpguide.service";
import { Tooltip } from "@progress/kendo-react-tooltip";

const HelpGuide : React.FC = () => {

    const getHelpGuideURL = async ()  => {
        let url = await helpguideService.getHelpGuideURL();
        
        window.open(url.message);
    }

    return <React.Fragment>
        <div className="hdrTrk-nav ml-auto">
            <div className="nav-custom" style={{paddingTop:"9px", paddingLeft:"15px"}}>
                <Tooltip
                    anchorElement={"target"}
                    position={"bottom"}
                    parentTitle={true}
                    tooltipStyle={{ fontSize: "12px" }}
                    >
                    <i title="Help" 
                       className="bi bi-question-circle"
                       onClick={getHelpGuideURL}
                       style={{fontSize:"20px"}}
                       >                       
                    </i>
                </Tooltip>
            </div>
        </div>
    </React.Fragment>
};

export default HelpGuide