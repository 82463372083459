import { Button } from "@progress/kendo-react-buttons";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { ComboBox, ComboBoxChangeEvent } from "@progress/kendo-react-dropdowns";
import React, { useEffect, useRef, useState } from "react";
import { ShortScorcardType } from "../../pages/call-details/RecordingScorecardTab";
import scorecardService from "../../services/scorecard.service";
import { Scorecard } from "../../types/scorecard";
import { sortDataFunc } from "../../utils/scorecardSort";

interface ScorecardSelectDialogProps {
  selectedScorecard: ShortScorcardType;
  onCloseDialog: () => void;
  onScorecardSelect: (scorecard: ShortScorcardType) => void;
  fetchKeyTranslation: (key: string, defaultVal: string) => string;
  translationLoading: boolean;
  allocatedScorecards?: Scorecard[];
}

const ScorecardSelectDialog: React.FC<ScorecardSelectDialogProps> = ({
  selectedScorecard,
  onCloseDialog,
  onScorecardSelect,
  fetchKeyTranslation,
  translationLoading,
  allocatedScorecards,
}) => {
  const scorecardComboxBoxRef = useRef<any>();
  const [value, setValue] = useState<ShortScorcardType>(selectedScorecard);

  const [scorecards, setScorecards] = useState<
    ShortScorcardType[] | undefined
  >();
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const sendFetchScorecardNames = async () => {
      try {
        setLoading(true);
        const data = await scorecardService.fetchAllScorecards();
        const shortNamesSuggested: ShortScorcardType[] = [],
          shortNamesOthers: ShortScorcardType[] = [];
        const filteredScorecards: Scorecard[] = data.filter((sc) => {
          return sc.isArchived === false;
        });
        filteredScorecards.forEach((s: Scorecard) => {
          if (
            allocatedScorecards?.find(
              (allocatedSC: Scorecard) => allocatedSC.id === s.id
            )
          ) {
            shortNamesSuggested.push({
              id: s.id,
              text: s.scorecardName,
              isArchived: false,
              position: "Suggested",
            });
          }
        });
        const sortedShortNamesSuggested: ShortScorcardType[] =
          sortDataFunc(shortNamesSuggested);
        filteredScorecards.length !== 0 &&
          sortedShortNamesSuggested.unshift({
            id: 0,
            text: "",
            isArchived: false,
            position: " ",
          });
        filteredScorecards.forEach((s: Scorecard) => {
          if (
            !allocatedScorecards?.find(
              (allocatedSC: Scorecard) => allocatedSC.id === s.id
            )
          ) {
            shortNamesOthers.push({
              id: s.id,
              text: s.scorecardName,
              isArchived: false,
              position: "Others",
            });
          }
        });
        const sortedShortNamesOthers: ShortScorcardType[] =
          sortDataFunc(shortNamesOthers);
        setScorecards(sortedShortNamesSuggested.concat(sortedShortNamesOthers));
      } catch (err) {
        console.log(err);
      } finally {
        setLoading(false);
      }
    };
    sendFetchScorecardNames();
  }, []);

  const onChangeHandler = (e: ComboBoxChangeEvent) => {
    setValue(e.value);
  };

  const onSubmitHandler = () => {
    const scorecard = scorecardComboxBoxRef?.current.value;
    onScorecardSelect(scorecard);
  };

  const itemRender = (li: any, itemProps: any) => {
    const item = <span>{li.props.children}</span>;
    return itemProps.dataItem.text !== ""
      ? React.cloneElement(li, li.props, item)
      : null;
  };

  return (
    <Dialog
      title={`${
        translationLoading
          ? "Scorecards"
          : fetchKeyTranslation("TextScorecards", "Scorecards")
      }`}
      onClose={onCloseDialog}
    >
      <p className="m-t-30 m-b-40" style={{ textAlign: "center" }}>
        <ComboBox
          ref={scorecardComboxBoxRef}
          style={{
            width: "350px",
            minHeight: "36px",
          }}
          textField="text"
          dataItemKey="id"
          data={scorecards}
          value={value}
          loading={loading}
          onChange={onChangeHandler}
          defaultValue={{
            id: -1,
            text: `${
              translationLoading
                ? "Select"
                : fetchKeyTranslation("PlaceholderSelect", "Select")
            }`,
          }}
          allowCustom={false}
          groupField="position"
          itemRender={itemRender}
        />
      </p>
      <DialogActionsBar>
        <Button className="btn" onClick={onCloseDialog}>
          {`${
            translationLoading
              ? "Cancel"
              : fetchKeyTranslation("CancelText", "Cancel")
          }`}
        </Button>
        <Button className="btn bg-primary text-white" onClick={onSubmitHandler}>
          {`${
            translationLoading
              ? "Select"
              : fetchKeyTranslation("PlaceholderSelect", "Select")
          }`}
        </Button>
      </DialogActionsBar>
    </Dialog>
  );
};

export default ScorecardSelectDialog;
