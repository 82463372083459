import { Topic } from "../types/topic";
import { InsertTopic } from "../types/topic/InsertTopic";
import { UpdateTopic } from "../types/topic/UpdateTopic";
import { axiosWithAuth } from "../utils/customAxios";

const topicsService = {
  fetchAllTopics: async (): Promise<Topic[]> => {
    try {
      const response = await axiosWithAuth.get(`/topic`);
      const topics: Topic[] = response.data;

      return topics;
    } catch (ex) {
      throw ex;
    }
  },

  upsertTopic: async (topic: Topic): Promise<Topic> => {
    try {
      // update topic
      if (topic.id > 0) {
        const updatedTopicObj: UpdateTopic = {
          id: topic.id,
          topicName: topic.topicName,
          description: topic.description,
          isPublic: topic.isPublic,
          words: topic.words
        }
        const response = updateTopic(updatedTopicObj)
        return response;
      }
      // insert new topic
      else {
        const insertTopicObj : InsertTopic = {
          topicName: topic.topicName,
          description: topic.description,
          isPublic: topic.isPublic,
          words: topic.words,
          topicType: topic.type.masterDataKey
        }
        const response = await insertTopic(insertTopicObj)
        return response;
      }
    } catch (ex) {
      throw ex;
    }
  },

  deleteTopic: async (id: number) => {
    try {
      const response = await axiosWithAuth.delete(`topic/${id}`);
      if (response.data <= 0) {
        throw Error("Topic could not be deleted!");
      }
    } catch (ex) {
      throw ex;
    }
  },
};

export default topicsService;


const insertTopic =  async (topic: InsertTopic) : Promise<Topic> => {
  const response = await axiosWithAuth.post(`/topic`, topic)
  const addedTopic : Topic =response.data;
  return addedTopic
}

const updateTopic = async (topic : UpdateTopic) : Promise<Topic> => {
  const response = await axiosWithAuth.put(`/topic/${topic.id}`, topic);
      const updatedTopic: Topic = response.data;

      return updatedTopic;
}