import * as React from "react";
import {
  ListItemProps,
  ComboBox,
  ComboBoxChangeEvent,
  ComboBoxFilterChangeEvent,
} from "@progress/kendo-react-dropdowns";

interface ComboBoxData {
  data: any[] | undefined;
  value: any;
  onChange: (event: ComboBoxChangeEvent) => void;
  textField: string;
  itemRender?: (
    li: React.ReactElement<HTMLLIElement>,
    itemProps: ListItemProps
  ) => React.ReactElement<
    HTMLLIElement,
    string | React.JSXElementConstructor<any>
  >;
  dataItemKey: string;
  disabled?: boolean;
  placeholder?: string;
}

const CustomComboBoxFilteringWithoutForm: React.FC<ComboBoxData> = ({
  data,
  value,
  onChange,
  textField,
  itemRender,
  dataItemKey,
  disabled,
  placeholder,
}) => {
  const [filterData, setFilterData] = React.useState(data?.slice());

  const filterChange = (event: ComboBoxFilterChangeEvent) => {
    if (data) {
      const filteredData = data.filter((row) =>
        Object.entries(row).some(([key, value]: [string, any]) => {
          if (key.toLowerCase() === 'email' && typeof value === 'string') {
            return value.toLowerCase().includes(event.filter.value.toLowerCase());
          }
          else if (key.toLowerCase() === 'firstname' && typeof value === 'string') {
            return value.toLowerCase().includes(event.filter.value.toLowerCase());
          }
          else if (key.toLowerCase() === 'lastname' && typeof value === 'string') {
            return value.toLowerCase().includes(event.filter.value.toLowerCase());
          }
          return false;
        })
      );

      setFilterData(filteredData)
      // if (data) setFilterData(filterBy(data.slice(), event.filter));
    };
  };

  React.useEffect(() => {
    setFilterData(data?.slice());
  }, [data]);

  return (
    <ComboBox
      data={filterData}
      onChange={onChange}
      value={value}
      dataItemKey={dataItemKey}
      itemRender={itemRender}
      textField={textField} // which property of the selected object has to be displayed in the textfied
      filterable={true}
      onFilterChange={filterChange}
      disabled={disabled}
      placeholder={placeholder}
    />
  );
};

export default CustomComboBoxFilteringWithoutForm;
