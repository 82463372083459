import { AuditLogsData, Page } from "../types/audit-logs/AuditLogs";
import { AuditFilter } from "../types/filters";
import { axiosWithAuth } from "../utils/customAxios";

const auditLogsService = {
  fetchAllAuditLogs: async (filter: AuditFilter,pageData:Page,searchTerm:string): Promise<AuditLogsData> => {
    let requestQuery = "";
    if(filter){
      requestQuery += "?";
      const queryFilters: string[] = [];
      const startDateFilter = filter.startDate
        ? `startdate=${filter.startDate}%2000:00:00`
        : "";
      if (startDateFilter.trim() !== "") queryFilters.push(startDateFilter);
      const endDateFilter = filter.endDate ? `enddate=${filter.endDate}%2023:59:59` : "";
      if (endDateFilter.trim() !== "") queryFilters.push(endDateFilter);
      const usersFilter = filter.changedBy
      ? filter.changedBy.map((id:number) => `changedBy=${encodeURIComponent(id)}`).join("&")
      : "";
    if (usersFilter.trim() !== "") queryFilters.push(usersFilter);
    if(filter.entityType&& filter.entityType?.trim()!=="")queryFilters.push(`entityType=${filter.entityType}`);
    if(filter.entityType && filter.entityFriendlyName&& filter.entityFriendlyName.trim()!=="")queryFilters.push(`entityFriendlyName=${filter.entityFriendlyName}`)
    requestQuery += queryFilters.join("&");
  requestQuery+=`&skip=${pageData.skip}&take=${pageData.take}&sortField=${pageData.sort.sortField}&sortDirection=${pageData.sort.sortDirection}&searchTerm=${searchTerm}`
    }
    try {
      const response = await axiosWithAuth.get(`/AuditLog${requestQuery}`);
      const auditLogs: AuditLogsData = response.data;
      return auditLogs;
    } catch (ex) {
      throw ex;
    }
  }
}
export default auditLogsService;