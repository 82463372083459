import React, { useEffect, useRef, useState } from "react";
import { Button, ButtonGroup } from "@progress/kendo-react-buttons";
import moment from "moment";
import { AuditFilter, AuditPeriodFilterObj } from "../types/filters/";
import { Popup } from "@progress/kendo-react-popup";
import { Link } from "react-router-dom";
import useLocale from "../hooks/useLocale";
import { Dictionary } from "../types/Dictionary";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import CustomMultiSelectFilteringWithoutForm from "./custom/form/CustomMultiSelectFilteringWithoutForm";
import {
  ComboBox,
  ComboBoxChangeEvent,
  ListItemProps,
  MultiSelectChangeEvent,
} from "@progress/kendo-react-dropdowns";
import useMasterData from "../hooks/useMasterData";
import { TinyUser } from "../types/user";
import CustomUserGridInfo from "./custom/grid/CustomUserGridInfo";
import { Input, InputChangeEvent } from "@progress/kendo-react-inputs";

type AuditFilterProps = {
  auditFilterData?: AuditFilter;
  handleAuditDataFilter: (auditFilterData: AuditFilter) => void;
  handleFilterDialog: () => void;
};

const AuditFilterDrawer: React.FC<AuditFilterProps> = (props) => {
  const master = useMasterData();
  const localeCtx = useLocale();
  const periodFilterMenu = [
    {
      id: 1,
      title: "Day",
      displayTitle: "Day",
      translationLabelKey: "DayFilterLabel",
      filters: [
        {
          id: 1,
          title: "Today",
          translationLabelKey: "TodayFilterLabel",
          active: true,
        },
        {
          id: 2,
          title: "Yesterday",
          translationLabelKey: "YesterdayFilterLabel",
          active: false,
        },
        {
          id: 3,
          title: "Last 7 Days",
          translationLabelKey: "Last7DaysFilterLabel",
          active: false,
        },
        {
          id: 4,
          title: "Last 30 Days",
          translationLabelKey: "Last30DaysFilterLabel",
          active: false,
        },
      ],
      refObj: useRef<any>(),
      showFilters: false,
    },
    {
      id: 2,
      title: "Week",
      displayTitle: "Week",
      translationLabelKey: "WeekFilterLabel",
      filters: [
        {
          id: 1,
          title: "This Week",
          translationLabelKey: "ThisWeekFilterLabel",
          active: false,
        },
        {
          id: 2,
          title: "Last Week",
          translationLabelKey: "LastWeekFilterLabel",
          active: false,
        },
      ],
      refObj: useRef<any>(),
      showFilters: false,
    },
    {
      id: 3,
      title: "Month",
      displayTitle: "Month",
      translationLabelKey: "MonthFilterLabel",
      filters: [
        {
          id: 1,
          title: "This Month",
          translationLabelKey: "ThisMonthFilterLabel",
          active: false,
        },
        {
          id: 2,
          title: "Last Month",
          translationLabelKey: "LastMonthFilterLabel",
          active: false,
        },
      ],
      refObj: useRef<any>(),
      showFilters: false,
    },
    {
      id: 4,
      title: "Custom",
      displayTitle: "Custom",
      translationLabelKey: "CustomFilterLabel",
      showFilters: false,
    },
  ];

  const [periodFilters, setPeriodFilters] =
    useState<AuditPeriodFilterObj[]>(periodFilterMenu);
  const [periodType, setPeriodType] = useState<AuditPeriodFilterObj>(
    periodFilters[0]
  );
  const [startDate, setStartDate] = useState<Date | null>(new Date());
  const [endDate, setEndDate] = useState<Date | null>(new Date());
  const [users, setUsers] = useState<TinyUser[]>([]);
  const [entityName, setEntityName] = useState<string>("");
  const [entityType, setEntityType] = useState<string | undefined>(undefined);

  const [loading, setLoading] = useState<boolean>(false);
  const [translations, setTranslations] = useState<
    Dictionary<string> | undefined
  >(
    localeCtx?.selectedLocale?.current.componentTranslations["auditLogsFilter"]
  );
  const [error, setError] = useState<string>("");

  const translatePeriodFiltersMenu = (
    updatedTranslations: Dictionary<string> | undefined
  ): AuditPeriodFilterObj[] => {
    const updatedMenu = periodFilters;
    if (updatedTranslations) {
      updatedMenu.forEach((p) => {
        p.displayTitle = updatedTranslations[p.translationLabelKey] ?? p.title;
        if (p.filters) {
          p.filters.forEach((f) => {
            f.title = updatedTranslations[f.translationLabelKey] ?? f.title;
          });
        }
      });
    }
    updatedMenu.forEach((p) => {
      if (p.id === props.auditFilterData?.periodType) {
        if (p.filters) {
          p.filters.forEach((f) => {
            if (f.id === props.auditFilterData?.filterType) {
              p.displayTitle = f.title;
              f.active = true;
            } else {
              f.active = false;
            }
          });
        }
      } else {
        p.filters = p.filters?.map((f) => {
          f.active = false;
          return f;
        });
      }
    });
    return updatedMenu;
  };

  const fetchTranslations = async () => {
    try {
      setLoading(true);
      const resp = await localeCtx?.setComponentTranslations("auditLogsFilter");
      setTranslations(resp);
      setPeriodFilters(translatePeriodFiltersMenu(resp));
    } catch (err) {
      console.error(err);
      setTranslations(
        localeCtx?.selectedLocale?.previous.componentTranslations[
          "auditLogsFilter"
        ]
      );
      localeCtx?.setPreviousAppLocale("auditLogsFilter");
    } finally {
      setTimeout(() => {
        setLoading(false);
      }, 50);
    }
  };

  useEffect(() => {
    if (
      !localeCtx?.selectedLocale?.current.componentTranslations[
        "auditLogsFilter"
      ]
    ) {
      fetchTranslations();
    }
  }, [localeCtx?.selectedLocale]);

  const setFilterValues = () => {
    if (props.auditFilterData) {
      if (
        props.auditFilterData.periodType &&
        props.auditFilterData.filterType
      ) {
        // Reset and Activate Select Period Filter
        const initalPeriodFilterMenu: AuditPeriodFilterObj[] =
          translatePeriodFiltersMenu(translations);
        const updatedPeriodFilters: AuditPeriodFilterObj[] = [];
        initalPeriodFilterMenu.forEach((p) => {
          if (p.id === props.auditFilterData?.periodType) {
            if (p.filters) {
              p.filters.forEach((f) => {
                if (f.id === props.auditFilterData?.filterType) {
                  p.displayTitle = f.title;
                  f.active = true;
                } else {
                  f.active = false;
                }
              });
            }
          } else {
            p.filters = p.filters?.map((f) => {
              f.active = false;
              return f;
            });
          }
          updatedPeriodFilters.push(p);
        });
        setPeriodFilters(updatedPeriodFilters);
        const period =
          periodFilters[
            periodFilters.findIndex(
              (el) => el.id === props.auditFilterData?.periodType
            )
          ];
        period.filters?.forEach((f) => {
          if (f.id === props.auditFilterData?.filterType) {
            f.active = true;
            period.displayTitle = f.title;
          }
        });
        setPeriodType(period);
      } else if (props.auditFilterData.periodType) {
        // Reset the period filters
        const initialFilters = translatePeriodFiltersMenu(translations);
        setPeriodFilters(
          initialFilters.map((p) => {
            p.filters?.forEach((f) => {
              f.active = false;
            });
            return p;
          })
        );

        setPeriodType(
          periodFilters[
            periodFilters.findIndex(
              (el) => el.id === props.auditFilterData?.periodType
            )
          ]
        );
      }

      if (props.auditFilterData?.startDate !== undefined) {
        setStartDate(new Date(props.auditFilterData?.startDate));
      }
      if (props.auditFilterData?.endDate !== undefined) {
        setEndDate(new Date(props.auditFilterData?.endDate));
      }
      if (master) {
        setUsers(
          master?.users?.filter((u) =>
            props.auditFilterData?.changedBy?.includes(u.loginUserId ?? 0)
          )
        );
      }
      setEntityType(
        props.auditFilterData.entityType
          ? props.auditFilterData.entityType
          : undefined
      );
      if (props.auditFilterData.entityFriendlyName)
        setEntityName(props.auditFilterData.entityFriendlyName);
    }
  };

  useEffect(() => {
    setFilterValues();
  }, [props.auditFilterData]);

  useEffect(() => {
    setPeriodFilters((prev) => {
      return prev.map((p) => {
        if (p.id === periodType.id) {
          if (p.filters) {
            const activeFilter = p.filters.find((f) => f.active === true);
            p.displayTitle = activeFilter ? activeFilter.title : p.displayTitle;
          }
        }
        return p;
      });
    });
  }, []);

  const handlePeriodSelection = (
    period: AuditPeriodFilterObj,
    filter: { id: number; title: string; active: boolean }
  ) => {
    // Reset and Activate Select Period Filter
    const updatedPeriodFilters: AuditPeriodFilterObj[] =
      translatePeriodFiltersMenu(translations);
    updatedPeriodFilters.forEach((p) => {
      p.displayTitle =
        translations && translations[p.translationLabelKey]
          ? translations[p.translationLabelKey]
          : p.title;
      if (p.id === period.id) {
        if (p.filters) {
          p.filters.forEach((f) => {
            if (f.id === filter.id) {
              p.displayTitle = f.title;
              f.active = true;
            }
          });
          p.showFilters = false;
        }
      }
    });
    setPeriodFilters(updatedPeriodFilters);

    const tempStartDate = new Date();
    const tempEndDate = new Date();
    switch (period.id) {
      // Day Filter
      case 1: {
        switch (filter.id) {
          // Today
          case 1: {
            break;
          }
          // Yesterday
          case 2: {
            tempStartDate.setDate(tempStartDate.getDate() - 1);
            tempEndDate.setDate(tempEndDate.getDate() - 1);
            break;
          }
          // Last 7 Days
          case 3: {
            tempStartDate.setDate(tempStartDate.getDate() - 6);
            tempEndDate.setDate(tempEndDate.getDate());
            break;
          }
          // Last 30 Days
          case 4: {
            tempStartDate.setDate(tempStartDate.getDate() - 29);
            tempEndDate.setDate(tempEndDate.getDate());
            break;
          }
        }
        break;
      }
      // Week Filter
      case 2: {
        switch (filter.id) {
          // This week
          case 1: {
            const startOfWeek = moment().startOf("isoWeek").toDate();
            tempStartDate.setMonth(startOfWeek.getMonth());
            tempStartDate.setDate(startOfWeek.getDate());
            break;
          }
          // Last Week
          case 2: {
            const startOfLastWeek = moment()
              .subtract(1, "weeks")
              .startOf("isoWeek")
              .toDate();
            tempStartDate.setMonth(startOfLastWeek.getMonth());
            tempStartDate.setDate(startOfLastWeek.getDate());
            const endOfLastWeek = moment()
              .subtract(1, "weeks")
              .endOf("isoWeek")
              .toDate();
            tempEndDate.setMonth(endOfLastWeek.getMonth());
            tempEndDate.setDate(endOfLastWeek.getDate());
            break;
          }
        }
        break;
      }
      // Month Filter
      case 3: {
        switch (filter.id) {
          // This Month
          case 1: {
            const startOfMonth = moment().startOf("month").toDate();
            tempStartDate.setDate(startOfMonth.getDate());
            break;
          }
          // Last Month
          case 2: {
            const startOfLastMonth = moment()
              .subtract(1, "months")
              .startOf("month")
              .toDate();
            tempStartDate.setFullYear(startOfLastMonth.getFullYear());
            tempStartDate.setMonth(startOfLastMonth.getMonth());
            tempStartDate.setDate(startOfLastMonth.getDate());

            const endOfLastMonth = moment()
              .subtract(1, "months")
              .endOf("month")
              .toDate();
            tempEndDate.setFullYear(endOfLastMonth.getFullYear());
            tempEndDate.setMonth(endOfLastMonth.getMonth());
            tempEndDate.setDate(endOfLastMonth.getDate());

            break;
          }
        }
        break;
      }
      // Custom Filter
      default:
        break;
    }
    setStartDate(tempStartDate);
    setEndDate(tempEndDate);
    // Set current period type
    period.filters?.forEach((f) => {
      if (f.id === filter.id) {
        f.active = true;
        period.displayTitle = f.title;
      } else {
        f.active = false;
      }
    });
    setPeriodType(period);
  };

  const handleAuditDataFilter = () => {
    var tempStartDate = startDate;
    var tempEndDate = endDate;
    tempStartDate?.setHours(0, 0, 0);
    tempEndDate?.setHours(23, 59, 59);

    const filterType = periodType.filters?.find((f) => f.active === true);

    var filterValues: AuditFilter = {
      startDate: moment(tempStartDate).format("YYYY-MM-DD"),
      endDate: moment(tempEndDate).format("YYYY-MM-DD"),
      periodType: periodType?.id,
      filterType: filterType?.id,
      entityType,
      entityFriendlyName: entityName,
      changedBy: users?.map((u: TinyUser) => u.loginUserId ?? 0),
    };
    props.handleAuditDataFilter(filterValues);
  };

  const periodFilterClickHandler = (period: AuditPeriodFilterObj) => {
    if (
      period.title.toLocaleLowerCase() === "custom" &&
      periodType?.title.toLocaleLowerCase() !== "custom"
    ) {
      setPeriodType(period);
      setStartDate(new Date());
      setEndDate(new Date());
      setPeriodFilters(translatePeriodFiltersMenu(translations));
    } else {
      setPeriodFilters((prev) => {
        return prev.map((p) => {
          if (p.id === period.id) {
            p.showFilters = !p.showFilters;
          } else {
            p.showFilters = false;
          }
          return p;
        });
      });
    }
  };

  const fetchLabelKeyTranslation = (
    key: string,
    defaultValue: string
  ): string => {
    return translations && translations[key] ? translations[key] : defaultValue;
  };

  const handleClearFilter = () => {
    setPeriodFilters(periodFilterMenu);
    setPeriodType(periodFilterMenu[0]);
    setPeriodFilters((prev) => {
      return prev.map((p) => {
        if (p.id === periodFilterMenu[0].id) {
          if (p.filters) {
            const activeFilter = p.filters.find((f) => f.active === true);
            p.displayTitle = activeFilter ? activeFilter.title : p.displayTitle;
          }
        }
        return p;
      });
    });
    setStartDate(new Date());
    setEndDate(new Date());
    setError("");
    setEntityType(undefined);
    setEntityName("");
    setUsers([]);
  };

  const itemRenderUser = (
    li: React.ReactElement<HTMLLIElement>,
    itemProps: ListItemProps
  ) => {
    const userInfo: TinyUser = {
      id: itemProps.dataItem.id,
      loginUserId: 0,
      firstName: itemProps.dataItem.firstName,
      lastName: itemProps.dataItem.lastName,
      email: itemProps.dataItem.email,
      phoneNo: itemProps.dataItem.phoneNo,
      image: itemProps.dataItem.image,
      isArchived: itemProps.dataItem.isArchived,
    };
    const checkedclass = itemProps.selected ? "liChecked" : "";
    const itemChildren = (
      <span className={checkedclass}>
        <CustomUserGridInfo userInfo={userInfo} selected={itemProps.selected} />
      </span>
    );
    return React.cloneElement(li, li.props, itemChildren);
  };

  return (
    <div className="float-left border-top-1 border-black-1 border-top-solid p-t-10 p-b-10 p-r-15 p-l-15">
      <div className="filterExpd">
        <div className="filterMain m-b-5">
          <div className="filterTrak">
            <div className="row">
              <div className="col-md-12">
                <div className="filterTrak-h fs-15 font-weight-semi p-b-5">
                  {loading
                    ? "Date"
                    : fetchLabelKeyTranslation("PeriodFilterTitle", "Date")}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="filterTrak-btn">
                  <div className="filterTrakRow">
                    <ButtonGroup className="rdButtonFilter">
                      {periodFilters.map((period) => {
                        return (
                          <div key={period.id}>
                            <Button
                              togglable={true}
                              onClick={() => periodFilterClickHandler(period)}
                              selected={period.id === periodType.id}
                            >
                              <span ref={period.refObj}>
                                {period.displayTitle}
                              </span>
                              {period.filters && (
                                <i className="bi bi-chevron-down p-l-3 fs-14"></i>
                              )}
                            </Button>
                            {period.filters && (
                              <Popup
                                anchor={period.refObj?.current}
                                show={period.showFilters}
                                popupClass={"popup-content"}
                                style={{
                                  marginLeft: "-10px",
                                  marginTop: "10px",
                                }}
                              >
                                <ul
                                  className="dropMenu min-w-40 droplist-ul"
                                  style={{
                                    maxWidth: "180px",
                                    width: "120px",
                                    top: "0",
                                    maxHeight: "300px",
                                    overflowY: "auto",
                                    overflowX: "hidden",
                                  }}
                                >
                                  {period.filters.map((filter) => {
                                    return (
                                      <li
                                        key={filter.id}
                                        className="droplist-li"
                                      >
                                        <Link
                                          to="#"
                                          className={`droplist-a ${
                                            filter.active ? "activeFilter" : ""
                                          }`}
                                          onClick={(e) =>
                                            handlePeriodSelection(
                                              period,
                                              filter
                                            )
                                          }
                                        >
                                          {filter.title}
                                        </Link>
                                      </li>
                                    );
                                  })}
                                </ul>
                              </Popup>
                            )}
                          </div>
                        );
                      })}
                    </ButtonGroup>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="filterTrak-date p-t-1">
                  <div className="setSubData">
                    {periodType.title.toLocaleLowerCase() === "custom" && (
                      <div className="setSubTrk">
                        <div className="form-group">
                          <div className="rangeFld">
                            <div className="rangeFldTrk">
                              <div className="rangeInput">
                                <DatePicker
                                  label={
                                    loading
                                      ? "Start Date"
                                      : fetchLabelKeyTranslation(
                                          "StartDateFilterLabel",
                                          "Start Date"
                                        )
                                  }
                                  value={startDate}
                                  max={endDate !== null ? endDate : new Date()}
                                  onChange={(event) =>
                                    setStartDate(event.value)
                                  }
                                  width="100%"
                                />
                              </div>
                            </div>
                            <div
                              className="rangeFldTrk"
                              style={{ paddingRight: "0" }}
                            >
                              <div className="rangeInput">
                                <DatePicker
                                  label={
                                    loading
                                      ? "End Date"
                                      : fetchLabelKeyTranslation(
                                          "EndDateFilterLabel",
                                          "End Date"
                                        )
                                  }
                                  value={endDate}
                                  min={
                                    startDate !== null ? startDate : new Date()
                                  }
                                  max={new Date()}
                                  onChange={(event) => setEndDate(event.value)}
                                  width="100%"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="inrExpandList float-left w-100 p-t-7 p-b-7">
              <div className="float-left w-100">
                <div className="inputList">
                  <div className="inputRow p-b-10">
                    <div className="inputLabel">
                      {loading
                        ? "Entity Type"
                        : fetchLabelKeyTranslation(
                            "EntityTypeFilterLabel",
                            "Entity Type"
                          )}
                    </div>
                    <div className="multiselectBox">
                      <ComboBox
                        data={master?.data?.auditEntityTypes}
                        value={entityType !== undefined ? entityType : null}
                        filterable={true}
                        loading={false}
                        disabled={false}
                        onChange={(event: ComboBoxChangeEvent) => {
                          if (event.value == null) setEntityName("");
                          setEntityType(
                            event.value == null ? undefined : event.value
                          );
                        }}
                      />
                    </div>
                  </div>
                  <div className="inputRow p-b-10">
                    <div className="inputLabel">
                      {loading
                        ? "Entity Name"
                        : fetchLabelKeyTranslation(
                            "EntityNameFilterText",
                            "Entity Name"
                          )}
                    </div>
                    <div className="multiselectBox">
                      <Input
                        onChange={(event: InputChangeEvent) =>
                          setEntityName(event.value)
                        }
                        value={entityName}
                        disabled={!entityType}
                      />
                    </div>
                  </div>
                  <div className="inputRow p-b-10">
                    <div className="inputLabel">
                      {loading
                        ? "Changed By"
                        : fetchLabelKeyTranslation(
                            "ChangedByFilterText",
                            "Changed By"
                          )}
                    </div>
                    <div className="multiselectBox">
                      <CustomMultiSelectFilteringWithoutForm
                        data={master?.users.filter(
                          (user: TinyUser) => user.loginUserId !== 0
                        )}
                        onChange={(event: MultiSelectChangeEvent) =>
                          setUsers([...event.value])
                        }
                        value={users}
                        dataItemKey="loginUserId"
                        itemRender={itemRenderUser}
                        textField="email"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row m-t-15">
              <div className="col-md-12">
                <div className="d-flex justify-content-end">
                  <Button
                    className="btn bg-primary text-white m-r-5"
                    onClick={handleClearFilter}
                  >
                    Clear
                  </Button>
                  <Button
                    disabled={error === "" ? false : true}
                    className="btn bg-primary text-white"
                    onClick={handleAuditDataFilter}
                  >
                    Filter
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AuditFilterDrawer;
