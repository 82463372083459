import { createContext, useEffect, useState } from "react";
import useAuth from "../hooks/useAuth";
import masterDataService from "../services/masterData.service";
import usersService from "../services/users.service";
import { MasterData } from "../types/master-data";
import { TinyUser, User } from "../types/user";

type MasterDataContextType = {
  data?: MasterData;
  users: TinyUser[];
  standardUsers: User[];
};

const MasterDataContext = createContext<MasterDataContextType | null>(null);

export const MasterDataProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const auth = useAuth();
  const [data, setData] = useState<MasterData>();
  const [users, setUsers] = useState<TinyUser[]>([]);
  const [standardUsers, setStandardUsers] = useState<User[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    let isMounted = true;

    const getMasterData = async () => {
      try {
        isMounted && setIsLoading(true);
        const response = await masterDataService.getMasterData([
          "Platform",
          "License",
          "AccessPermission",
          "StorageMode",
          "Sentiment",
          "ColorCategory",
          "RecordingNetworkAccessibilty",
          "CallDirection",
          "CallType",
          "Country",
          "Timezone",
          "CallSubsetTypes",
          "AuditEntityTypes",
        ]);
        setData(response);
      } catch (err) {
        console.error(err);
      } finally {
        isMounted && setIsLoading(false);
      }
    };

    auth?.accessToken ? getMasterData() : setIsLoading(false);

    return () => {
      isMounted = false;
    };
  }, [auth?.accessToken]);

  useEffect(() => {
    let isMounted = true;

    const fetchAppUsers = async () => {
      try {
        isMounted && setIsLoading(true);
        const response = await usersService.getAllTinyUsers();
        setUsers(response);
      } catch (err) {
        console.error(err);
      } finally {
        isMounted && setIsLoading(false);
      }
    };

    const fetchStandardUsers = async () => {
      try {
        isMounted && setIsLoading(true);
        const response = await usersService.fetchUsers();
        setStandardUsers(response);
      } catch (err) {
        console.error(err);
      } finally {
        isMounted && setIsLoading(false);
      }
    };

    auth?.accessToken ? fetchAppUsers() : setIsLoading(false);
    auth?.accessToken ? fetchStandardUsers() : setIsLoading(false);

    return () => {
      isMounted = false;
    };
  }, [auth?.accessToken]);

  return (
    <MasterDataContext.Provider value={{ data, users, standardUsers }}>
      {children}
    </MasterDataContext.Provider>
  );
};

export default MasterDataContext;
