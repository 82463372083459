import { Button } from "@progress/kendo-react-buttons";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import {
  Field,
  FieldRenderProps,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { Loader } from "@progress/kendo-react-indicators";
import React, { useEffect, useRef, useState } from "react";
import CustomFormTextArea from "../../../../components/custom/form/CustomFormTextArea";
import CustomInput from "../../../../components/custom/form/CustomInput";
import CustomMultiSelect from "../../../../components/custom/form/CustomMultiSelect";
import { AuthorizationProfile } from "../../../../types/authorization-profile/AuthorizationProfile";
import { AxiosError } from "axios";
import useMasterData from "../../../../hooks/useMasterData";
import authorizationProfileService from "../../../../services/authorizationprofile.service";
import { Dictionary } from "../../../../types/Dictionary";
import useLocale from "../../../../hooks/useLocale";
import useAuth from "../../../../hooks/useAuth";
import { Error } from "@progress/kendo-react-labels";
import { AccessPermission } from "../../../../types/master-data";
import { UpsertAccessPermission, UpsertAuthorizationProfile } from "../../../../types/authorization-profile/UpsertAuthorizationProfile";
import useSwal from "../../../../hooks/useSwal";

interface UpsertProfileDialogProps {
  data?: AuthorizationProfile;
  onCloseDialog: () => void;
  onProfileUpsert: (
    isUpdated: boolean,
    upsertedProfile: AuthorizationProfile
  ) => void;
}

const UpsertProfileDialog: React.FC<UpsertProfileDialogProps> = ({
  data,
  onCloseDialog,
  onProfileUpsert,
}) => {
  const masterData = useMasterData();
  const formRef = useRef<any>();
  const Swal = useSwal();
  const localeCtx = useLocale();
  const auth = useAuth();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | undefined>();
  const [translationsLoading, setTranslationsLoading] =
    useState<boolean>(false);
  const [translations, setTranslations] = useState<
    Dictionary<string> | undefined
  >(
    localeCtx?.selectedLocale?.current.componentTranslations[
      "UpsertProfileDialog"
    ]
  );
  const [listenCallSelected, setListenCallSelected] = useState<boolean>(false);
  const [listenCallError, setListenCallError] = useState<string>("");

  useEffect(() => {
    if (
      !localeCtx?.selectedLocale?.current.componentTranslations[
        "UpsertProfileDialog"
      ]
    ) {
      fetchTranslations();
    }
  }, [localeCtx?.selectedLocale]);

  const fetchTranslations = async () => {
    try {
      setTranslationsLoading(true);
      const resp = await localeCtx?.setComponentTranslations(
        "UpsertProfileDialog"
      );
      setTranslations(resp);
    } catch (err) {
      console.error(err);
      setTranslations(
        localeCtx?.selectedLocale?.previous.componentTranslations[
          "UpsertProfileDialog"
        ]
      );
      localeCtx?.setPreviousAppLocale("UpsertProfileDialog");
      if (localeCtx?.localeSwitchFailed) {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Couldn't Switch Language",
        });
      }
    } finally {
      setTimeout(() => {
        setTranslationsLoading(false);
      }, 100);
    }
  };

  const fetchLabelKeyTranslation = (
    key: string,
    defaultValue: string
  ): string => {
    return translations && translations[key] ? translations[key] : defaultValue;
  };

  const listNoDataRender = (element: React.ReactElement<HTMLDivElement>) => {
    const noData = (
      <h4 style={{ fontSize: "1em" }}>{`${
        translationsLoading
          ? "No data found"
          : fetchLabelKeyTranslation("NodatafoundMsg", "No data found")
      }`}</h4>
    );
    return React.cloneElement(element, { ...element.props }, noData);
  };

  const nameValidator = (value: string) => {
    if (!value) {
      return `${
        translationsLoading
          ? "Permission Group name cannot be empty"
          : fetchLabelKeyTranslation(
              "NameEmptyText",
              "Permission Group name cannot be empty"
            )
      }`;
    }
    if (value.length > 100) {
      return `${
        translationsLoading
          ? "Permission Group name length cannot be more than 100!"
          : fetchLabelKeyTranslation(
              "NameLengthText",
              "Permission Group name length cannot be more than 100!"
            )
      }`;
    }
    return "";
  };

  const descriptionValidator = (value: string) => {
    if (value.length > 1000) {
      return `${
        translationsLoading
          ? "Description length cannot be more than 1000!"
          : fetchLabelKeyTranslation(
              "DescriptionLengthText",
              "Description length cannot be more than 1000!"
            )
      }`;
    }
    return "";
  };

  const multiSelectValidator = (value: string[]) => {
    if (!value || value.length === 0) {
      return `${
        translationsLoading
          ? "Permission Group permissions cannot be empty!"
          : fetchLabelKeyTranslation(
              "PermissionMsg",
              "Permission Group permissions cannot be empty!"
            )
      }`;
    }
    return "";
  };

  const submitHandler = () => {
    setError(undefined);
    const form = formRef.current as Form;
    const profile: UpsertAuthorizationProfile = {
      name: form.values.name,
      description: form.values.description,
      permissions: form.values.permissions.map((p : AccessPermission) => {
        const upsertPermission : UpsertAccessPermission = {
         Permission: p.name
        }
        return upsertPermission
     }),
    };
    if (form.isFormValid(form.errors)) {
      setLoading(true);
      authorizationProfileService
        .upsertAuthorizationProfile(profile, data?.id ?? 0)
        .then((upsertedProfile) => {
          Swal.fire({
            icon: "success",
            title: `${`${
              translationsLoading
                ? "Permission Group"
                : fetchLabelKeyTranslation(
                    "PermissionGroupTitle",
                    "Permission Group"
                  )
            }`} ${
              data
                ? `${
                    translationsLoading
                      ? "Updated"
                      : fetchLabelKeyTranslation("UpdatedMsg", "Updated")
                  }`
                : `${
                    translationsLoading
                      ? "Added"
                      : fetchLabelKeyTranslation("AddedMsg", "Added")
                  }`
            }`,
          });
          onProfileUpsert(data ? true : false, upsertedProfile);
          onCloseDialog();
        })
        .catch((err) => {
          console.log(err.response.data.error);
          if (err.response.data.error === "Name already exists.") {
            setError(
              `${
                translationsLoading
                  ? "Permission Group with same name already exists."
                  : fetchLabelKeyTranslation(
                      "InvalidNameMsg",
                      "Permission Group with same name already exists."
                    )
              }`
            );
          } else if (err instanceof Error) {
            setError(
              `${
                translationsLoading
                  ? "Something went wrong! Please try again"
                  : fetchLabelKeyTranslation(
                      "SomethingError",
                      "Something went wrong! Please try again"
                    )
              }`
            );
          }
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setError(
        `${
          translationsLoading
            ? "Please check all the fields!"
            : fetchLabelKeyTranslation(
                "CheckError",
                "Please check all the fields!"
              )
        }`
      );
    }
  };

  const permissionMultiselectHandler = (e: FieldRenderProps) => {
    setListenCallError("");
    const findElement = e.value.find(
      (v: AccessPermission) => v.name === "Sharing" || v.name === "Evaluation"
    );
    const findListenCall = e.value.find(
      (v: AccessPermission) => v.name === "Listen Call"
    );

    if (findElement && !listenCallSelected) {
      !findListenCall &&
        e.value.push({
          id: 1,
          isPlaybackPermission: true,
          name: "Listen Call",
        });
    }

    if (listenCallSelected && !findListenCall) {
      const findSharingIndex = e.value.findIndex(
        (v: AccessPermission) => v.name === "Sharing"
      );
      if (findSharingIndex !== -1) e.value.splice(findSharingIndex, 1);
      const findEvaluationIndex = e.value.findIndex(
        (v: AccessPermission) => v.name === "Evaluation"
      );
      if (findEvaluationIndex !== -1) e.value.splice(findEvaluationIndex, 1);
      setListenCallError(
        "Listen Call is required for Evaluation and Sharing Permissions"
      );
      setListenCallSelected(false);
    }

    if (
      !listenCallSelected &&
      e.value[e.value.length - 1].name === "Listen Call"
    ) {
      setListenCallSelected(true);
    }
  };

  return (
    <Form
      ref={formRef}
      initialValues={{
        name: data?.name ?? "",
        description: data?.description ?? "",
        permissions: data?.permissions ? data.permissions : [],
      }}
      render={(formRenderProps: FormRenderProps) => (
        <FormElement style={{ maxWidth: 650 }}>
          <Dialog
            title={
              data
                ? `${
                    translationsLoading
                      ? "Edit Permission Group"
                      : fetchLabelKeyTranslation(
                          "EditPermissionGroupTitle",
                          "Edit Permission Group"
                        )
                  }`
                : `${
                    translationsLoading
                      ? "Add Permission Group"
                      : fetchLabelKeyTranslation(
                          "AddPermissionGroupTitle",
                          "Add Permission Group"
                        )
                  }`
            }
            onClose={onCloseDialog}
          >
            <div className="formAdd" style={{ marginBottom: "0px" }}>
              {error && <span className="tx-red">{error}</span>}
              <div className="formBoxRow p-t-5 p-b-5">
                <div className="formBoxLabel fs-14">{`${
                  translationsLoading
                    ? "Name"
                    : fetchLabelKeyTranslation("NameTitle", "Name")
                }`}</div>
                <div className="formBoxAction">
                  <div className="formInput">
                    <Field
                      id="name"
                      name="name"
                      style={{ height: "32px" }}
                      value={formRenderProps.valueGetter("name")}
                      placeholder={`${
                        translationsLoading
                          ? "Enter Name…"
                          : fetchLabelKeyTranslation("NamePC", "Enter Name…")
                      }`}
                      disabled={data !== undefined}
                      component={CustomInput}
                      validator={nameValidator}
                    />
                  </div>
                </div>
              </div>
              <div className="formBoxRow p-t-5 p-b-5">
                <div className="formBoxLabel fs-14">{`${
                  translationsLoading
                    ? "Description"
                    : fetchLabelKeyTranslation(
                        "DescriptionTitle",
                        "Description"
                      )
                }`}</div>
                <div className="formBoxAction">
                  <div className="formInput">
                    <Field
                      id={"description"}
                      name={"description"}
                      style={{
                        minWidth: "250px",
                        height: "auto",
                        minHeight: "70px",
                      }}
                      max={1000}
                      autoSize={true}
                      rows={3}
                      showTextLimitHint={false}
                      placeholder={`${
                        translationsLoading
                          ? "Enter description…"
                          : fetchLabelKeyTranslation(
                              "DescriptionPC",
                              "Enter description…"
                            )
                      }`}
                      value={formRenderProps.valueGetter("description")}
                      component={CustomFormTextArea}
                      validator={descriptionValidator}
                    />
                  </div>
                </div>
              </div>
              <div className="formBoxRow p-t-5 p-b-15">
                <div className="formBoxLabel fs-14">{`${
                  translationsLoading
                    ? "Permissions"
                    : fetchLabelKeyTranslation(
                        "PermissionsTitle",
                        "Permissions"
                      )
                }`}</div>
                <div className="formBoxAction">
                  <div className="formInput">
                    {!masterData?.data ? (
                      <Loader size="small" type="infinite-spinner" />
                    ) : (
                      <Field
                        id="permissions"
                        name="permissions"
                        placeholder={`${
                          translationsLoading
                            ? "Select Permissions…"
                            : fetchLabelKeyTranslation(
                                "PermissionsPC",
                                "Select Permissions…"
                              )
                        }`}
                        onChange={permissionMultiselectHandler}
                        listNoDataRender={listNoDataRender}
                        data={masterData?.data?.accessPermissions.filter(
                          (ele) =>
                            ele.id !== 8 || auth?.checkTopicAvailibility()
                        )}
                        value={formRenderProps.valueGetter("permissions")}
                        component={CustomMultiSelect}
                        validator={multiSelectValidator}
                        textField="name"
                        dataItemKey="id"
                        filtering={true}
                      />
                    )}

                    <Error>{listenCallError}</Error>
                  </div>
                </div>
              </div>
            </div>
            <DialogActionsBar>
              <Button onClick={onCloseDialog}>{`${
                translationsLoading
                  ? "Cancel"
                  : fetchLabelKeyTranslation("CancelButton", "Cancel")
              }`}</Button>
              <Button
                className={`btn bg-primary text-white ${
                  !formRenderProps.valid ? "disabledBtn" : ""
                }`}
                onClick={submitHandler}
                disabled={!formRenderProps.valid}
              >
                {loading ? (
                  <Loader
                    themeColor={"primary"}
                    size={"small"}
                    type={"infinite-spinner"}
                  />
                ) : (
                  `${
                    data
                      ? `${
                          translationsLoading
                            ? "Update"
                            : fetchLabelKeyTranslation("UpdateButton", "Update")
                        }`
                      : `${
                          translationsLoading
                            ? "Add"
                            : fetchLabelKeyTranslation("AddButton", "Add")
                        }`
                  }`
                )}
              </Button>
            </DialogActionsBar>
          </Dialog>
        </FormElement>
      )}
    />
  );
};

export default UpsertProfileDialog;
