import React, { useEffect, useRef, useState } from "react";
import { Button } from "@progress/kendo-react-buttons";
import WaveSurfer from "wavesurfer.js";
import RegionsPlugin, {
  Region,
  RegionParams,
} from "wavesurfer.js/src/plugin/regions";
import { formatSecondsToTime } from "../../utils/dateTimeUtils";
import { Loader } from "@progress/kendo-react-indicators";
import { Link, useNavigate } from "react-router-dom";
import { Switch, SwitchChangeEvent } from "@progress/kendo-react-inputs";
import { CommentRegionSelection } from "../../pages/call-details/CallDetails";
import { Tooltip } from "@progress/kendo-react-tooltip";
import {
  defaultRegionColor,
  highlightedRegionColor,
  playedRegionColor,
} from "../../data";
import useAuth from "../../hooks/useAuth";
import { parseJSON } from "../../utils/customJsonParser";
import { API_BASE_URL } from "../../constants";
import { Align, Popup } from "@progress/kendo-react-popup";
import { Comment } from "../../types/recording";
import { RecordingTrackSegment } from "../../types/player-track";

const buildDefaultCommentRegionParams = (
  start: number,
  end: number,
  commentId: number
): RegionParams => {
  const regionParams: RegionParams = {
    start: start,
    end: end,
    color: defaultRegionColor,
    data: {
      commentId: commentId,
      highlighted: false,
      entireRecording: false,
    },
    resize: false,
    drag: false,
  };
  return regionParams;
};

const buildCommentRegionPlayingParams = (
  start: number,
  end: number,
  commentId: number,
  isEntireRecording: boolean = false
): RegionParams => {
  const regionParams: RegionParams = {
    start: start,
    end: end,
    color: playedRegionColor,
    data: {
      commentId: commentId,
      highlighted: true,
      entireRecording: isEntireRecording,
      playing: true,
    },
    drag: false,
    resize: false,
  };
  return regionParams;
};
interface RecordingSpeedData {
  fieldId: number;
  fieldName: string;
  selected: boolean;
}

interface ICallPlayerProps {
  enableRegions: boolean;
  recordingId: number;
  setRegionSelection?: React.Dispatch<
    React.SetStateAction<{ start: number; end: number }>
  >;
  setRecordingDuration?: React.Dispatch<React.SetStateAction<number>>;
  durationInSeconds?: number;
  setPlaybackProgress?: React.Dispatch<React.SetStateAction<number>>;
  highlightCommentRegion?: Comment;
  playCommentRegion?: Comment;
  trackSpanTime?: number;
  wavformContainerId?: string;
  setAudioLoaded?: React.Dispatch<React.SetStateAction<boolean>>;
  commentRegions?: CommentRegionSelection[];
  onUserSeekOutOfCommentRegion?: () => void;
  setAudioLoadedErrored?: React.Dispatch<React.SetStateAction<boolean>>;
  recordingTrackLineClicked?: boolean;
  segments?: RecordingTrackSegment[];
}

const CallPlayer: React.FC<ICallPlayerProps> = ({
  recordingId,
  enableRegions,
  setRegionSelection,
  setRecordingDuration,
  setPlaybackProgress,
  durationInSeconds,
  highlightCommentRegion,
  playCommentRegion,
  trackSpanTime,
  wavformContainerId,
  setAudioLoaded,
  commentRegions,
  onUserSeekOutOfCommentRegion,
  setAudioLoadedErrored,
  recordingTrackLineClicked,
  segments,
}) => {
  const speedData: RecordingSpeedData[] = [
    { fieldId: 0.25, fieldName: "0.25x", selected: false },
    { fieldId: 0.5, fieldName: "0.50x", selected: false },
    { fieldId: 0.75, fieldName: "0.75x", selected: false },
    { fieldId: 1, fieldName: "1.0x", selected: true },
    { fieldId: 1.25, fieldName: "1.25x", selected: false },
    { fieldId: 1.5, fieldName: "1.50x", selected: false },
    { fieldId: 1.75, fieldName: "1.75x", selected: false },
    { fieldId: 2, fieldName: "2x", selected: false },
  ];
  const auth = useAuth();
  const navigate = useNavigate();
  const [playing, setPlaying] = useState(false);
  const [volumeMute, setVolumeMute] = useState(false);
  const waveform = useRef<WaveSurfer | null>(null);
  const anchor = useRef<HTMLButtonElement | null>(null);
  const [durationSeconds, setDurationSeconds] = useState(0);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | undefined>();
  const [totalDuration, setTotalDuration] = useState(durationInSeconds ?? 0);
  const [showCommentRegions, setShowCommentRegions] = useState<boolean>(true);
  const [autoplayCall, setAutoPlayCall] = useState<boolean>(true);
  const [commentRegionPlaying, setCommentRegionPlaying] =
    useState<boolean>(false);
  const [show, setShow] = useState<boolean>(false);
  const [popupAlign, setPopupAlign] = useState<Align>({
    horizontal: "center",
    vertical: "top",
  });
  const [speed, setSpeed] = useState<RecordingSpeedData[]>(speedData);
  const [skipSilence, setSkipSilence] = useState<boolean>(false);

  useEffect(() => {
    setShow(false);
  }, []);

  useEffect(() => {
    if (show) {
      const body = document.getElementById("root");
      const eventHandler = () => {
        setShow(false);
      };
      if (body != null) {
        body.addEventListener("click", eventHandler);
      }
      return () => body?.removeEventListener("click", eventHandler);
    }
  }, [show]);


  const setAutoplay = () =>{
    let autoplay = localStorage.getItem("autoplayCall")?? "true";
    setAutoPlayCall(autoplay.toLocaleLowerCase() == "true");

    if(autoplay.toLocaleLowerCase() == "true")
    {
      waveform.current?.play();
      setPlaying(true);
    }
    else{
      waveform.current?.stop();
      setPlaying(false);
    }
  }

  /* Wavesurfer "error" Event Handler */
  const onWavesurferErrorHandler = (error: string) => {
    if (error !== "Error decoding audiobuffer") {
      setError(error.toString());
      if (setAudioLoadedErrored) {
        setAudioLoadedErrored(true);
      }
      waveform.current?.destroy();
    } else {
      setAutoplay();
    }
    setLoading(false);
    if (setAudioLoaded) {
      setAudioLoaded(false);
    }
  };

  /* Wavesurfer "ready" Event Handler */
  const onWavesurferReadyHandler = () => {
    waveform.current?.enableDragSelection({});
    setLoading(false);
    if (setAudioLoaded) {
      setAudioLoaded(true);
    }
    setTotalDuration(waveform.current?.getDuration() ?? 0);
    if (setRecordingDuration) {
      setRecordingDuration(waveform.current?.getDuration() ?? 0);
    }
    if (enableRegions && setRegionSelection) {
      setRegionSelection({
        start: 0,
        end: waveform.current?.getDuration() ?? 0,
      });
    }
    if (setAudioLoaded) {
      setAudioLoaded(true);
    }
    setAutoplay();
  };

  /* Wavesurfer "finish" Event Handler */
  const onWavesurferPlayingFinishHandler = () => {
    setPlaying(false);
  };

  /* Wavesurfer "audioprocess" Event Handler */
  const onWavesurferAudioProcessHandler = (progress: number) => {
    if (waveform.current) {
      if (setPlaybackProgress) {
        setPlaybackProgress(progress);
      }
      setDurationSeconds(progress);
    }
  };

  /* Wavesurfer "region-update-end" Event Handler */
  const onWavesurferRegionUpdateEndHandler = (region: Region) => {
    if (enableRegions && setRegionSelection) {
      setRegionSelection({
        start: region.start,
        end: region.end,
      });
    }
    region.update({
      start: region.start,
      end: region.end,
      data: {
        userCreated: true,
      },
    });
    region.playLoop();
    setPlaying(true);
    if (waveform.current && waveform.current.regions) {
      const regionIds = Object.keys(waveform.current?.regions.list);
      let userCreatedRegion: Region | undefined;
      regionIds.forEach((id) => {
        const r = waveform.current?.regions.list[id];
        if (
          r &&
          r.data.userCreated === true &&
          r.start !== region.start &&
          r.end !== region.end
        ) {
          userCreatedRegion = waveform.current?.regions.list[id];
        }
      });
      if (userCreatedRegion) {
        userCreatedRegion.remove();
      }
    }
    if (onUserSeekOutOfCommentRegion && enableRegions) {
      onUserSeekOutOfCommentRegion();
    }
  };

  /* Wavesurfer "seek" Event Handler */
  const onWavesurferSeekHandler = (progress: number) => {
    let currentSeekTime = 0;
    if (waveform.current) {
      currentSeekTime = progress * waveform.current.getDuration();
      setDurationSeconds(currentSeekTime);
    }
    // Remove user created region if current progress is beyond its boundaries.
    if (waveform.current && waveform.current.regions) {
      const regionIds = Object.keys(waveform.current?.regions.list);
      let userCreatedRegion: Region | undefined;
      regionIds.forEach((id) => {
        if (waveform.current?.regions.list[id].data.userCreated === true) {
          userCreatedRegion = waveform.current?.regions.list[id];
        }
      });
      if (userCreatedRegion) {
        if (
          !(
            currentSeekTime > userCreatedRegion.start &&
            currentSeekTime < userCreatedRegion.end
          )
        ) {
          userCreatedRegion.remove();
          if (enableRegions && setRegionSelection) {
            setRegionSelection({
              start: 0,
              end: waveform.current?.getDuration() ?? 0,
            });
          }
        }
      }
    }
    // Stop playing comment region if user seeks out of comment region.
    if (onUserSeekOutOfCommentRegion && waveform.current && enableRegions) {
      const currentRegion = waveform.current.regions.getCurrentRegion();
      if (
        !(
          currentRegion &&
          currentSeekTime >= currentRegion.start &&
          currentSeekTime <= currentRegion.end
        )
      ) {
        onUserSeekOutOfCommentRegion();
      }
    }
  };

  /* Initialize Wavesurfer and add all the player events */
  useEffect(() => {
    const shareToken = parseJSON(sessionStorage.getItem("ShareToken"));
    if (!auth?.accessToken && !shareToken) {
      navigate("/", { replace: true });
    }

    if (!waveform.current) {
      waveform.current = WaveSurfer.create({
        barWidth: 3,
        barRadius: 3,
        barGap: 2,
        barMinHeight: 1,
        cursorWidth: 1,
        container: `#waveform${wavformContainerId ?? ""}`,
        backend: "WebAudio",
        height: 60,
        progressColor: "#6b6db2",
        responsive: true,
        waveColor: "#C4C4C4",
        cursorColor: "transparent",
        xhr: {
          requestHeaders: [
            {
              key: "Authorization",
              value: `Bearer ${shareToken ? shareToken : auth?.accessToken}`,
            },
          ],
        },
      });

      if (enableRegions) {
        waveform.current.addPlugin(
          RegionsPlugin.create({
            dragSelection: false,
          })
        );
      }

      if (API_BASE_URL) {
        waveform.current.load(`${API_BASE_URL}/recording/${recordingId}/play`);
      }

      waveform.current.on("error", onWavesurferErrorHandler);

      waveform.current.on("finish", onWavesurferPlayingFinishHandler);

      waveform.current.on("ready", onWavesurferReadyHandler);

      waveform.current.on("audioprocess", onWavesurferAudioProcessHandler);

      waveform.current.on(
        "region-update-end",
        onWavesurferRegionUpdateEndHandler
      );

      waveform.current.on("seek", onWavesurferSeekHandler);
    }

    return () => {
      waveform?.current?.cancelAjax();
      waveform?.current?.destroy();
      setPlaying(false);
    };
  }, [recordingId, enableRegions]);

  /* Recording Track clicked by the user */
  useEffect(() => {
    if (trackSpanTime) {
      waveform.current?.play(trackSpanTime);
      if (!playing) {
        setPlaying(true);
      }
    }
  }, [trackSpanTime, recordingTrackLineClicked]);

  /* Update the State - Regions List */
  const updateRegionsList = () => {
    if (!commentRegions) {
      return;
    }
    //const updatedRegions: Region[] = [];
    if (
      !error &&
      commentRegions.length > 0 &&
      waveform.current &&
      waveform.current.regions
    ) {
      commentRegions.forEach((c) => {
        const region = waveform.current?.regions.add(
          buildDefaultCommentRegionParams(c.start, c.end, c.commentId)
        );
      });
    }
  };

  // Stop highlighting all regions.
  const removeHighlightInAllRegions = () => {
    const wavesurferRegions = waveform.current?.regions.list;
    for (const rId in wavesurferRegions) {
      const r = wavesurferRegions[rId];
      if (r.data.entireRecording === true && r.data.playing === false) {
        r.remove();
      } else if (r.data.playing === false) {
        r.update(
          buildDefaultCommentRegionParams(
            r.start,
            r.end,
            r.data.commentId as number
          )
        );
      }
    }
  };

  const stopCommentRegionPlay = () => {
    waveform.current?.pause();
    setPlaying(false);
    setCommentRegionPlaying(false);
    const wavesurferRegions = waveform.current?.regions.list;
    // if regions are present on the player.
    if (showCommentRegions) {
      // update regions.
      for (const rId in wavesurferRegions) {
        const r = wavesurferRegions[rId];
        // if region is for the entire recording and it doesn't needs to be highlighted.
        if (
          r.data.entireRecording === true &&
          highlightCommentRegion &&
          highlightCommentRegion.id !== r.data.commentId
        ) {
          // then, remove the region.
          r.remove();
        }
        // else, simply update the regions.
        else {
          r.update({
            start: r.start,
            end: r.end,
            color:
              highlightCommentRegion &&
              highlightCommentRegion.id === r.data.commentId
                ? highlightedRegionColor
                : defaultRegionColor,
            data: {
              commentId: r.data.commentId,
              highlighted:
                highlightCommentRegion &&
                highlightCommentRegion.id === r.data.commentId
                  ? true
                  : false,
              playing: false,
              entireRecording: r.data.entireRecording,
            },
            drag: false,
            resize: false,
          });
        }
      }
    }
    // regions are not present on the player.
    else {
      // remove all regions from the player.
      if (waveform.current?.regions) {
        const wavesurferRegions = waveform.current?.regions.list;
        for (const rId in wavesurferRegions) {
          wavesurferRegions[rId].remove();
        }
      }
    }
  };

  /* Recording Comment regions */
  useEffect(() => {
    // clear all user created regions
    if (waveform.current && waveform.current.regions) {
      waveform.current.regions.clear();
    }
    // regions is enabled for player and commentRegions are
    // and showCommentRegion toggle is turned on.
    if (
      waveform.current &&
      enableRegions &&
      commentRegions &&
      showCommentRegions
    ) {
      // update the player regions to the latest commentRegions.
      updateRegionsList();
    }
  }, [enableRegions, commentRegions]);

  /* Highlight comment region */
  useEffect(() => {
    if (enableRegions) {
      // comment region should be highlighted
      if (highlightCommentRegion) {
        // remove highlight from all the regions.
        removeHighlightInAllRegions();
        // comment regions present on the player and should be highlighted
        if (showCommentRegions) {
          // comment to highlight is not for the entire recording
          if (
            highlightCommentRegion.segmentStart &&
            highlightCommentRegion.segmentEnd
          ) {
            if (waveform.current?.regions) {
              const wavesurferRegions = waveform.current?.regions.list;
              let regionToHighlight: Region | undefined = undefined;
              for (const rId in wavesurferRegions) {
                const r = wavesurferRegions[rId];
                if (
                  r.data.commentId === highlightCommentRegion.id &&
                  r.data.highlighted === false
                ) {
                  regionToHighlight = r;
                }
              }
              // region found
              if (regionToHighlight) {
                // update and highlight the comment region
                regionToHighlight.update({
                  start: regionToHighlight.start,
                  end: regionToHighlight.end,
                  color: highlightedRegionColor,
                  data: {
                    commentId: regionToHighlight.data.commentId,
                    highlighted: true,
                    entireRecording: false,
                    playing: false,
                  },
                  resize: false,
                  drag: false,
                });
              }
            }
          }
          // comment to highlight is for the entire recording
          else {
            // Look for any comment region already present which is for the entire recording
            // and highlighted and playing.
            const wavesurferRegions = waveform.current?.regions.list;
            let entireRecordingRegionPlaying: Region | undefined = undefined;
            for (const rId in wavesurferRegions) {
              const r = wavesurferRegions[rId];
              if (
                r.data.commentId === highlightCommentRegion.id &&
                r.data.entireRecording === true &&
                r.data.highlighted === true &&
                r.data.playing === true
              ) {
                entireRecordingRegionPlaying = r;
              }
            }

            // if such a region is not present.
            if (!entireRecordingRegionPlaying) {
              // create a new highlighted region for the entire recording
              waveform.current?.regions.add({
                start: 0,
                end: totalDuration,
                color: highlightedRegionColor,
                data: {
                  commentId: highlightCommentRegion.id,
                  highlighted: true,
                  entireRecording: true,
                  playing: false,
                },
                drag: false,
                resize: false,
              });
            }
            // if such a region is present then,
            // Comment region for entire recording is playing and should not be highlighted
            else {
            }
          }
        }
        // no regions present on the player and should not be highlighted
        else {
        }
      }
      // comment region should not be highlighted
      else {
        removeHighlightInAllRegions();
      }
    }
  }, [enableRegions, highlightCommentRegion]);

  /* Play comment region */
  useEffect(() => {
    if (enableRegions) {
      // comment region should be played
      if (playCommentRegion) {
        // stop any comment region already playing
        stopCommentRegionPlay();

        // comment regions present on the player
        if (showCommentRegions) {
          const wavesurferRegions = waveform.current?.regions.list;
          let regionToPlay: Region | undefined = undefined;
          for (const rId in wavesurferRegions) {
            const r = wavesurferRegions[rId];
            if (r.data.commentId === playCommentRegion.id) {
              regionToPlay = r;
            }
          }
          // region found
          if (regionToPlay) {
            regionToPlay.update(
              buildCommentRegionPlayingParams(
                regionToPlay.start,
                regionToPlay.end,
                playCommentRegion.id,
                regionToPlay.data.entireRecording === true ? true : false
              )
            );
            regionToPlay.playLoop();
            setPlaying(true);
            setCommentRegionPlaying(true);
          }
        }
        // comment regions not present on the player
        else {
          // add a new region and starts playing it.
          // adjust start and end based on new region is for the entire recording or not.
          const start = playCommentRegion.segmentStart ?? 0;
          const end = playCommentRegion.segmentEnd ?? totalDuration;
          const isEntireRecording = playCommentRegion.segmentStart
            ? false
            : true;
          const regionToPlay = waveform.current?.regions.add(
            buildCommentRegionPlayingParams(
              start,
              end,
              playCommentRegion.id,
              isEntireRecording
            )
          );
          // region is created.
          if (regionToPlay) {
            regionToPlay.playLoop();
            setPlaying(true);
            setCommentRegionPlaying(true);
          }
        }
      }
      // comment region play should be stopped
      else {
        stopCommentRegionPlay();
      }
    }
  }, [enableRegions, playCommentRegion]);

  const playAudio = () => {
    setPlaying((prev) => !prev);
    waveform.current?.playPause();
    if (!playing) {
      localStorage.setItem("callPlayerState", wavformContainerId ?? "");
    }
  };

  useEffect(() => {
    if (wavformContainerId !== localStorage.getItem("callPlayerState")) {
      setPlaying((prev) => !prev);
      waveform.current?.playPause();
    }
  }, [localStorage.getItem("callPlayerState")]);

  const volumeMuteHandler = () => {
    setVolumeMute(!volumeMute);
    waveform.current?.toggleMute();
  };

  const skipForwardHandler = () => {
    waveform.current?.skipForward(10);
  };

  const skipBackwardHandler = () => {
    waveform.current?.skipBackward(10);
  };

  const onShowCommentRegionsSwitchChange = (e: SwitchChangeEvent) => {
    setShowCommentRegions(e.value);
    if (!e.value) {
      const wavesurferRegions = waveform.current?.regions.list;
      for (const rId in wavesurferRegions) {
        const r = wavesurferRegions[rId];
        r.remove();
      }
      // regions.forEach((r) => {
      //   r.remove();
      // });
      //setRegions([]);
    } else {
      if (commentRegions) {
        updateRegionsList();
      }
    }
  };

  const onAutoplayCallSwitchChange = (e: SwitchChangeEvent) => {
    setAutoPlayCall(e.value);
    localStorage.setItem("autoplayCall", String(e.value));
  }

  const onClick = () => {
    setShow(!show);
  };

  const speedHandler = (sData: any) => {
    waveform.current?.playPause();
    const newSpeedData: RecordingSpeedData[] = speed.map((s) => {
      if (s.fieldId === sData.fieldId) {
        s.selected = true;
      } else {
        s.selected = false;
      }
      return s;
    });
    waveform.current?.setPlaybackRate(sData.fieldId);
    setSpeed(newSpeedData);
    setShow(!show);
    waveform.current?.playPause();
  };

  useEffect(() => {
    if (segments && skipSilence) {
      const findSegment: RecordingTrackSegment | undefined = segments.find(
        (segment: RecordingTrackSegment) =>
          segment.cssLabel === "silence-both" &&
          segment.start <= durationSeconds &&
          segment.end >= durationSeconds
      );
      if (findSegment) {
        if (findSegment?.end === durationInSeconds) {
          waveform.current?.skip(findSegment.end);
          waveform.current?.pause();
          setPlaying(false);
        } else {
          waveform.current?.skip(findSegment.end - findSegment.start);
        }
      }
    }
  }, [durationSeconds, skipSilence]);

  return (
    <div className="wavePlayer w-100 float-left">
      <div className="WaveFormContainer">
        {!loading && !error && (
          <div style={{ display: "flex" }}>
            <div className="playerBtnGroup">
              <div className="playerBtn">
                <Tooltip
                  anchorElement={"target"}
                  position={"top"}
                  parentTitle={true}
                  tooltipStyle={{ fontSize: "12px" }}
                >
                  <Button
                    title="Back"
                    className="PlayerButton btnCounterclockwise fs-18"
                    onClick={skipBackwardHandler}
                  >
                    <div className="arrowClockwiseBtn">
                      <i className="bi bi-arrow-counterclockwise fs-25"></i>
                      <span className="arrowClockwiseVal fs-10">10</span>
                    </div>
                  </Button>
                </Tooltip>
              </div>
              <div className="playerBtn">
                <Tooltip
                  anchorElement={"target"}
                  position={"top"}
                  parentTitle={true}
                  tooltipStyle={{ fontSize: "12px" }}
                >
                  <Button
                    title={playing ? "Pause" : "Play"}
                    className="PlayerButton"
                    onClick={playAudio}
                  >
                    {playing ? (
                      <i className="bi bi-pause"></i>
                    ) : (
                      <i className="bi bi-play"></i>
                    )}
                  </Button>
                </Tooltip>
              </div>
              <div className="playerBtn">
                <Tooltip
                  anchorElement={"target"}
                  position={"top"}
                  parentTitle={true}
                  tooltipStyle={{ fontSize: "12px" }}
                >
                  <Button
                    title="Forward"
                    className="PlayerButton btnClockwise fs-18"
                    onClick={skipForwardHandler}
                  >
                    <div className="arrowClockwiseBtn">
                      <i className="bi bi-arrow-clockwise fs-25"></i>
                      <span className="arrowClockwiseVal fs-10">10</span>
                    </div>
                  </Button>
                </Tooltip>
              </div>
              <div className="playerBtn">
                <Tooltip
                  anchorElement={"target"}
                  position={"top"}
                  parentTitle={true}
                  tooltipStyle={{ fontSize: "12px" }}
                >
                  <Button
                    title={volumeMute ? "Unmute" : "Mute"}
                    className="PlayerButton"
                    onClick={volumeMuteHandler}
                  >
                    {volumeMute ? (
                      <i className="bi bi-volume-mute"></i>
                    ) : (
                      <i className="bi bi-volume-down"></i>
                    )}
                  </Button>
                </Tooltip>
              </div>
              <div className="playerBtn">
                <Tooltip
                  anchorElement={"target"}
                  position={"top"}
                  parentTitle={true}
                  tooltipStyle={{ fontSize: "12px" }}
                >
                  <Button
                    onClick={() => setSkipSilence(!skipSilence)}
                    title={skipSilence ? "Do not skip silence" : "Skip silence"}
                    className="PlayerButton"
                  >
                    <i
                      className={`bi ${
                        skipSilence ? "bi-person-fill" : "bi-person-x-fill"
                      } fs-20`}
                    ></i>
                  </Button>
                </Tooltip>
              </div>

              <div className="playerBtn">
                <Button
                  onClick={onClick}
                  title="Speed"
                  className="PlayerButton p-r-10 p-l-10 fs-14"
                >
                  <Tooltip
                    anchorElement={"target"}
                    position={"top"}
                    parentTitle={true}
                    tooltipStyle={{ fontSize: "12px" }}
                  >
                    <span className="speedValLabel" ref={anchor}>
                      {speed.find((s) => s.selected === true)?.fieldName}
                    </span>
                  </Tooltip>
                </Button>

                <Popup
                  anchor={anchor.current}
                  popupAlign={popupAlign}
                  show={show}
                  popupClass={"popup-content"}
                  style={{ marginTop: "20px" }}
                >
                  <ul className="dropMenu min-w-200 droplist-ul">
                    {speed.map((s) => {
                      return (
                        <li
                          onClick={() => speedHandler(s)}
                          key={s.fieldId}
                          className="droplist-li"
                        >
                          <div className="droplist-a">
                            <div className="w-100 d-flex align-items-center justify-content-between">
                              <span>{s.fieldName}</span>
                              {s.selected && (
                                <i className="bi bi-check-lg fs-14"></i>
                              )}
                            </div>
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                </Popup>
              </div>
              <div className="playerBtn">
                <Button
                  className="PlayerButton p-r-10 p-l-10 fs-14"
                  style={{ cursor: "default" }}
                >
                  <span className="timeVal-start">
                    {formatSecondsToTime(durationSeconds)} /
                  </span>
                  <span className="timeVal-start">
                    {formatSecondsToTime(totalDuration)}
                  </span>
                </Button>
              </div>
            </div>
            {enableRegions && (
              <Tooltip
                anchorElement={"target"}
                position={"left"}
                parentTitle={true}
                tooltipStyle={{ fontSize: "12px" }}
              >
                <div className="extraConrollBtn">
                  <div
                    className="switchButton float-right m-t-5"
                    title={`${
                      showCommentRegions
                        ? "Disable Comment Regions"
                        : "Enable Comment Regions"
                    }`}
                  >
                    <Switch
                      disabled={commentRegionPlaying}
                      checked={showCommentRegions}
                      offLabel=""
                      onLabel=""
                      onChange={onShowCommentRegionsSwitchChange}
                    />
                  </div>
                </div>
              </Tooltip>
            )}
            <Tooltip
                anchorElement={"target"}
                position={"left"}
                parentTitle={true}
                tooltipStyle={{ fontSize: "12px" }}
              >
                <div className="extraConrollBtn playSwitchBt" style={{paddingLeft:"5px"}}>
                  <div
                    className="switchButton float-right m-t-5"
                    title={`${
                      autoplayCall
                        ? "Dont Autoplay Call"
                        :"Autoplay Call"    
                    }`}
                  >
                    <Switch
                      checked={autoplayCall}
                      offLabel=""
                      onLabel=""
                      onChange={onAutoplayCallSwitchChange}
                    />
                  </div>
                </div>
              </Tooltip>
          </div>
        )}
        <div className="Wave" id={`waveform${wavformContainerId ?? ""}`}>
          {loading && (
            <div className="d-flex align-items-center justify-content-center">
              <Loader type={"infinite-spinner"} />
            </div>
          )}
          {error && (
            <div className="d-flex align-items-center p-t-30 p-b-40 justify-content-center">
              <span className="text-muted">
                <i className="bi bi-exclamation-triangle-fill tx-amber"></i>{" "}
                {"Can't play recording right now! Please try again..."}
              </span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CallPlayer;
