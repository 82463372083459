import * as React from "react";
import * as ReactDOM from "react-dom";
import { Button } from "@progress/kendo-react-buttons";
import {
  AutoComplete,
  ComboBox,
  MultiColumnComboBox,
  DropDownList,
  MultiSelect,
  DropDownTree,
} from "@progress/kendo-react-dropdowns";
import { useEffect, useState } from "react";
import { Tooltip } from "@progress/kendo-react-tooltip";
import {
  Input,
  TextArea,
  MaskedTextBox,
  NumericTextBox,
  Slider,
  RangeSlider,
  Rating,
  ColorGradient,
  ColorPalette,
  ColorPicker,
  Switch,
  Checkbox,
  RadioButton,
  RadioGroup,
} from "@progress/kendo-react-inputs";

const langAccount = ["English UK", "English US", "French FR", "Dutch NL"];

interface SystemSettingAccountsProps {
  shared?: boolean;
}

const SystemSettingAccounts: React.FC<SystemSettingAccountsProps> = ({
  shared,
}) => {
  return (
    <div className="m-b-10">
      <div className="row">
        <div className="col-md-12">
          <div className="trk-container">
            <div className="trk-inr p-b-30">
              <div className="trk-t text-black-14 border-bottom-solid border-w-1 border-black-1">
                <span className="text-primary">Accounts Details</span>
                <span className="float-right">
                  <Button
                    className="btn bg-transparent text-black-12 border-primary text-primary m-r-5 p-r-7 p-l-7"
                    style={{ height: "26px" }}
                  >
                    <span className="p-r-5">Edit</span>
                    <i className="bi bi-pencil"></i>
                  </Button>
                </span>
              </div>
              <div className="trk-row border-bottom-solid border-w-1 border-black-1">
                <div className="trkCol">
                  <div className="trkCol-h font-weight-semi">Company Name</div>
                  <div className="trkCol-dot">:</div>
                  <div className="trkCol-p" contentEditable>
                    Microsoft Corporation Limited
                  </div>
                </div>
              </div>
              <div className="trk-row border-bottom-solid border-w-1 border-black-1">
                <div className="trkCol">
                  <div className="trkCol-h font-weight-semi">Address</div>
                  <div className="trkCol-dot">:</div>
                  <div className="trkCol-p" contentEditable>
                    A-16, Sector 16, Noida, Uttar Pradesh, India Pin Code-201301
                  </div>
                </div>
              </div>
              <div className="trk-row border-bottom-solid border-w-1 border-black-1">
                <div className="trkCol">
                  <div className="trkCol-h font-weight-semi">Country</div>
                  <div className="trkCol-dot">:</div>
                  <div className="trkCol-p" contentEditable>
                    <span>India</span>
                  </div>
                </div>
              </div>
              <div className="trk-row border-bottom-solid border-w-1 border-black-1">
                <div className="trkCol">
                  <div className="trkCol-h font-weight-semi">Time Zone</div>
                  <div className="trkCol-dot">:</div>
                  <div className="trkCol-p" contentEditable>
                    <span>
                      Time zone in Ghaziabad, Uttar Pradesh (GMT+5:30)
                      Wednesday, 22 March 2023, 5:43 pm
                    </span>
                  </div>
                </div>
              </div>
              <div className="trk-row border-bottom-solid border-w-1 border-black-1">
                <div className="trkCol">
                  <div className="trkCol-h font-weight-semi d-flex align-items-center">
                    Language
                  </div>
                  <div className="trkCol-dot d-flex align-items-center">:</div>
                  <div className="trkCol-p">
                    {/* <span>English UK</span> */}
                    <span>
                      <ComboBox
                        style={{ width: "300px", height: "30px" }}
                        data={langAccount}
                        defaultValue="English UK"
                        placeholder="Select Language"
                      />
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12 text-right">
          <Button className="btn bg-black-5 m-r-5">Cancel</Button>
          <Button className="btn bg-primary text-white">Save</Button>
        </div>
      </div>
    </div>
  );
};

export default SystemSettingAccounts;
