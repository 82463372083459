import { InsertStorageLocation, StorageLocation, UpdateStorageLocation } from "../types/storage";
import { axiosWithAuth } from "../utils/customAxios";

const storageService = {
  getStorageLocations: async (): Promise<StorageLocation[]> => {
    const response = await axiosWithAuth.get(`/storage`);
    const storageLocations: StorageLocation[] = response.data;

    return storageLocations;
  },
  upsertStorageLocation: async (
    storageLocation: StorageLocation
  ): Promise<StorageLocation> => {
    if (storageLocation.id > 0) {
      const payload: UpdateStorageLocation = {
        isActive: storageLocation.isActive,
        detail: storageLocation.detail
      }
      const response = await axiosWithAuth.put(`/storage/${storageLocation.id}`, payload);
      const updatedStorageLocation: StorageLocation = response.data;

      return updatedStorageLocation;
    } else {
      const payload: InsertStorageLocation = {
        name: storageLocation.name,
        mode: storageLocation.mode,
        isActive: storageLocation.isActive,
        detail: storageLocation.detail
      }
      const response = await axiosWithAuth.post(`/storage`, payload);
      const insertedStorageLocation: StorageLocation = response.data;

      return insertedStorageLocation;
    }
  },
  activateStorageLocation: async (
    storageLocationId: number
  ): Promise<StorageLocation> => {
    const response = await axiosWithAuth.put(
      `/storage/${storageLocationId}/activate`
    );
    const updatedStorageLocation: StorageLocation = response.data;

    return updatedStorageLocation;
  },
  deactivateStorageLocations: async (): Promise<boolean> => {
    const response = await axiosWithAuth.put(`/storage/deactivate`);
    const success = response.data;

    return success;
  },
};

export default storageService;
