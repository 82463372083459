import { Button } from "@progress/kendo-react-buttons";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { Loader } from "@progress/kendo-react-indicators";
import { NumericTextBox } from "@progress/kendo-react-inputs";
import { AxiosError } from "axios";
import { useRef, useState } from "react";
import CustomInput from "../../../../components/custom/form/CustomInput";
import { StorageModes } from "../../../../enums";
import storageService from "../../../../services/storage.service";
import { StorageLocation } from "../../../../types/storage";
import useSwal from "../../../../hooks/useSwal";

interface SFTPStorageLocationProps {
  data?: StorageLocation;
  configuring: boolean;
  onUserAddStorageToggle: () => void;
  onStorageLocationUpserted: (
    upsertedStorageLocation: StorageLocation,
    updated: boolean
  ) => void;
  onActivateStorageLocation?: (storageLocationId: number) => void;
}

const SFTPStorageLocation: React.FC<SFTPStorageLocationProps> = ({
  data,
  configuring,
  onUserAddStorageToggle,
  onStorageLocationUpserted,
  onActivateStorageLocation,
}) => {
  const formRef = useRef<any>();
  const Swal = useSwal();
  const [editing, setEditing] = useState<boolean>(false);
  const [upserting, setUpserting] = useState<boolean>(false);

  const onUserEditingToggle = () => {
    setEditing((prevState) => !prevState);
    if (configuring) {
      onUserAddStorageToggle();
    }
    const form = formRef.current as Form;
    form.resetForm();
  };

  const onSubmitHandler = async (dataItem: { [name: string]: any }) => {
    try {
      setUpserting(true);
      let upsertedStorageLocation: StorageLocation = {
        id: data?.id ?? 0,
        name: dataItem.name,
        mode: StorageModes.SFTP,
        isActive: false,
        detail: {
          id: data?.detail.id ?? 0,
          keys: {
            Server: dataItem.server,
            Port: dataItem.port.toString(),
            UserName: dataItem.username,
            Password: dataItem.password,
            Directory: dataItem.directory,
          },
        },
      };
      upsertedStorageLocation = await storageService.upsertStorageLocation(
        upsertedStorageLocation
      );
      onStorageLocationUpserted(upsertedStorageLocation, data !== undefined);
      setEditing(false);
      Swal.fire({
        icon: "success",
        confirmButtonText: `OK`,
        title: `SFTP Storage Location ${data ? "Updated" : "Added"}`,
      });
    } catch (err) {
      if (err instanceof AxiosError) {
        console.error(err);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: `${err.message}`,
        });
      }
    } finally {
      setUpserting(false);
    }
  };

  const onUserActivateStorage = () => {
    if (onActivateStorageLocation && data) {
      onActivateStorageLocation(data.id);
    }
  };

  return (
    <div className="float-left w-100 p-t-5 p-l-5 p-r-5">
      <div className="visibleDataDiv float-left w-100">
        {data?.id && !editing && (
          <div className="row">
            <div className="col-md-12">
              <div className="float-left w-100 text-right p-t-5">
                {!data.isActive && (
                  <Button
                    className="btn bg-transparent text-black-12 border-primary text-primary m-r-5 p-r-7 p-l-7"
                    style={{ height: "26px" }}
                    onClick={onUserActivateStorage}
                  >
                    <span className="p-r-5">Activate Storage</span>
                    <i className="bi bi-check2-circle"></i>
                  </Button>
                )}
                <Button
                  className="btn bg-transparent text-black-12 border-primary text-primary m-r-5 p-r-7 p-l-7"
                  style={{ height: "26px" }}
                  onClick={onUserEditingToggle}
                >
                  <span className="p-r-5">Edit</span>
                  <i className="bi bi-pencil"></i>
                </Button>
              </div>
            </div>
          </div>
        )}
        <Form
          onSubmit={onSubmitHandler}
          ref={formRef}
          initialValues={{
            name: data?.name,
            server: data?.detail.keys.Server,
            port: data?.detail.keys.Port ? Number(data?.detail.keys.Port) : 0,
            username: data?.detail.keys.UserName,
            password: data?.detail.keys.Password,
            directory: data?.detail.keys.Directory,
          }}
          render={(formRenderProps: FormRenderProps) => (
            <FormElement>
              <div className="row">
                <div className="col-md-12">
                  <div className="trRowData float-left w-100 p-b-5">
                    {(configuring || editing) && (
                      <div className="trk-row">
                        <div className="trkCol d-flex">
                          <div
                            className="trkCol-h font-weight-semi"
                            style={{ minWidth: "150px" }}
                          >
                            Storage Name
                          </div>
                          <div className="trkCol-dot">:</div>
                          <div className="trkCol-p border-none">
                            {!configuring && !editing ? (
                              <span>{data?.name}</span>
                            ) : (
                              <Field
                                className="textInput"
                                id={"name"}
                                name={"name"}
                                value={formRenderProps.valueGetter("name")}
                                required={true}
                                placeholder={"Storage Name..."}
                                component={CustomInput}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                    <div className="trk-row">
                      <div className="trkCol d-flex">
                        <div
                          className="trkCol-h font-weight-semi"
                          style={{ minWidth: "150px" }}
                        >
                          Server
                        </div>
                        <div className="trkCol-dot">:</div>
                        <div className="trkCol-p border-none">
                          {!configuring && !editing ? (
                            <span>{data?.detail.keys.Server}</span>
                          ) : (
                            <Field
                              className="textInput"
                              id={"server"}
                              name={"server"}
                              value={formRenderProps.valueGetter("server")}
                              required={true}
                              placeholder={"Server..."}
                              component={CustomInput}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="trk-row">
                      <div className="trkCol d-flex">
                        <div
                          className="trkCol-h font-weight-semi"
                          style={{ minWidth: "150px" }}
                        >
                          Port
                        </div>
                        <div className="trkCol-dot">:</div>
                        <div className="trkCol-p border-none">
                          {!configuring && !editing ? (
                            <span>{data?.detail.keys.Port}</span>
                          ) : (
                            <Field
                              className="textInput"
                              id={"port"}
                              name={"port"}
                              value={formRenderProps.valueGetter("port")}
                              spinners={false}
                              min={0}
                              required={true}
                              placeholder={"Port..."}
                              component={NumericTextBox}
                              format=""
                            />
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="trk-row">
                      <div className="trkCol d-flex">
                        <div
                          className="trkCol-h font-weight-semi"
                          style={{ minWidth: "150px" }}
                        >
                          Username
                        </div>
                        <div className="trkCol-dot">:</div>
                        <div className="trkCol-p border-none">
                          {!configuring && !editing ? (
                            <span>{data?.detail.keys.UserName}</span>
                          ) : (
                            <Field
                              className="textInput"
                              id={"username"}
                              name={"username"}
                              value={formRenderProps.valueGetter("username")}
                              required={true}
                              placeholder={"Username..."}
                              component={CustomInput}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="trk-row">
                      <div className="trkCol d-flex">
                        <div
                          className="trkCol-h font-weight-semi"
                          style={{ minWidth: "150px" }}
                        >
                          Password
                        </div>
                        <div className="trkCol-dot">:</div>
                        <div className="trkCol-p border-none">
                          {!configuring && !editing ? (
                            <span>{`\u25CF`.repeat(20)}</span>
                          ) : (
                            <Field
                              className="textInput"
                              id={"password"}
                              name={"password"}
                              type={"password"}
                              value={formRenderProps.valueGetter("password")}
                              required={true}
                              placeholder={"Password..."}
                              component={CustomInput}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="trk-row">
                      <div className="trkCol d-flex">
                        <div
                          className="trkCol-h font-weight-semi"
                          style={{ minWidth: "150px" }}
                        >
                          Directory
                        </div>
                        <div className="trkCol-dot">:</div>
                        <div className="trkCol-p border-none">
                          {!configuring && !editing ? (
                            <span>{data?.detail.keys.Directory}</span>
                          ) : (
                            <Field
                              className="textInput"
                              id={"directory"}
                              name={"directory"}
                              value={formRenderProps.valueGetter("directory")}
                              required={true}
                              placeholder={"Directory..."}
                              component={CustomInput}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  {(configuring || editing) && (
                    <div className="row">
                      <div className="col-md-12 text-right p-b-5">
                        <div className="btnRightDiv">
                          <Button
                            className="btn bg-black-5 m-r-5"
                            onClick={onUserEditingToggle}
                          >
                            Cancel
                          </Button>
                          <Button
                            className={`btn bg-primary text-white ${
                              !formRenderProps.allowSubmit ||
                              !formRenderProps.modified ||
                              !formRenderProps.valid
                                ? "disabledBtn"
                                : ""
                            }`}
                            disabled={
                              !formRenderProps.allowSubmit ||
                              !formRenderProps.modified ||
                              !formRenderProps.valid
                            }
                          >
                            {upserting ? (
                              <Loader
                                size={"small"}
                                type={"infinite-spinner"}
                                themeColor={"light"}
                              />
                            ) : (
                              "Save"
                            )}
                          </Button>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </FormElement>
          )}
        />
      </div>
    </div>
  );
};

export default SFTPStorageLocation;
