import * as React from "react";
import * as ReactDOM from "react-dom";
import { Button } from "@progress/kendo-react-buttons";
import {
  AutoComplete,
  ComboBox,
  MultiColumnComboBox,
  DropDownList,
  MultiSelect,
  DropDownTree,
  MultiSelectChangeEvent,
} from "@progress/kendo-react-dropdowns";
import { useEffect, useState } from "react";
import { Tooltip } from "@progress/kendo-react-tooltip";
import {
  PanelBar,
  PanelBarItem,
  PanelBarUtils,
} from "@progress/kendo-react-layout";
import {
  Input,
  TextArea,
  MaskedTextBox,
  NumericTextBox,
  Slider,
  RangeSlider,
  Rating,
  ColorGradient,
  ColorPalette,
  ColorPicker,
  Switch,
  Checkbox,
  RadioButton,
  RadioGroup,
} from "@progress/kendo-react-inputs";

const maskCount = ["1", "2", "3", "4", "5"];

const recPolicy = ["Dialed", "Received", "Internal", "External"];

interface SystemSettingSecurityProps {
  shared?: boolean;
}

const SystemSettingSecurity: React.FC<SystemSettingSecurityProps> = ({
  shared,
}) => {
  // recPolicy
  const [value, setValue] = React.useState<string[]>([]);

  const onChange = (event: MultiSelectChangeEvent) => {
    setValue([...event.value]);
  };

  return (
    <div className="m-b-10">
      <div className="row">
        <div className="col-md-12">
          <div className="trk-container">
            <div className="trk-inr p-b-30">
              <div className="trk-t text-black-14 border-bottom-solid border-w-1 border-black-1">
                <span className="text-primary">Security & Privacy Details</span>
              </div>
              <div className="accordionData float-left w-100">
                <div className="panelbar-wrapper">
                  <PanelBar animation={true}>
                    <PanelBarItem
                      title="CLI Masking"
                      expanded={true}
                      icon="folder"
                    >
                      <div className="float-left w-100 p-t-5 p-l-5 p-r-5">
                        <div className="visibleDataDiv float-left w-100">
                          <div className="row">
                            <div className="col-md-12">
                              <div className="float-left w-100 text-right p-t-5">
                                <Button
                                  className="btn bg-transparent text-black-12 border-primary text-primary m-r-5 p-r-7 p-l-7"
                                  style={{ height: "26px" }}
                                >
                                  <span className="p-r-5">Edit</span>
                                  <i className="bi bi-pencil"></i>
                                </Button>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-12">
                              <div className="trRowData float-left w-100 p-b-5">
                                <div className="trk-row">
                                  <div className="trkCol d-flex align-items-center">
                                    <div className="trkCol-h font-weight-semi">
                                      Masking Policy
                                    </div>
                                    <div className="trkCol-dot">:</div>
                                    <div className="trkCol-p border-none">
                                      <div className="keywordRow">
                                        <Checkbox
                                          className="m-l-2"
                                          label={"Mask Inbound"}
                                        />
                                        <Checkbox
                                          className="m-l-8"
                                          label={"Mask Outbound"}
                                        />
                                        <Checkbox
                                          className="m-l-8"
                                          label={"Mask Internal"}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="trk-row">
                                  <div className="trkCol d-flex align-items-center">
                                    <div className="trkCol-h font-weight-semi">
                                      Number of digits to Mask
                                    </div>
                                    <div className="trkCol-dot">:</div>
                                    <div className="trkCol-p border-none">
                                      <span>
                                        <ComboBox
                                          data={maskCount}
                                          placeholder="Select Count"
                                        />
                                      </span>
                                    </div>
                                  </div>
                                </div>
                                <div className="row">
                                  <div className="col-md-12 text-right p-b-5">
                                    <div className="btnRightDiv">
                                      <Button className="btn bg-black-5 m-r-5">
                                        Cancel
                                      </Button>
                                      <Button className="btn bg-primary text-white">
                                        Save
                                      </Button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </PanelBarItem>
                    <PanelBarItem
                      title="Key Management"
                      animation={true}
                      icon="folder"
                    >
                      <div className="float-left w-100 p-t-5 p-l-5 p-r-5">
                        <div className="editableDataDiv float-left w-100">
                          <div className="row">
                            <div className="col-md-12">
                              <div className="trk-row">
                                <div className="trkCol d-flex align-items-center">
                                  <div className="trkCol-h font-weight-semi">
                                    Public Key
                                  </div>
                                  <div className="trkCol-dot">:</div>
                                  <div className="trkCol-p border-none">
                                    {/* <span>{publicKey.substring(0, 50)}...</span> */}
                                    <span>{`\u25CF`.repeat(20)}</span>
                                    <Button className="btn bg-transparent text-black-12 text-primary m-l-2 m-r-5 p-r-7 p-l-7">
                                      <i className="bi bi-files fs-16"></i>
                                      {/* <i className="bi bi-check-lg"></i> */}
                                    </Button>
                                    <Button className="d-flex align-items-center float-right btn bg-transparent text-black-12 border-primary text-primary m-r-5 m-l-5 m-r-5 p-r-7 p-l-7">
                                      <span className="p-r-5">
                                        Re-generate Keys
                                      </span>
                                      <i className="bi bi-arrow-clockwise fs-14"></i>
                                    </Button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </PanelBarItem>
                  </PanelBar>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SystemSettingSecurity;
