import { Button } from "@progress/kendo-react-buttons";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import { ListItemProps } from "@progress/kendo-react-dropdowns";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { Loader } from "@progress/kendo-react-indicators";
import React, { useEffect, useRef, useState } from "react";
import CustomFormTextArea from "../../../components/custom/form/CustomFormTextArea";
import CustomInput from "../../../components/custom/form/CustomInput";
import CustomMultiSelect from "../../../components/custom/form/CustomMultiSelect";
import CustomUserGridInfo from "../../../components/custom/grid/CustomUserGridInfo";
import {
  InsertScorecard,
  Scorecard,
  UpdateScorecard,
} from "../../../types/scorecard";
import {
  ScorecardAllocation,
  ScorecardAllocationInstance,
} from "../../../types/scorecard/ScorecardAllocation";
import { TinyObj } from "../../../types/TinyObj";
import { TinyUser } from "../../../types/user";
import { Group } from "../../../types/user-groups/Group";
import { axiosWithAuth } from "../../../utils/customAxios";
import CustomGridGroupInfo from "../../../components/custom/grid/CustomGridGroupInfo";
import useLocale from "../../../hooks/useLocale";
import { Dictionary } from "../../../types/Dictionary";
import userGroupsService from "../../../services/usergroups.service";
import useMasterData from "../../../hooks/useMasterData";
import useSwal from "../../../hooks/useSwal";

interface IScorecardDialogProps {
  toggleDialog: () => void;
  scorecardWithAllocation?: ScorecardWithAllocation;
  upsertScorecard: (
    isUpdated: boolean,
    scoreAllocation: ScorecardWithAllocation
  ) => void;
}

interface ScorecardWithAllocation {
  scorecard: Scorecard;
  allocation: ScorecardAllocation;
}

const UpsertScorecardDialog: React.FC<IScorecardDialogProps> = ({
  toggleDialog,
  scorecardWithAllocation,
  upsertScorecard,
}) => {
  const masterData = useMasterData();
  const localeCtx = useLocale();
  const swal = useSwal();
  const [loading, setLoading] = useState<boolean>(false);
  const [allGroups, setAllGroups] = useState<Group[] | undefined>(undefined);
  const [translationsLoading, setTranslationsLoading] =
    useState<boolean>(false);
  const [translations, setTranslations] = useState<
    Dictionary<string> | undefined
  >(
    localeCtx?.selectedLocale?.current.componentTranslations[
      "UpsertScorecardDialog"
    ]
  );

  useEffect(() => {
    if (
      !localeCtx?.selectedLocale?.current.componentTranslations[
        "UpsertScorecardDialog"
      ]
    ) {
      fetchTranslations();
    }
  }, [localeCtx?.selectedLocale]);

  const fetchTranslations = async () => {
    try {
      setTranslationsLoading(true);
      const resp = await localeCtx?.setComponentTranslations(
        "UpsertScorecardDialog"
      );
      setTranslations(resp);
    } catch (err) {
      console.error(err);
      setTranslations(
        localeCtx?.selectedLocale?.previous.componentTranslations[
          "UpsertScorecardDialog"
        ]
      );
      localeCtx?.setPreviousAppLocale("UpsertScorecardDialog");
      if (localeCtx?.localeSwitchFailed) {
        swal.fire({
          icon: "error",
          title: "Error",
          text: "Couldn't Switch Language",
        });
      }
    } finally {
      setTimeout(() => {
        setTranslationsLoading(false);
      }, 100);
    }
  };
  const fetchLabelKeyTranslation = (
    key: string,
    defaultValue: string
  ): string => {
    return translations && translations[key] ? translations[key] : defaultValue;
  };

  const itemRenderUser = (
    li: React.ReactElement<HTMLLIElement>,
    itemProps: ListItemProps
  ) => {
    const userInfo: TinyUser = {
      id: itemProps.dataItem.id,
      loginUserId: itemProps.dataItem.loginUserId,
      firstName: itemProps.dataItem.firstName,
      lastName: itemProps.dataItem.lastName,
      email: itemProps.dataItem.email,
      phoneNo: itemProps.dataItem.phoneNo,
      image: itemProps.dataItem.image,
      isArchived: itemProps.dataItem.isArchived,
    };
    const checkedclass = itemProps.selected ? "liChecked" : "";
    const itemChildren = (
      <span className={checkedclass}>
        <CustomUserGridInfo userInfo={userInfo} selected={itemProps.selected} />
      </span>
    );
    return React.cloneElement(li, li.props, itemChildren);
  };

  const itemRenderGroup = (
    li: React.ReactElement<HTMLLIElement>,
    itemProps: ListItemProps
  ) => {
    const checkedGroup = itemProps.selected ? "liChecked" : "";
    const itemChildren = (
      <span className={checkedGroup}>
        <CustomGridGroupInfo group={itemProps.dataItem}></CustomGridGroupInfo>
      </span>
    );
    return React.cloneElement(li, li.props, itemChildren);
  };

  const nameValidator = (value: string) => {
    if (!value) {
      return translationsLoading
        ? "Scorecard Name cannot be empty"
        : fetchLabelKeyTranslation(
            "SCNameValidationText1",
            "Scorecard Name cannot be empty"
          );
    }
    if (value.length > 100) {
      return translationsLoading
        ? "Scorecard name length cannot be more than 100!"
        : fetchLabelKeyTranslation(
            "SCNameValidationText2",
            "Scorecard name length cannot be more than 100!"
          );
    }

    return "";
  };

  const [error, setError] = useState<string | undefined>();

  const handleAdd = (
    scorecardVal: InsertScorecard,
    scoreAllocation: ScorecardAllocation
  ) => {
    axiosWithAuth
      .post(`/scorecard`, scorecardVal)
      .then(async (response) => {
        const sc: Scorecard = response.data;
        const scorecardName: TinyObj = {
          id: sc.id,
          name: sc.scorecardName,
        };
        axiosWithAuth
          .post(`scorecard/${sc.id}/allocation`, scoreAllocation)
          .then((response) => {
            swal.fire({
              icon: "success",
              title: `${
                translationsLoading
                  ? "Scorecard Created"
                  : fetchLabelKeyTranslation(
                      "SwtSCCreatedSuccessTitle",
                      "Scorecard Created"
                    )
              }`,
              confirmButtonText: `${
                translationsLoading
                  ? "OK"
                  : fetchLabelKeyTranslation("OKText", "OK")
              }`,
            });
            const scorecardWithAllocation: ScorecardWithAllocation = {
              scorecard: sc,
              allocation: scoreAllocation,
            };
            upsertScorecard(false, scorecardWithAllocation);
          })
          .catch((err) => {
            if (err instanceof Error) {
              swal.fire({
                icon: "error",
                title: `${
                  translationsLoading
                    ? "Error"
                    : fetchLabelKeyTranslation(
                        "SwtSCCreatedFailedTitle",
                        "Error"
                      )
                }`,
                text: `${
                  translationsLoading
                    ? "Something went wrong."
                    : fetchLabelKeyTranslation(
                        "SwtSCCreatedFailedText",
                        "Something went wrong."
                      )
                }`,
                confirmButtonText: `${
                  translationsLoading
                    ? "OK"
                    : fetchLabelKeyTranslation("OKText", "OK")
                }`,
              });
            }
          });
        toggleDialog();
      })
      .catch((err) => {
        if (err.response.data.error === "Name already exists.") {
          setError(
            `${
              translationsLoading
                ? "Scorecard with same name already exists."
                : fetchLabelKeyTranslation(
                    "InvalidNameTitle",
                    "Scorecard with same name already exists."
                  )
            }`
          );
        } else {
          setError(
            `${
              translationsLoading
                ? "Something went wrong."
                : fetchLabelKeyTranslation(
                    "SwtSCCreatedFailedText",
                    "Something went wrong."
                  )
            }`
          );
        }
      });
  };

  const handleUpdate = (
    scorecardVal: UpdateScorecard,
    scoreAllocation: ScorecardAllocation,
    id: number
  ) => {
    axiosWithAuth
      .put(`/scorecard/${id}`, scorecardVal)
      .then(async (response) => {
        const sc: Scorecard = response.data;
        const scorecardName: TinyObj = {
          id: sc.id,
          name: sc.scorecardName,
        };
        axiosWithAuth
          .post(`scorecard/${sc.id}/allocation`, scoreAllocation)
          .then((response) => {
            swal.fire({
              icon: "success",
              title: `${
                translationsLoading
                  ? "Scorecard Updated"
                  : fetchLabelKeyTranslation(
                      "SwtSCCreatedUpdatedTitle",
                      "Scorecard Updated"
                    )
              }`,
              confirmButtonText: `${
                translationsLoading
                  ? "OK"
                  : fetchLabelKeyTranslation("OKText", "OK")
              }`,
            });
            const scorecardWithAllocation: ScorecardWithAllocation = {
              scorecard: sc,
              allocation: scoreAllocation,
            };
            upsertScorecard(true, scorecardWithAllocation);
          })
          .catch((err) => {
            swal.fire({
              icon: "error",
              title: `${
                translationsLoading
                  ? "Error"
                  : fetchLabelKeyTranslation(
                      "SwtSCCreatedFailedTitle",
                      "SwtSCCreatedUpdatedText"
                    )
              }`,
              text: `${
                translationsLoading
                  ? "Something went wrong."
                  : fetchLabelKeyTranslation(
                      "SwtSCCreatedFailedText",
                      "Something went wrong."
                    )
              }`,
              confirmButtonText: `${
                translationsLoading
                  ? "OK"
                  : fetchLabelKeyTranslation("OKText", "OK")
              }`,
            });
          });
        toggleDialog();
      })
      .catch((err) => {
        if (err.response.status == 405) {
          toggleDialog();
          swal
            .fire({
              icon: "info",
              title: `${
                translationsLoading
                  ? "Scorecard cannot be Updated"
                  : fetchLabelKeyTranslation(
                      "SwtAltUpdateFailedTitle",
                      "Scorecard cannot be Updated"
                    )
              }`,
              text: `${
                translationsLoading
                  ? "You can archive the Scorecard"
                  : fetchLabelKeyTranslation(
                      "SwtSCDeleteConfirmText",
                      "You can archive the Scorecard"
                    )
              }`,
              showCancelButton: true,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#d33",
              confirmButtonText: `${
                translationsLoading
                  ? "Yes, Archive it!"
                  : fetchLabelKeyTranslation(
                      "SwtAltArchiveIfBtnText",
                      "Yes, Archive it!"
                    )
              }`,
              cancelButtonText: `${
                translationsLoading
                  ? "Cancel"
                  : fetchLabelKeyTranslation("SwtAltCancelBtnText", "Cancel")
              }`,
            })
            .then(async (result) => {
              if (result.isConfirmed) {
                const updatedScorecard: Scorecard | undefined =
                  scorecardWithAllocation?.scorecard;
                if (updatedScorecard != undefined) {
                  updatedScorecard.isArchived = true;
                }
                await axiosWithAuth
                  .put(
                    `/scorecard/${scorecardWithAllocation?.scorecard.id}`,
                    updatedScorecard
                  )
                  .then((response) => {
                    swal.fire({
                      icon: "success",
                      title: `${
                        translationsLoading
                          ? "Scorecard Archived"
                          : fetchLabelKeyTranslation(
                              "SwtAltSCArchivedSuccessTitle",
                              "Scorecard Archived"
                            )
                      }`,
                      text: `${
                        translationsLoading
                          ? "Scorecard has been archived Sucessfully"
                          : fetchLabelKeyTranslation(
                              "SwtAltSCArchivedSuccessText",
                              "Scorecard has been archived Sucessfully"
                            )
                      }`,
                    });
                    const newSCwithAllocation: ScorecardWithAllocation = {
                      scorecard: response.data,
                      allocation: scorecardWithAllocation?.allocation
                        ? scorecardWithAllocation?.allocation
                        : scoreAllocation,
                    };
                    upsertScorecard(true, newSCwithAllocation);
                  });
              }
            });
        } else {
          if (err.response.data.error === "Name already exists.") {
            setError(
              `${
                translationsLoading
                  ? "Scorecard with same name already exists."
                  : fetchLabelKeyTranslation(
                      "InvalidNameTitle",
                      "Scorecard with same name already exists."
                    )
              }`
            );
          } else {
            setError(
              `${
                translationsLoading
                  ? "Something went wrong."
                  : fetchLabelKeyTranslation(
                      "SwtSCCreatedFailedText",
                      "Something went wrong."
                    )
              }`
            );
          }
        }
      });
  };

  const getAllUserGroups = async () => {
    const groups = await userGroupsService.getUserGroups();
    setAllGroups(groups);
  };

  const formRef = useRef<any>();

  const submitHandler = () => {
    setError(undefined);
    const form = formRef.current as Form;
    const scorecardCreateVal: InsertScorecard = {
      scorecardName: form.values.scorecardName,
      description: form.values.description,
    };
    const scorecardUpdateVal: UpdateScorecard = {
      scorecardName: form.values.scorecardName,
      description: form.values.description,
    };

    const tinySc: TinyObj = {
      id: scorecardWithAllocation?.scorecard.id
        ? scorecardWithAllocation?.scorecard.id
        : 0,
      name: form.values.scorecardName,
    };

    const users: ScorecardAllocationInstance[] = form.values.users.map(
      (user: TinyUser) => {
        const allocInstance: ScorecardAllocationInstance = {
          user: user,
        };
        return allocInstance;
      }
    );

    const groups: ScorecardAllocationInstance[] = form.values.groups.map(
      (group: TinyObj) => {
        const allocInstance: ScorecardAllocationInstance = {
          group: group,
        };
        return allocInstance;
      }
    );

    const scoreAllocation: ScorecardAllocation = {
      ScorecardName: tinySc,
      users: users,
      groups: groups,
    };
    !scorecardWithAllocation
      ? handleAdd(scorecardCreateVal, scoreAllocation)
      : handleUpdate(
          scorecardUpdateVal,
          scoreAllocation,
          scorecardWithAllocation.scorecard.id
        );
  };

  useEffect(() => {
    getAllUserGroups();
  }, []);

  return (
    <Form
      ref={formRef}
      initialValues={{
        scorecardName: scorecardWithAllocation?.scorecard.scorecardName ?? "",
        description: scorecardWithAllocation?.scorecard.description ?? "",
        users:
          scorecardWithAllocation?.allocation.users?.map((u) => {
            return u.user;
          }) ?? [],
        groups:
          scorecardWithAllocation?.allocation.groups?.map((g) => {
            return g.group;
          }) ?? [],
      }}
      render={(formRenderProps: FormRenderProps) => (
        <FormElement style={{ maxWidth: 650 }}>
          <Dialog
            title={
              scorecardWithAllocation
                ? `${
                    translationsLoading
                      ? "Edit Scorecard"
                      : fetchLabelKeyTranslation(
                          "UpsertSCTitleEdit",
                          "Edit Scorecard"
                        )
                  }`
                : `${
                    translationsLoading
                      ? "Add Scorecard"
                      : fetchLabelKeyTranslation(
                          "UpsertSCTitleAdd",
                          "Add Scorecard"
                        )
                  }`
            }
            onClose={toggleDialog}
          >
            <div className="formAdd" style={{ marginBottom: "0px" }}>
              {error && <span className="tx-red">{error}</span>}
              <div className="formBoxRow p-t-5 p-b-5">
                <div className="formBoxLabel fs-14">
                  {`${
                    translationsLoading
                      ? "Scorecard Name"
                      : fetchLabelKeyTranslation(
                          "UpsertSCName",
                          "Scorecard Name"
                        )
                  }`}
                </div>
                <div className="formBoxAction">
                  <div className="formInput">
                    <Field
                      id="scorecardName"
                      name="scorecardName"
                      style={{ height: "32px" }}
                      value={formRenderProps.valueGetter("scorecardName")}
                      placeholder={`${
                        translationsLoading
                          ? "Enter Scorecard name..."
                          : fetchLabelKeyTranslation(
                              "UpsertSCNamePlaceHolderText",
                              "Enter Scorecard name..."
                            )
                      }`}
                      component={CustomInput}
                      validator={nameValidator}
                    />
                  </div>
                </div>
              </div>
              <div className="formBoxRow p-t-5 p-b-5">
                <div className="formBoxLabel fs-14">
                  {`${
                    translationsLoading
                      ? "Description"
                      : fetchLabelKeyTranslation("Description", "Description")
                  }`}
                </div>
                <div className="formBoxAction">
                  <div className="formInput">
                    <Field
                      id={"description"}
                      name={"description"}
                      style={{
                        minWidth: "250px",
                        height: "auto",
                        minHeight: "70px",
                      }}
                      max={1000}
                      autoSize={true}
                      rows={3}
                      showTextLimitHint={false}
                      placeholder={`${
                        translationsLoading
                          ? "Enter Scorecard description..."
                          : fetchLabelKeyTranslation(
                              "UpsertSCDescriptionPlaceHolderText",
                              "Enter Scorecard description..."
                            )
                      }`}
                      value={formRenderProps.valueGetter("description")}
                      component={CustomFormTextArea}
                    />
                  </div>
                </div>
              </div>
              <div className="formBoxRow p-t-5 p-b-15">
                <div className="formBoxLabel fs-14">
                  {`${
                    translationsLoading
                      ? "Assign Users"
                      : fetchLabelKeyTranslation(
                          "UpsertSCAssignUsers",
                          "Assign Users"
                        )
                  }`}
                </div>
                <div className="formBoxAction">
                  <div className="formInput">
                    {masterData?.users?.length === 0 ? (
                      <Loader size="small" type="infinite-spinner" />
                    ) : (
                      <Field
                        id="users"
                        name="users"
                        data={masterData?.users}
                        fillMode="solid"
                        component={CustomMultiSelect}
                        itemRender={itemRenderUser}
                        textField="email"
                        value={formRenderProps.valueGetter("users")}
                        placeholder={`${
                          translationsLoading
                            ? "Select Users"
                            : fetchLabelKeyTranslation(
                                "UpsertSCAssignUsersPlaceHolderText",
                                "Select Users"
                              )
                        }`}
                        dataItemKey="id"
                        filtering={true}
                      />
                    )}
                  </div>
                </div>
              </div>
              <div className="formBoxRow p-t-5 p-b-15">
                <div className="formBoxLabel fs-14">
                  {`${
                    translationsLoading
                      ? "Assign User Groups"
                      : fetchLabelKeyTranslation(
                          "UpsertSCAssignGroups",
                          "Assign User Groups"
                        )
                  }`}
                </div>
                <div className="formBoxAction">
                  <div className="formInput">
                    {!allGroups ? (
                      <Loader size="small" type="infinite-spinner" />
                    ) : (
                      <Field
                        id="groups"
                        name="groups"
                        data={allGroups}
                        component={CustomMultiSelect}
                        itemRender={itemRenderGroup}
                        textField="name"
                        value={formRenderProps.valueGetter("groups")}
                        placeholder={`${
                          translationsLoading
                            ? "Select User Groups"
                            : fetchLabelKeyTranslation(
                                "UpsertSCAssignGroupsPlaceHolderText",
                                "Select User Groups"
                              )
                        }`}
                        dataItemKey="id"
                        fillMode="solid"
                        filtering={true}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
            <DialogActionsBar>
              <Button onClick={toggleDialog}>
                {`${
                  translationsLoading
                    ? "Cancel"
                    : fetchLabelKeyTranslation(
                        "UpsertCancelButtonText",
                        "Cancel"
                      )
                }`}
              </Button>
              <Button
                themeColor={"primary"}
                fillMode={"solid"}
                onClick={submitHandler}
                disabled={!formRenderProps.valid}
              >
                {loading ? (
                  <Loader
                    themeColor={"primary"}
                    size={"small"}
                    type={"infinite-spinner"}
                  />
                ) : (
                  `${
                    scorecardWithAllocation
                      ? `${
                          translationsLoading
                            ? "Update"
                            : fetchLabelKeyTranslation(
                                "UpsertUpdateButtonText",
                                "Update"
                              )
                        }`
                      : `${
                          translationsLoading
                            ? "Add"
                            : fetchLabelKeyTranslation(
                                "UpsertAddButtonText",
                                "Add"
                              )
                        }`
                  }`
                )}
              </Button>
            </DialogActionsBar>
          </Dialog>
        </FormElement>
      )}
    />
  );
};

export default UpsertScorecardDialog;
