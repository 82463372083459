import { GridCellProps } from "@progress/kendo-react-grid";
import { CallDetail } from "../../../types/call";
import CallDirection from "../CallDirection";
import { Button } from "@progress/kendo-react-buttons";
import { Tooltip } from "@progress/kendo-react-tooltip";

interface CustomGridCellProps extends GridCellProps {
  dataItem: CallDetail;
}

const CustomGridCellForCallDirection = (props: CustomGridCellProps) => {
  return (
    <td className="text-center">
      <Tooltip anchorElement={"target"} position={"top"} parentTitle={true}>
        <span className="iBtn">
          <Button className="text-primary radius-50">
            <CallDirection callDirection={props.dataItem.callDirection} callType={props.dataItem.callType} />
          </Button>
        </span>
      </Tooltip>
    </td>
  );
};

export default CustomGridCellForCallDirection;
