import React, { useDebugValue, useEffect, useState } from "react";
import { Button } from "@progress/kendo-react-buttons";
import {
  TabStrip,
  TabStripSelectEventArguments,
  TabStripTab,
} from "@progress/kendo-react-layout";
import {
  Field,
  FormElement,
  Form,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { ComboBox, MultiSelect } from "@progress/kendo-react-dropdowns";
import "./UserProfile.scss";
import { User } from "../../../../../types/user";
import { Customer } from "../../../../../types/customer/Customer";
import { Loader } from "@progress/kendo-react-indicators";
import useLocale from "../../../../../hooks/useLocale";
import { Dictionary } from "../../../../../types/Dictionary";
import usersService from "../../../../../services/users.service";
import useAuth from "../../../../../hooks/useAuth";
import { AccessPermissionEnum } from "../../../../../enums";
import {
  UpsertUser,
  UpsertUserDevices,
} from "../../../../../types/user/UpsertUser";
import { AuthorizationProfile } from "../../../../../types/authorization-profile/AuthorizationProfile";
import { TinyObj } from "../../../../../types/TinyObj";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import useMasterData from "../../../../../hooks/useMasterData";
import CustomSearchFieldTextInput from "../../../../../components/custom/form/CustomSearchFieldTextInput";
import { RecordingNetworkAccessibilty } from "../../../../../types/master-data";
import { Group } from "../../../../../types/user-groups/Group";
import CustomUserGridInfo from "../../../../../components/custom/grid/CustomUserGridInfo";
import { Link, useLocation } from "react-router-dom";
import { navData } from "../../../../../data/NavigationData";
import useSwal from "../../../../../hooks/useSwal";
import CustomComboBox from "../../../../../components/custom/form/CustomComboBox";
import CustomMultiSelect from "../../../../../components/custom/form/CustomMultiSelect";

interface IUserRecordingNetworkGroup {
  groupDetail: TinyObj;
  users: User[];
}

interface ICustomTabTitleProp {
  name: string;
  count: number;
}

interface ICustomUserCardProp {
  user: User;
}

interface IUserPermissionsProps {
  user: User;
  authProfiles: AuthorizationProfile[] | undefined;
  userGroups: Group[] | undefined;
  setChanged: Function;
  updateUserDetails: () => void;
}

const CustomTabTitle = (props: ICustomTabTitleProp) => {
  return (
    <span>
      <span>{props.name}</span>
      <span className="tabBadge m-l-5">{props.count ? props.count : 0}</span>
    </span>
  );
};

const CustomUserCard = (props: ICustomUserCardProp) => {
  const user = props.user;
  const auth = useAuth();
  const location = useLocation();

  return (
    <div className="cardListBlock">
      <div className="cardRows">
        <div className="cardColumn">
          <div className="colUser">
            <CustomUserGridInfo
              userInfo={{
                id: user.id,
                loginUserId: user.loginUserId,
                firstName: user.firstName ? user.firstName : "",
                lastName: user.lastName ? user.lastName : "",
                image: user.image ? user.image : "",
                email: user.email,
                phoneNo: "",
                isArchived: user.isArchived,
              }}
            />
          </div>
          <div className="lblGroup float-left w-100">
            <div className="fs-12 font-weight-semi p-t-7 p-b-7 text-truncate">
              <span>{user.authorizationProfile?.name}</span>
            </div>
            <div className="minTextLabel p-t-1">
              <span
                className="float-left text-black-9 p-b-2"
                style={{ minWidth: "77px" }}
              >
                Recorded Device:
              </span>
              <span className="p-l-3">
                <span className="badgeList">
                  <div className="keywordRow">
                    {user.devices.map((device) => {
                      return (
                        <div
                          className="keywordTrk"
                          key={device.deviceIdentifier}
                        >
                          {device.deviceIdentifier}
                        </div>
                      );
                    })}
                  </div>
                </span>
              </span>
            </div>
          </div>
        </div>
      </div>
      {auth?.checkUserAccess(AccessPermissionEnum.ManageUsersAndGroups) && (
        <div className="blockIconButton">
          <span className="btnBlocks">
            <Link
              to={`/settings/user-control/user/${user.id}`}
              className="btnBlock-a"
              state={{ ...navData, from: location.pathname }}
            >
              <i className="bi bi-pencil fs-17"></i>
            </Link>
          </span>
        </div>
      )}
    </div>
  );
};

const UserPermissions: React.FC<IUserPermissionsProps> = ({
  user,
  authProfiles,
  userGroups,
  setChanged,
  updateUserDetails,
}) => {
  const localeCtx = useLocale();
  const auth = useAuth();
  const Swal = useSwal();
  const masterData = useMasterData();
  const [translationsLoading, setTranslationsLoading] =
    useState<boolean>(false);
  const [translations, setTranslations] = useState<
    Dictionary<string> | undefined
  >(
    localeCtx?.selectedLocale?.current.componentTranslations["UserPermissions"]
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [userDetails, setUserDetails] = useState<User>(user);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [valuesChanged, setValuesChanged] = useState<boolean>(false);
  const [selectedPlaybackNetworkTab, setSelectedPlaybackNetworkTab] =
    useState<number>(0);
  const [selectedUserGroupTab, setSelectedUserGroupTab] = useState<number>(0);
  const [addMoreUserDialogVisible, setAddMoreUserDialogVisible] =
    useState<boolean>(false);
  const [userRecordingNetworkGroups, setUserRecordingNetworkGroups] = useState<
    IUserRecordingNetworkGroup[]
  >([]);
  const [userRecordingNetwork, setUserRecordingNetwork] = useState<User[]>([]);
  var _tempMyPeople: User[] = [];

  useEffect(() => {
    if (
      !localeCtx?.selectedLocale?.current.componentTranslations["UserProfile"]
    ) {
      fetchTranslations();
    }
  }, [localeCtx?.selectedLocale]);

  const fetchTranslations = async () => {
    try {
      setTranslationsLoading(true);
      const resp = await localeCtx?.setComponentTranslations("UserProfile");
      setTranslations(resp);
    } catch (err) {
      console.error(err);
      setTranslations(
        localeCtx?.selectedLocale?.previous.componentTranslations["UserProfile"]
      );
      localeCtx?.setPreviousAppLocale("UserProfile");
      if (localeCtx?.localeSwitchFailed) {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Couldn't Switch Language",
        });
      }
    } finally {
      setTimeout(() => {
        setTranslationsLoading(false);
      }, 100);
    }
  };

  const fetchLabelKeyTranslation = (
    key: string,
    defaultValue: string
  ): string => {
    return translations && translations[key] ? translations[key] : defaultValue;
  };

  useEffect(() => {
    updateUserRecordingNetwork();
    if (JSON.stringify(userDetails) === JSON.stringify(user)) {
      setValuesChanged(false);
      setChanged(false);
    } else {
      setValuesChanged(true);
      setChanged(true);
    }
  }, [userDetails]);

  const updateUserRecordingNetwork = () => {
    let _userRecordingNetworkGroups: IUserRecordingNetworkGroup[] = [];
    let _masterDataUsers = [
      ...(masterData?.standardUsers.filter((u) => !u.isArchived) ?? []),
    ];

    // Adding Organization Users....
    let _userOrganization: IUserRecordingNetworkGroup = {
      groupDetail: {
        id: -1,
        name: "Organization",
      },
      users: [],
    };
    if (userDetails.recordingNetworkAccessibilty?.id === 1) {
      const _ownData = _masterDataUsers.filter(
        (su) => su.id === userDetails.id
      );
      if (_ownData) {
        _userOrganization.users = [..._ownData];
      }
    } else if (userDetails.recordingNetworkAccessibilty?.id === 2) {
      let _directReports = sortUsersData(
        _masterDataUsers.filter((su) => su.manager?.id === userDetails.id)
      );
      if (_directReports) {
        _userOrganization.users = [..._directReports];
      }
    } else if (userDetails.recordingNetworkAccessibilty?.id === 3) {
      _tempMyPeople = [];
      getMyPeople(userDetails.id);
      _userOrganization.users = sortUsersData([..._tempMyPeople]);
      _tempMyPeople = [];
    } else if (userDetails.recordingNetworkAccessibilty?.id === 4) {
      _userOrganization.users = sortUsersData([..._masterDataUsers]);
    }
    _userRecordingNetworkGroups.push(_userOrganization);

    // Adding associated groups playback network....
    userDetails?.associatedGroups?.playbackNetwork?.forEach((gp) => {
      const _group = userGroups?.find((ug) => ug.id === gp.id);
      if (_group) {
        let _userNetworkGroup: IUserRecordingNetworkGroup = {
          groupDetail: _group,
          users: [],
        };
        _group.groupMembers.forEach((gm) => {
          const _member = masterData?.standardUsers.find(
            (su) => su.id === gm.member.id
          );
          if (_member) {
            _userNetworkGroup.users.push(_member);
          }
        });
        _userRecordingNetworkGroups.push(_userNetworkGroup);
      }
    });

    let _networkUsers: User[] = [];
    _userRecordingNetworkGroups.forEach((gp) => {
      _networkUsers = [..._networkUsers, ...gp.users];
    });

    setUserRecordingNetwork(sortUsersData(_networkUsers));
    setUserRecordingNetworkGroups(_userRecordingNetworkGroups);
  };

  const getMyPeople = (userId: number) => {
    let _directReportsToUserId = masterData?.standardUsers.filter(
      (su) => su.manager?.id === userId
    );
    if (_directReportsToUserId && _directReportsToUserId.length > 0) {
      _directReportsToUserId.forEach((ele) => {
        if (_tempMyPeople.findIndex((tp) => tp.id === ele.id) === -1) {
          _tempMyPeople = [..._tempMyPeople, ele];
          getMyPeople(ele.id);
        }
      });
    }
  };

  const sortUsersData = (_users: User[]) => {
    return _users.sort((a, b) => {
      let a_name = (
        (a.firstName ? a.firstName : "") +
        " " +
        (a.lastName ? a.lastName : "")
      )
        .trim()
        .toLocaleLowerCase();
      let b_name = (
        (b.firstName ? b.firstName : "") +
        " " +
        (b.lastName ? b.lastName : "")
      )
        .trim()
        .toLocaleLowerCase();
      return a_name > b_name ? 1 : -1;
    });
  };

  const reset = () => {
    setUserDetails(user);
  };

  const handlePlaybackNetworkTabSelection = (
    e: TabStripSelectEventArguments
  ) => {
    setSelectedPlaybackNetworkTab(e.selected);
  };

  const handleUserGroupTabSelection = (e: TabStripSelectEventArguments) => {
    setSelectedUserGroupTab(e.selected);
  };

  const toggleAddMoreUserDialog = () => {
    setAddMoreUserDialogVisible(!addMoreUserDialogVisible);
  };

  const handleSearchChange = (e: React.FormEvent<HTMLInputElement>) => {
    setSearchTerm(e.currentTarget.value);
  };

  const searchUser = (userDetails: User) => {
    var searchText = searchTerm.trim().toLocaleLowerCase();
    var firstName = userDetails.firstName ? userDetails?.firstName : "";
    var lastName = userDetails.lastName ? userDetails.lastName : "";
    var userName = `${firstName} ${lastName}`.trim();

    if (
      userDetails?.email?.toLocaleLowerCase().includes(searchText) ||
      userName?.toLocaleLowerCase().includes(searchText)
    ) {
      return true;
    } else {
      return false;
    }
  };

  const onSaveClicked = async () => {
    setLoading(true);
    Promise.all([updateUserInfo(), updateRecordingNetwork()])
      .then((responses) => {
        setLoading(false);
        Swal.fire({
          icon: "success",
          title: `${
            translationsLoading
              ? "Updated"
              : fetchLabelKeyTranslation("SwtAltUpdatedTitle", "Updated")
          }`,
          text: `${
            translationsLoading
              ? "User has been updated"
              : fetchLabelKeyTranslation(
                  "SwtAltUpdate",
                  "User has been updated"
                )
          }`,
          confirmButtonText: `${
            translationsLoading
              ? "OK"
              : fetchLabelKeyTranslation("OKText", "OK")
          }`,
        });
        updateUserDetails();
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
        if (err.response.status === 403) {
          Swal.fire({
            icon: "error",
            title: `${
              translationsLoading
                ? "Error"
                : fetchLabelKeyTranslation("SwtAltErrorTitle", "Error")
            }`,
            text: `${
              translationsLoading
                ? "Email Already Register"
                : fetchLabelKeyTranslation(
                    "SwtAltEARegistered",
                    "Email Already Register"
                  )
            }`,
            confirmButtonText: `${
              translationsLoading
                ? "OK"
                : fetchLabelKeyTranslation("OKText", "OK")
            }`,
          });
        } else if (err.response.status === 404) {
          Swal.fire({
            icon: "error",
            title: `${
              translationsLoading
                ? "Error"
                : fetchLabelKeyTranslation("SwtAltErrorTitle", "Error")
            }`,
            text: `${
              translationsLoading
                ? "User Registration failed"
                : fetchLabelKeyTranslation(
                    "SwtAltURFailed",
                    "User Registration failed"
                  )
            }`,
            confirmButtonText: `${
              translationsLoading
                ? "OK"
                : fetchLabelKeyTranslation("OKText", "OK")
            }`,
          });
        } else {
          Swal.fire({
            icon: "error",
            title: `${
              translationsLoading
                ? "Error"
                : fetchLabelKeyTranslation("SwtAltErrorTitle", "Error")
            }`,
            text: `${
              translationsLoading
                ? "Error Updating User"
                : fetchLabelKeyTranslation(
                    "SwtAltUpdateFailed",
                    "Error Updating User"
                  )
            }`,
            confirmButtonText: `${
              translationsLoading
                ? "OK"
                : fetchLabelKeyTranslation("OKText", "OK")
            }`,
          });
        }
      });
  };

  const updateUserInfo = async () => {
    const upsertUser: UpsertUser = {
      email: userDetails.email,
      firstName: userDetails.firstName,
      lastName: userDetails.lastName,
      managerEmailAddress: userDetails.manager?.email,
      countryName: userDetails.country?.name,
      timezoneIdentifier: userDetails.timezone?.timezoneIdentifier,
      localeCode: userDetails.locale?.code,
      loginEnabled: userDetails.loginEnabled,
      devices: userDetails.devices.map((ele) => {
        var upsertDevice: UpsertUserDevices = {
          devicePlatform: ele.platform,
          deviceIdentifier: ele.deviceIdentifier,
          license: ele.license?.licenseName,
          recordingPolicy: ele.recordingPolicy
            ? {
                isIncludedForRecording:
                  ele.recordingPolicy?.isIncludedForRecording,
                callSubsetTypeName:
                  ele.recordingPolicy?.callSubset?.name ?? "All Calls",
              }
            : undefined,
        };
        return upsertDevice;
      }),
      authorizationProfileName: userDetails.authorizationProfile?.name,
      recordingNetworkAccessibility:
        userDetails.recordingNetworkAccessibilty?.name,
    };
    const updatedUser = await usersService
      .updateUser(upsertUser, user.id)
      .then((data) => {})
      .catch((err) => {
        throw err;
      });
  };

  const updateRecordingNetwork = async () => {
    if (userDetails.associatedGroups !== null) {
      let updatedNetworkGroup = await usersService
        .updateRecordingNetworkViaGroup(
          userDetails.associatedGroups.playbackNetwork,
          user.id
        )
        .then((data) => {})
        .catch((err) => {
          console.log(err);
        });
    }
  };

  const AddMoreUserDialog = () => {
    const [recordingNetworkAccessibilties, setRecordingNetworkAccessibilties] =
      useState<RecordingNetworkAccessibilty | undefined>(
        userDetails.recordingNetworkAccessibilty
      );
    const [playbackNetworkGroups, setPlaybackNetworkGroups] = useState<
      TinyObj[]
    >(userDetails.associatedGroups?.playbackNetwork);

    const updatePlaybackNetwork = () => {
      setUserDetails({
        ...userDetails,
        recordingNetworkAccessibilty: recordingNetworkAccessibilties,
        associatedGroups: {
          ...userDetails.associatedGroups,
          playbackNetwork: playbackNetworkGroups,
        },
      });
      toggleAddMoreUserDialog();
    };

    return (
      <div>
      <Form
        render={(formRenderProps: FormRenderProps) => (
          <FormElement>
      <Dialog title={"Add Users"} onClose={toggleAddMoreUserDialog}>
        <div className="formBox p-t-15 p-b-15" style={{ maxWidth: "450px" }}>
          <div className="formBoxRow d-flex justify-content-between p-t-1 p-b-1">
            <div className="formBoxLabel fs-14 text-black-8">
              {translationsLoading
                ? "Add more people to Playback Network"
                : fetchLabelKeyTranslation(
                    "AddMorePeopleToPlaybackNetworkTitle",
                    "Add more people to Playback Network"
                  )}
            </div>
          </div>
          <div className="formBoxRow addGroupMultiProfile d-flex justify-content-between p-t-10 p-b-15">
            <div
              className="formBoxLabel fs-14 p-b-5"
              style={{ minWidth: "160px" }}
            >
              <span className="fs-18 text-black-8 p-r-20">
                <i className="bi bi-person"></i>
              </span>
              <span className="fs-14 text-black-11">
                {translationsLoading
                  ? "Via People"
                  : fetchLabelKeyTranslation("ViaPeopleTitle", "Via People")}
              </span>
            </div>
            <div className="formBoxAction w-100">
              <div className="formControl">
              <Field
                id="adViaPeople"
                name="adViaPeople"
                data={
                  masterData?.data?.recordingNetworkAccessibilties
                }
                // <ComboBox
                //   style={{ height: "32px" }}
                //   data={masterData?.data?.recordingNetworkAccessibilties}
                  value={recordingNetworkAccessibilties}
                  onChange={(e) => setRecordingNetworkAccessibilties(e.value)}
                  component={CustomComboBox}
                  textField="name"
                  filtering={true}
                  disabled={
                    !auth?.checkUserAccess(
                      AccessPermissionEnum.ManageUsersAndGroups
                    )
                  }
                />
              </div>
            </div>
          </div>
          <div className="formBoxRow addGroupMultiProfile d-flex justify-content-between p-t-1 p-b-5">
            <div
              className="formBoxLabel fs-14 p-b-5"
              style={{ minWidth: "160px" }}
            >
              <span className="fs-18 text-black-8 p-r-20">
                <i className="bi bi-people"></i>
              </span>
              <span className="fs-14 text-black-11">
                {translationsLoading
                  ? "Via User Group"
                  : fetchLabelKeyTranslation(
                      "ViaUserGroupTitle",
                      "Via User Group"
                    )}
              </span>
            </div>
            <div className="formBoxAction w-100">
              <div className="formInput">
              <Field
                id="addViaGroup"
                name="addViaGroup"
                data={userGroups}
                style={{ height: "32px" }}
                value={playbackNetworkGroups}
                component={CustomMultiSelect}
                textField="name"
                filtering={true}
                  placeholder={
                    translationsLoading
                      ? "Select Groups"
                      : fetchLabelKeyTranslation(
                          "ViaUserGroupPlaceHolder",
                          "Select Groups"
                        )
                  }
                  
                  onChange={(e) => setPlaybackNetworkGroups(e.value)}
                />
              </div>
            </div>
          </div>
        </div>
        <DialogActionsBar>
          <button
            className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
            onClick={toggleAddMoreUserDialog}
          >
            Cancel
          </button>
          <button
            className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base bg-primary text-white"
            onClick={updatePlaybackNetwork}
          >
            Update
          </button>
        </DialogActionsBar>
      </Dialog>
      </FormElement>
        )} />
            </div>)
  };

  return (
    <div className="tabInner">
      <div className="row">
        <div className="col-md-12">
          <div className="detailSection p-t-16 p-r-15 p-l-25">
            <div className="detailRow">
              <div className="float-left w-100">
                <div className="row">
                  <div className="col-md-4">
                    <div className="detailCol">
                      <div className="detailColBox">
                        <div className="formBox">
                          <div className="formBoxRow d-flex justify-content-between align-items-center p-t-7 p-b-7">
                            <div className="formBoxLabel fs-15 font-weight-semi">
                              {translationsLoading
                                ? "Permission Group"
                                : fetchLabelKeyTranslation(
                                    "PermissionGroupTitle",
                                    "Permission Group"
                                  )}
                            </div>
                            <div className="formBoxAction">
                              <div className="switchButton">
                                <ComboBox
                                  style={{ height: "32px" }}
                                  data={authProfiles}
                                  value={
                                    userDetails.authorizationProfile
                                      ? userDetails.authorizationProfile
                                      : ""
                                  }
                                  onChange={(e) => {
                                    setUserDetails({
                                      ...userDetails,
                                      authorizationProfile: e.value,
                                    });
                                  }}
                                  textField="name"
                                  disabled={
                                    !auth?.checkUserAccess(
                                      AccessPermissionEnum.ManageSecurityControl
                                    )
                                  }
                                />
                              </div>
                            </div>
                          </div>
                          <div className="formBoxRow p-t-5 p-b-5">
                            <div className="formBoxLabel fs-14 text-black-8">
                              {translationsLoading
                                ? "All Permissions"
                                : fetchLabelKeyTranslation(
                                    "AllPermissionsTitle",
                                    "All Permissions"
                                  )}
                            </div>
                          </div>
                          <div
                            className="allPermissionsList float-left scroller"
                            style={{
                              maxHeight: "350px",
                              overflow: "hidden auto",
                            }}
                          >
                            {masterData?.data?.accessPermissions.map((ele) => {
                              if (
                                ele.id === 8 &&
                                !auth?.checkTopicAvailibility()
                              ) {
                                return;
                              }
                              const pIndex =
                                userDetails.authorizationProfile?.permissions.findIndex(
                                  (p) => p.id === ele.id
                                );
                              const granted =
                                pIndex !== undefined && pIndex > -1;
                              return (
                                <div
                                  key={ele.id}
                                  className={`formBoxRow ${
                                    granted ? "" : "liUnChecked"
                                  } d-flex justify-content-between align-items-center p-t-12 p-b-12 border-bottom-solid border-w-1 border-black-1`}
                                >
                                  <div className="formBoxLabel">
                                    <span
                                      className="fs-14 p-r-8 text-black-9"
                                      title={
                                        ele.isPlaybackPermission
                                          ? "Playback Permission"
                                          : ""
                                      }
                                    >
                                      <i
                                        className={`bi ${
                                          ele.isPlaybackPermission
                                            ? "bi-collection-play-fill"
                                            : "bi-shield-lock-fill"
                                        }`}
                                      ></i>
                                    </span>
                                    <span className="fs-14">{ele.name}</span>
                                  </div>
                                  {granted && (
                                    <i className="bi bi-check-circle fs-20 text-success p-r-10"></i>
                                  )}
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-8 cardLab cardLabRow">
                    <div className="detailColOuterLabel h-100 w-100 float-left p-l-15 border-l-1 border-solid-black border-left-solid border-left-w-1 border-black-1">
                      <div className="detailCol">
                        <div className="titleWithTab float-left w-100 p-l-10 p-r-10">
                          <div className="detailColTitle float-left w-100 fs-15 text-default font-weight-semi p-b-5">
                            <div className="d-flex justify-content-between align-items-center">
                              <span>
                                {translationsLoading
                                  ? "Playback Network"
                                  : fetchLabelKeyTranslation(
                                      "PlaybackNetworkTitle",
                                      "Playback Network"
                                    )}
                              </span>
                              <div
                                className="leftProControl d-flex align-items-center"
                                style={{
                                  margin: "-5px 0 0 0",
                                  padding: "0 0 5px 0",
                                }}
                              >
                                <div className="searchCol searchBox m-r-10">
                                  <CustomSearchFieldTextInput
                                    className="input-search"
                                    placeholder={
                                      translationsLoading
                                        ? "Search…"
                                        : fetchLabelKeyTranslation(
                                            "SearchBoxPlaceHolder",
                                            "Search…"
                                          )
                                    }
                                    value={searchTerm}
                                    onEscapeKeyFunc={true}
                                    searchTextChangeHandler={handleSearchChange}
                                    updateValue={setSearchTerm}
                                  />
                                </div>
                                <Button
                                  onClick={toggleAddMoreUserDialog}
                                  className="btn bg-primary text-white fs-13 fw-normal cursor-pointer"
                                  style={{
                                    height: "29px",
                                    margin: "-1px 0 0 0",
                                  }}
                                  title={"Add"}
                                >
                                  Add
                                </Button>
                                {addMoreUserDialogVisible && (
                                  <AddMoreUserDialog />
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="detailColBox float-left w-100">
                          <div className="formBox">
                            <div className="dvcList p-t-1">
                              <div className="listOverflowDvc float-left w-100 p-12 p-t-1">
                                <div className="cardPro">
                                  <div className="tabLabel tabLeftPadding-0 h-100">
                                    <TabStrip
                                      selected={selectedPlaybackNetworkTab}
                                      onSelect={
                                        handlePlaybackNetworkTabSelection
                                      }
                                    >
                                      <TabStripTab
                                        title={
                                          <CustomTabTitle
                                            name="People"
                                            count={
                                              userRecordingNetwork.filter(
                                                (ele) => searchUser(ele)
                                              ).length
                                            }
                                          />
                                        }
                                      >
                                        <div className="cardBrand">
                                          <div className="cardListArea">
                                            <div className="row">
                                              {userRecordingNetwork &&
                                              userRecordingNetwork.filter(
                                                (ele) => searchUser(ele)
                                              ).length > 0 ? (
                                                userRecordingNetwork
                                                  .filter((ele) =>
                                                    searchUser(ele)
                                                  )
                                                  .map((_user) => {
                                                    return (
                                                      <div className="col col-md-4">
                                                        <CustomUserCard
                                                          user={_user}
                                                        />
                                                      </div>
                                                    );
                                                  })
                                              ) : (
                                                <div className="col-md-12 p-l-5">
                                                  <div className="dashedBox p-10 m-b-20 float-left w-100 radius-6 border-black-3 border-w-2 border-dashed d-flex align-items-center justify-content-center">
                                                    <span className="fs-13 text-black-9 p-t-20 p-b-20">
                                                      No Users Available
                                                    </span>
                                                  </div>
                                                </div>
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                      </TabStripTab>
                                      <TabStripTab
                                        title={
                                          <CustomTabTitle
                                            name="User Group"
                                            count={
                                              userRecordingNetworkGroups.filter(
                                                (ele) =>
                                                  ele.users.filter((u) =>
                                                    searchUser(u)
                                                  ).length > 0
                                              ).length
                                            }
                                          />
                                        }
                                      >
                                        <div className="cardBrand">
                                          <div className="navSidePro w-100 p-t-12">
                                            <TabStrip
                                              selected={selectedUserGroupTab}
                                              onSelect={
                                                handleUserGroupTabSelection
                                              }
                                              tabPosition="left"
                                            >
                                              {userRecordingNetworkGroups
                                                .filter(
                                                  (ele) =>
                                                    ele.users.filter((u) =>
                                                      searchUser(u)
                                                    ).length > 0
                                                )
                                                .map((_group) => {
                                                  return (
                                                    <TabStripTab
                                                      title={
                                                        _group.groupDetail.name
                                                      }
                                                    >
                                                      <div className="navSideProInr p-l-17">
                                                        {_group.users &&
                                                        _group.users.filter(
                                                          (u) => searchUser(u)
                                                        ).length > 0 ? (
                                                          <div className="cardListArea">
                                                            <div className="row">
                                                              {_group.users
                                                                .filter((u) =>
                                                                  searchUser(u)
                                                                )
                                                                .map(
                                                                  (_member) => {
                                                                    return (
                                                                      <div className="col col-md-4">
                                                                        <CustomUserCard
                                                                          user={
                                                                            _member
                                                                          }
                                                                        />
                                                                      </div>
                                                                    );
                                                                  }
                                                                )}
                                                            </div>
                                                          </div>
                                                        ) : (
                                                          <div
                                                            style={{
                                                              minHeight:
                                                                "200px",
                                                            }}
                                                            className="p-10 m-b-20 text-black-9 d-flex align-items-center justify-content-center radius-6 border-black-3 border-w-2 border-dashed"
                                                          >
                                                            No Users available
                                                          </div>
                                                        )}
                                                      </div>
                                                    </TabStripTab>
                                                  );
                                                })}
                                            </TabStrip>
                                          </div>
                                        </div>
                                      </TabStripTab>
                                    </TabStrip>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {valuesChanged && (
              <div className="row">
                <div className="col-md-12">
                  <div className="float-left w-100 p-t-12 p-b-6 p-l-15 p-r-15 m-t-10 text-right border-top-solid border-w-1 border-black-1">
                    <Button
                      className={`btn bg-black-5 fs-15 padding-6 p-l-15 p-r-15 ${
                        loading ? "disabledBtn" : ""
                      }`}
                      disabled={loading}
                      onClick={() => reset()}
                    >
                      {`${
                        translationsLoading
                          ? "Cancel"
                          : fetchLabelKeyTranslation("CancelButton", "Cancel")
                      }`}
                    </Button>
                    <Button
                      className={`btn bg-primary fs-15 text-white padding-6 p-l-15 p-r-15 ${
                        loading ? "disabledBtn" : ""
                      }`}
                      disabled={loading}
                      onClick={onSaveClicked}
                    >
                      {loading ? (
                        <Loader
                          themeColor="light"
                          size="small"
                          type="infinite-spinner"
                        />
                      ) : (
                        "Save"
                      )}
                    </Button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserPermissions;
