var textField = "";

function compare( a:any, b:any) {
  if ( a[textField]?.toLocaleLowerCase() < b[textField]?.toLocaleLowerCase() ){
    return -1;
  }
  if ( a[textField]?.toLocaleLowerCase() > b[textField]?.toLocaleLowerCase() ){
    return 1;
  }
  return 0;
}

export const sortDataFunc = (data: any, _textField : any) : string => {
  var sortData = data;
  textField = _textField;

  if(data?.length > 1){
    sortData = data.sort(compare);
  }

  return sortData;
}

