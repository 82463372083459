import { FieldRenderProps } from "@progress/kendo-react-form";
import { Hint, Error } from "@progress/kendo-react-labels";
import { MentionsInput, Mention } from "react-mentions";
import "./CustomFormTextArea.scss";
import { mentionStyling } from "./CustomFormMentionStyling";
import { TinyUser } from "../../../types/user";
import CustomUserGridInfo from "../grid/CustomUserGridInfo";
import { ReactNode } from "react";

const CustomFormMention = (fieldRenderProps: FieldRenderProps) => {
  const {
    validationMessage,
    touched,
    label,
    id,
    valid,
    disabled,
    hint,
    type,
    optional,
    max,
    rows,
    value,
    showTextLimitHint,
    className,
    ...others
  } = fieldRenderProps;
  const showValidationMessage = touched && validationMessage;
  const showHint = !showValidationMessage && hint;
  const hindId = showHint ? `${id}_hint` : "";
  const errorId = showValidationMessage ? `${id}_error` : "";
  const userData = fieldRenderProps.data.map((field: any) => {
    field.display = field.email;
    return field;
  });

  return (
    <div>
      <MentionsInput
        singleLine={fieldRenderProps.singleLineInput}
        style={mentionStyling}
        value={value}
        {...others}
      >
        <Mention
          style={
            !fieldRenderProps.isMention
              ? { backgroundColor: "white" }
              : { backgroundColor: "#cee4e5" }
          }
          displayTransform={(id, display) =>
            !fieldRenderProps.isMention ? display : `@[${display}]`
          }
          appendSpaceOnAdd={true}
          trigger={!fieldRenderProps.isMention ? "@#Fsa@!@!sa%$%$%$09()(" : "@"}
          data={userData}
          markup={" @[__display__]* "}
          renderSuggestion={(
            suggestion: any,
            search: string,
            highlightedDisplay: ReactNode,
            index: number,
            focused: boolean
          ) => {
            const userInfo: TinyUser = {
              id: suggestion.id,
              loginUserId:suggestion.loginUserId,
              firstName: suggestion.firstName,
              lastName: suggestion.lastName,
              email: suggestion.email,
              phoneNo: suggestion.phoneNo,
              image: suggestion.image,
              isArchived: suggestion.isArchived,
            };
            return <CustomUserGridInfo userInfo={userInfo} />;
          }}
        />
      </MentionsInput>
      {showTextLimitHint && (
        <Hint direction={"end"}>
          {value.length} / {max}
        </Hint>
      )}
      {showHint && <Hint id={hindId}>{hint}</Hint>}
      {showValidationMessage && <Error id={errorId}>{validationMessage}</Error>}
    </div>
  );
};

export default CustomFormMention;
