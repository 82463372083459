import * as React from "react";
import { useState, useEffect } from "react";
import { Dictionary } from "../../../types/Dictionary";
import useLocale from "../../../hooks/useLocale";
import Swal from "sweetalert2";
import {
  NotificationEvaluation,
  GetNotificationRule,
} from "../../../types/notification-setup/NotificationSetup";
import { TinyObj } from "../../../types/TinyObj";
import { ColorCategory, Sentiment } from "../../../types/master-data";

interface NotificationSetupViewBoxData {
  notificationRule: GetNotificationRule;
  deleteNotificationRule: (id: number) => Promise<void>;
  editClickHandler: (notificationData: GetNotificationRule) => void;
}

const NotificationSetupViewBox: React.FC<NotificationSetupViewBoxData> = ({
  notificationRule,
  deleteNotificationRule,
  editClickHandler,
}) => {
  const localeCtx = useLocale();

  const [translationsLoading, setTranslationsLoading] =
    useState<boolean>(false);
  const [translations, setTranslations] = useState<
    Dictionary<string> | undefined
  >(
    localeCtx?.selectedLocale?.current.componentTranslations[
      "NotificationSetupViewBox"
    ]
  );

  useEffect(() => {
    if (
      !localeCtx?.selectedLocale?.current.componentTranslations[
        "NotificationSetupViewBox"
      ]
    ) {
      fetchTranslations();
    }
  }, [localeCtx?.selectedLocale]);

  const fetchTranslations = async () => {
    try {
      setTranslationsLoading(true);
      const resp = await localeCtx?.setComponentTranslations(
        "NotificationSetupViewBox"
      );
      setTranslations(resp);
    } catch (err) {
      console.error(err);
      setTranslations(
        localeCtx?.selectedLocale?.previous.componentTranslations[
          "NotificationSetupViewBox"
        ]
      );
      localeCtx?.setPreviousAppLocale("NotificationSetupViewBox");
      if (localeCtx?.localeSwitchFailed) {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Couldn't Switch Language",
        });
      }
    } finally {
      setTimeout(() => {
        setTranslationsLoading(false);
      }, 100);
    }
  };

  const fetchLabelKeyTranslation = (
    key: string,
    defaultValue: string
  ): string => {
    return translations && translations[key] ? translations[key] : defaultValue;
  };
  return (
    <>
      <div className="col col-md-4 notificationCol">
        <div className="cardListBlock">
          <div className="cardRows">
            <div className="cardColumn">
              <div className="colUser">
                <div className="tblUsr float-left w-100">
                  <div className="topUsrAreaPic w-100">
                    <div className="topUsrAreaPic-tx w-100">
                      <span
                        style={{ maxWidth: "95%" }}
                        className="text-black-13 fs-13 font-weight-semi cursor-default text-decoration-none float-left w-100 line-height-2 text-truncate"
                        title="RecordingPlayer Admin"
                      >
                        {notificationRule.name}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="lblGroupArea float-left w-100">
                <div className="userRowLbl d-flex justify-content-between p-t-10">
                  <div className="userColLbl d-flex align-items-center w-100">
                    <div
                      className="userLabels float-left fs-12 text-black-9"
                      style={{ minWidth: "70px" }}
                    >
                      {translationsLoading
                        ? "Sentiment"
                        : fetchLabelKeyTranslation(
                            "SentimentTitle",
                            "Sentiment"
                          )}
                    </div>
                    {/* style={{ color: "#ccc" }} */}
                    <div className="sentimentCircle p-t-3">
                      <span className="sentimentCircleDots p-r-5">
                        <i
                          className={`bi bi-emoji-smile-fill fs-16 ${
                            notificationRule.filterCriteria.sentiments.find(
                              (sentiment: Sentiment) =>
                                sentiment.name.toLowerCase() === "positive"
                            )
                              ? "text-success"
                              : "unselected-color"
                          }`}
                        ></i>
                      </span>

                      <span className="sentimentCircleDots p-r-5">
                        <i
                          className={`bi bi-emoji-neutral-fill fs-16 ${
                            notificationRule.filterCriteria.sentiments.find(
                              (sentiment: Sentiment) =>
                                sentiment.name.toLowerCase() === "neutral"
                            )
                              ? "text-warning"
                              : "unselected-color"
                          }`}
                        ></i>
                      </span>
                      <span className="sentimentCircleDots p-r-5">
                        <i
                          className={`bi bi-emoji-frown-fill fs-16  ${
                            notificationRule.filterCriteria.sentiments.find(
                              (sentiment: Sentiment) =>
                                sentiment.name.toLowerCase() === "negative"
                            )
                              ? "text-danger"
                              : "unselected-color"
                          }`}
                        ></i>
                      </span>
                    </div>
                  </div>

                  <div className="userColLbl d-flex align-items-center justify-content-end w-100">
                    <div className="sentimentCircle p-t-8">
                      <span className="sentimentCircleDots p-r-5">
                        <span
                          style={{
                            color:
                              notificationRule.filterCriteria.colorCategories.find(
                                (color: ColorCategory) =>
                                  color.name.toLowerCase() === "red"
                              )
                                ? "rgb(220, 53, 69)"
                                : "#ccc",
                          }}
                        >
                          <i className="bi bi-flag-fill fs-15"></i>
                        </span>
                      </span>

                      <span className="sentimentCircleDots p-r-5">
                        <span
                          style={{
                            color:
                              notificationRule.filterCriteria.colorCategories.find(
                                (color: ColorCategory) =>
                                  color.name.toLowerCase() === "orange"
                              )
                                ? "rgb(255, 165, 0)"
                                : "#ccc",
                          }}
                        >
                          <i className="bi bi-flag-fill fs-15"></i>
                        </span>
                      </span>

                      <span className="sentimentCircleDots p-r-5">
                        <span
                          style={{
                            color:
                              notificationRule.filterCriteria.colorCategories.find(
                                (color: ColorCategory) =>
                                  color.name.toLowerCase() === "purple"
                              )
                                ? "rgb(0, 0, 255)"
                                : "#ccc",
                          }}
                        >
                          <i className="bi bi-flag-fill fs-15"></i>
                        </span>
                      </span>

                      <span className="sentimentCircleDots p-r-5">
                        <span
                          style={{
                            color:
                              notificationRule.filterCriteria.colorCategories.find(
                                (color: ColorCategory) =>
                                  color.name.toLowerCase() === "yellow"
                              )
                                ? "rgb(255, 255, 0)"
                                : "#ccc",
                          }}
                        >
                          <i className="bi bi-flag-fill fs-15"></i>
                        </span>
                      </span>

                      <span className="sentimentCircleDots p-r-5">
                        <span
                          style={{
                            color:
                              notificationRule.filterCriteria.colorCategories.find(
                                (color: ColorCategory) =>
                                  color.name.toLowerCase() === "green"
                              )
                                ? "rgb(7, 209, 27)"
                                : "#ccc",
                          }}
                        >
                          <i className="bi bi-flag-fill fs-15"></i>
                        </span>
                      </span>

                      <span className="sentimentCircleDots p-r-5">
                        <span
                          style={{
                            color:
                              notificationRule.filterCriteria.colorCategories.find(
                                (color: ColorCategory) =>
                                  color.name.toLowerCase() === "blue"
                              )
                                ? "rgb(160, 32, 240)"
                                : "#ccc",
                          }}
                        >
                          <i className="bi bi-flag-fill fs-15"></i>
                        </span>
                      </span>
                    </div>
                    <div className="userLabels float-right fs-12 text-black-9">
                      {translationsLoading
                        ? "Flag"
                        : fetchLabelKeyTranslation("FlagTitle", "Flag")}
                    </div>
                  </div>
                </div>
                <div className="minTextLabel p-t-10">
                  <span
                    className="float-left text-black-9"
                    style={{ minWidth: "70px" }}
                  >
                    {translationsLoading
                      ? "Evaluation"
                      : fetchLabelKeyTranslation(
                          "EvaluationTitle",
                          "Evaluation"
                        )}
                  </span>
                  <div className="sentimentCircle">
                    <span
                      className={`sentimentCircleLbl ${
                        notificationRule.filterCriteria.evaluations.find(
                          (evaluation: NotificationEvaluation) =>
                            evaluation.name.toLowerCase() === "good"
                        )
                          ? "bg-success"
                          : "unselected-bgcolor"
                      } text-white fs-11 p-l-4 p-r-4 radius-3 m-r-5`}
                    >
                      Good
                    </span>

                    <span
                      className={`sentimentCircleLbl ${
                        notificationRule.filterCriteria.evaluations.find(
                          (evaluation: NotificationEvaluation) =>
                            evaluation.name.toLowerCase() === "average"
                        )
                          ? "bg-warning"
                          : "unselected-bgcolor"
                      } text-white fs-11 p-l-4 p-r-4 radius-3 m-r-5`}
                    >
                      Average
                    </span>

                    <span
                      className={`sentimentCircleLbl ${
                        notificationRule.filterCriteria.evaluations.find(
                          (evaluation: NotificationEvaluation) =>
                            evaluation.name.toLowerCase() === "poor"
                        )
                          ? "bg-danger"
                          : "unselected-bgcolor"
                      } text-white fs-11 p-l-4 p-r-4 radius-3 m-r-5`}
                    >
                      Poor
                    </span>

                    <span
                      className={`sentimentCircleLbl ${
                        notificationRule.filterCriteria.evaluations.find(
                          (evaluation: NotificationEvaluation) =>
                            evaluation.name.toLowerCase() === "notevaluated"
                        )
                          ? "bg-secondary"
                          : "unselected-bgcolor"
                      } text-white fs-11 p-l-4 p-r-4 radius-3 m-r-5`}
                    >
                      Not Evaluated
                    </span>
                  </div>
                </div>

                {notificationRule.filterCriteria.topics.length > 0 && (
                  <div className="minTextLabel p-t-7">
                    <span
                      className="float-left text-black-9"
                      style={{ minWidth: "70px" }}
                    >
                      {translationsLoading
                        ? "Topics"
                        : fetchLabelKeyTranslation("TopicsTitle", "Topics")}
                    </span>
                    <span className="p-l-3">
                      <span className="badgeList">
                        <div className="keywordRow">
                          {notificationRule.filterCriteria.topics.map(
                            (topic: TinyObj) => {
                              return (
                                <div className="keywordTrk">{topic.name}</div>
                              );
                            }
                          )}
                        </div>
                      </span>
                    </span>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="blockIconButton">
            <span className="btnBlocks">
              <div
                className="btnBlock-a"
                title={
                  translationsLoading
                    ? "Edit"
                    : fetchLabelKeyTranslation("EditButtonText", "Edit")
                }
              >
                <i
                  style={{ cursor: "pointer" }}
                  onClick={() => editClickHandler(notificationRule)}
                  className="bi bi-pencil fs-17"
                ></i>
              </div>
            </span>
            <span className="btnBlocks">
              <div
                className="btnBlock-a"
                title={
                  translationsLoading
                    ? "Delete"
                    : fetchLabelKeyTranslation("DeleteButtonText", "Delete")
                }
              >
                <i
                  style={{ cursor: "pointer" }}
                  onClick={() => deleteNotificationRule(notificationRule.id)}
                  className="bi bi-trash3 fs-17"
                ></i>
              </div>
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default NotificationSetupViewBox;
