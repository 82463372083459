import { config } from "process";
import { Notification } from "../types/notification/Notification";
import { axiosWithAuth } from "../utils/customAxios";

const notificationService = {
  getNotifications: async (
    endTime: string,
    startTime: string,
    latestNotificationsFetched:Boolean
  ): Promise<Notification[]> => {
    try {
      const response = !latestNotificationsFetched? await axiosWithAuth.get(
        `/notification?endtime=${endTime}&starttime=${startTime}`,{headers: {"x-tollring-automated": true}}
      ):await axiosWithAuth.get(
        `/notification?endtime=${endTime}&IsRead=${false}`
      )
      const notifications: Notification[] = response.data;
      return notifications;
    } catch (ex) {
      throw ex;
    }
  },

  sendNotifications: async (
    notifications: Notification[]
  ): Promise<boolean> => {
    try {
      const response = await axiosWithAuth.post(`/notification`, notifications);
      const insertedNotifications: Notification[] = response.data;
      return insertedNotifications.length > 0;
    } catch (ex) {
      throw ex;
    }
  },

  updateSentNotification: async (
    notification: Notification
  ): Promise<Notification> => {
    try {
      const response = await axiosWithAuth.put(
        `/notification/${notification.id}`,
        notification
      );
      const updatedNotification: Notification = response.data;
      return updatedNotification;
    } catch (ex) {
      throw ex;
    }
  },

  clearNotifications: async (
    ids:number[]
  ): Promise<boolean> => {
    try {
      const response = await axiosWithAuth.delete(`/notification`, {data:ids});
      return response.data;
    } catch (ex) {
      throw ex;
    }
  },
};

export default notificationService;
