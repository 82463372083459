import { useEffect, useRef, useState } from "react";
import { PanelBar, PanelBarItem } from "@progress/kendo-react-layout";
import { Button } from "@progress/kendo-react-buttons";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { InsightFilterMetric } from "./Insights";
import useAuth from "../../hooks/useAuth";
import useLocale from "../../hooks/useLocale";
import { User } from "../../types/user";
import { CallFilter } from "../../types/filters";
import { Dictionary } from "../../types/Dictionary";
import {
  Insight,
  InsightsFilter,
  KeywordInsightDetail,
} from "../../types/insight";
import CallFilterDrawer from "../../components/CallFilterDrawer";
import usersService from "../../services/users.service";
import { PeriodFilterTypes } from "../../data/PeriodFilterTypes";
import { formatDateTime } from "../../utils/dateTimeUtils";
import WordcloudWrapper from "../wordcloud/WordcloudWrapper";
import { ParentSize } from "@visx/responsive";
import useSwal from "../../hooks/useSwal";
import { SweetAlertOptions } from "sweetalert2";

interface CustomerDefinedMetricsCardProps {
  title: string;
  metrics?: { id: number; name: string; count: number; isPublic?: boolean }[];
  metricKeyLabel?: string;
  filterMetric: InsightFilterMetric;
  onMetricClickHandler: (metricKey: string, metricKeyLabel?: string) => void;
}

interface InsightMetricCardsProps {
  insight?: Insight;
  filterMetric: InsightFilterMetric;
  onMetricClickHandler: (
    metricKey: string,
    metricKeyLabel?: string,
    metricKeySubLabel?: string
  ) => void;
  insightsFilter?: InsightsFilter;
  onFilterUpdate: (filter: InsightsFilter) => void;
}

const InsightMetricCards: React.FC<InsightMetricCardsProps> = ({
  insight,
  filterMetric,
  onMetricClickHandler,
  insightsFilter,
  onFilterUpdate,
}) => {
  const localeCtx = useLocale();
  const auth = useAuth();
  const swal = useSwal();
  const [currentUserData, setCurrentUserData] = useState<User>();
  const [filterVisible, setFilterVisible] = useState<boolean>(false);
  const [translationsLoading, setTranslationsLoading] =
    useState<boolean>(false);
  const [translations, setTranslations] = useState<
    Dictionary<string> | undefined
  >(
    localeCtx?.selectedLocale?.current.componentTranslations[
      "InsightMetricCards"
    ]
  );

  const avgEvaluatedCalls = insight
    ? insight.totalRecordingsInsight.totalCalls > 0
      ? (
          (insight.qualityAssuranceInsight.total /
            insight.totalRecordingsInsight.totalCalls) *
          100
        ).toPrecision(4)
      : 0
    : 0;

  useEffect(() => {
    if (
      !localeCtx?.selectedLocale?.current.componentTranslations[
        "InsightMetricCards"
      ]
    ) {
      fetchTranslations();
    }
  }, [localeCtx?.selectedLocale]);

  useEffect(() => {
    fetchUserData();
  }, []);

  const fetchTranslations = async () => {
    try {
      setTranslationsLoading(true);
      const resp = await localeCtx?.setComponentTranslations(
        "InsightMetricCards"
      );
      setTranslations(resp);
    } catch (err) {
      console.error(err);
      setTranslations(
        localeCtx?.selectedLocale?.previous.componentTranslations[
          "InsightMetricCards"
        ]
      );
      localeCtx?.setPreviousAppLocale("InsightMetricCards");
      if (localeCtx?.localeSwitchFailed) {
        const swalOptions: SweetAlertOptions<any, any> = {
          icon: "error",
          title: "Error",
          text: "Couldn't Switch Language",
        };
        swal.fire(swalOptions);
      }
    } finally {
      setTimeout(() => {
        setTranslationsLoading(false);
      }, 100);
    }
  };

  const fetchLabelKeyTranslation = (
    key: string,
    defaultValue: string
  ): string => {
    return translations && translations[key] ? translations[key] : defaultValue;
  };

  const fetchUserData = async () => {
    try {
      const userData = await usersService.getCurrentUser();
      setCurrentUserData(userData);
    } catch (err) {
      console.error(err);
    }
  };

  const mappingForWordCloud = (keywords: KeywordInsightDetail[]) => {
    let mappedKeywords: { text: string; value: number; count: number }[] = [];

    keywords.map((val: KeywordInsightDetail) => {
      mappedKeywords.push({
        text: val.keyword,
        value: val.relevance,
        count: val.count,
      });
    });
    return mappedKeywords;
  };

  const CustomerDefinedMetricsCard: React.FC<
    CustomerDefinedMetricsCardProps
  > = ({
    title,
    metrics,
    metricKeyLabel,
    filterMetric,
    onMetricClickHandler,
  }) => {
    const panelTitle = (
      <div>
        {title}{" "}
        <span className="panelTitleBadge fs-13">{metrics?.length ?? 0}</span>
      </div>
    );

    return (
      <div className="card cardEffect">
        <div className="card-body p-0">
          <div className="expandableNav expandBox">
            <div className="panelbar-wrapper">
              <PanelBar animation={true}>
                <PanelBarItem
                  id={"1"}
                  title={panelTitle}
                  expanded={filterMetric.metricKeyLabel === metricKeyLabel}
                >
                  <div className="labelListing">
                    <div className="labelLi">
                      {metrics && metrics.length > 0 ? (
                        metrics.map((m) => (
                          <div
                            key={m.id}
                            className={`labelLiRow p-b-6 p-t-6 border-bottom-solid border-w-1 border-black-1 d-flex justify-content-between align-items-center cursor-pointer ${
                              filterMetric.metricKey === m.name &&
                              "activeBox bg-white"
                            }`}
                            onClick={(e) => {
                              onMetricClickHandler(m.name, metricKeyLabel);
                            }}
                          >
                            <div className="labelLiRow-p fs-14 cursor-pointer p-l-10">
                              <span className="padding-6 p-t-2 p-b-3">
                                {metricKeyLabel === "Topics" && (
                                  <i
                                    className={`bi ${
                                      m.isPublic ? "bi-people" : "bi-lock-fill"
                                    } m-r-6`}
                                  ></i>
                                )}
                                {metricKeyLabel === "Topics"
                                  ? m.name.substring(4)
                                  : m.name}
                              </span>
                            </div>
                            <div className="labelLiRow-p fs-20 font-weight-semi text-black-12 cursor-pointer p-r-10">
                              {m.count}
                            </div>
                          </div>
                        ))
                      ) : (
                        <div className="p-15">
                          <div className="labelLi-empty text-center text-black-10 p-10 m-b-15 float-left w-100 radius-6 border-black-3 border-w-2 border-dashed">
                            {`${title} : ${
                              translationsLoading
                                ? "Not available."
                                : fetchLabelKeyTranslation(
                                    "NAText",
                                    "Not available."
                                  )
                            }`}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </PanelBarItem>
              </PanelBar>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const onUserMetricClick = (metricKey: string, metricKeyLabel?: string) => {
    if (metricKeyLabel) {
      onMetricClickHandler(metricKey, metricKeyLabel);
    }
  };

  const toggleFilterVisibile = () => {
    setFilterVisible(!filterVisible);
  };

  const handleCallDataFilter = (callFilter: CallFilter) => {
    onFilterUpdate({
      ...callFilter,
      insightId: insightsFilter?.insightId,
    });
    toggleFilterVisibile();
  };

  const onUserRefreshDataClick = () => {
    onFilterUpdate({
      ...insightsFilter,
    });
  };

  return (
    <div className="rightBox">
      <div className="row p-b-12">
        <div className="col-md-12">
          <div className="card cardEffect">
            <div className="card-body p-0">
              <div className="expandableNav expandBox">
                <div className="">
                  <div className="filterPushDiv float-left w-100">
                    <div
                      className="filterPushDivInner float-left d-flex align-items-center w-100 fs-14 font-weight-semi p-t-3 p-r-10 p-b-3 p-l-15"
                      style={{ minHeight: "36px" }}
                    >
                      <div className="filterHeaderDiv float-left">
                        <span className="p-r-5">Date: </span>
                        <span className="panelTitleBadge fs-12">
                          {insightsFilter?.periodType === 4
                            ? insightsFilter.startDate && insightsFilter.endDate
                              ? formatDateTime(
                                  insightsFilter.startDate,
                                  "MMM DD, YYYY"
                                ) +
                                " - " +
                                formatDateTime(
                                  insightsFilter.endDate,
                                  "MMM DD, YYYY"
                                )
                              : "Custom"
                            : PeriodFilterTypes.find(
                                (e) => e.id === insightsFilter?.periodType
                              )?.filters?.find(
                                (el) => el.id === insightsFilter?.filterType
                              )?.title}
                        </span>
                      </div>
                      <span className="expandRowHdrIcon">
                        <Tooltip
                          anchorElement={"target"}
                          parentTitle={true}
                          position={"top"}
                        >
                          <Button
                            className="buttons-container-button radius-50 p-0"
                            style={{ width: "30px", height: "30px" }}
                            title="Refresh"
                            onClick={onUserRefreshDataClick}
                          >
                            <i className="bi bi-arrow-repeat fs-20 line-height-1 text-primary"></i>
                          </Button>

                          {filterVisible && (
                            <Button
                              className="buttons-container-button radius-50 p-0"
                              style={{ width: "30px", height: "30px" }}
                              title={"Close"}
                              onClick={() => toggleFilterVisibile()}
                            >
                              <i className="bi bi-x-lg fs-20 line-height-1 text-primary"></i>
                            </Button>
                          )}
                          {!filterVisible && (
                            <Button
                              className="buttons-container-button radius-50 p-0"
                              style={{ width: "30px", height: "30px" }}
                              title={"Filter"}
                              onClick={() => toggleFilterVisibile()}
                            >
                              <i className="bi bi-sliders fs-20 line-height-1 text-primary"></i>
                            </Button>
                          )}
                        </Tooltip>
                      </span>
                    </div>
                    {filterVisible && (
                      <CallFilterDrawer
                        userMasterData={currentUserData}
                        callFilterData={insightsFilter}
                        handleCallDataFilter={handleCallDataFilter}
                        handleFilterDialog={toggleFilterVisibile}
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row p-b-12">
        <div className="col-md-12">
          <div className="card cardEffect">
            <div className="card-body p-0">
              <div className="expandableNav navTitleCenter expandBox">
                <div className="panelbar-wrapper">
                  <PanelBar animation={true}>
                    <PanelBarItem title="Total" expanded={true}>
                      <div className="qaList float-left w-100 p-t-7 p-r-10 p-b-7 p-l-10">
                        <div className="kpiListRow float-left w-100">
                          <div className="kpiList">
                            <div
                              className={`kpiList-a bdr-purpleBlue cursor-pointer ${
                                filterMetric.metricKey === "TotalCalls" &&
                                "active"
                              }`}
                              onClick={(e) =>
                                onUserMetricClick(
                                  `TotalCalls`,
                                  insight?.totalRecordingsInsight.metricKeyLabel
                                )
                              }
                            >
                              <div className="kpiListTile">
                                <div className="kpiListTile-i fs-14 p-r-1">
                                  <i className="bi bi-record2"></i>
                                </div>
                                <div className="kpiListTile-tx fs-12">
                                  {`${
                                    translationsLoading
                                      ? "Recordings"
                                      : fetchLabelKeyTranslation(
                                          "TextRecordings",
                                          "Recordings"
                                        )
                                  }`}
                                </div>
                              </div>
                              <div className="kpiListval">
                                <div className="kpiListval-v fs-22">
                                  {insight?.totalRecordingsInsight.totalCalls ??
                                    0}
                                </div>
                                <div className="kpiListval-bdr bg-purpleBlue"></div>
                              </div>
                            </div>
                            <div
                              className={`kpiList-a bdr-success cursor-pointer ${
                                filterMetric.metricKey === "Received" &&
                                "active"
                              }`}
                              onClick={(e) =>
                                onUserMetricClick(
                                  `Received`,
                                  insight?.directionInsight.metricKeyLabel
                                )
                              }
                            >
                              <div className="kpiListTile">
                                <div className="kpiListTile-i fs-12 p-r-3">
                                  <i className="bi bi-telephone-inbound"></i>
                                </div>
                                <div className="kpiListTile-tx fs-13">
                                  {`${
                                    translationsLoading
                                      ? "Received"
                                      : fetchLabelKeyTranslation(
                                          "TextReceived",
                                          "Received"
                                        )
                                  }`}
                                </div>
                              </div>
                              <div className="kpiListval">
                                <div className="kpiListval-v fs-22">
                                  {insight?.directionInsight.incoming ?? 0}
                                </div>
                                <div className="kpiListval-bdr bg-success"></div>
                              </div>
                            </div>
                            <div
                              className={`kpiList-a bdr-skyBlue cursor-pointer ${
                                filterMetric.metricKey === "Dialled" && "active"
                              }`}
                              onClick={(e) =>
                                onUserMetricClick(
                                  `Dialled`,
                                  insight?.directionInsight.metricKeyLabel
                                )
                              }
                            >
                              <div className="kpiListTile">
                                <div className="kpiListTile-i fs-12 p-r-4">
                                  <i className="bi bi-telephone-outbound"></i>
                                </div>
                                <div className="kpiListTile-tx fs-13">
                                  {`${
                                    translationsLoading
                                      ? "Dialled"
                                      : fetchLabelKeyTranslation(
                                          "TextDialled",
                                          "Dialled"
                                        )
                                  }`}
                                </div>
                              </div>
                              <div className="kpiListval">
                                <div className="kpiListval-v fs-22">
                                  {insight?.directionInsight.dialled ?? 0}
                                </div>
                                <div className="kpiListval-bdr bg-skyBlue"></div>
                              </div>
                            </div>
                            <div
                              className={`kpiList-a bdr-purpleLight cursor-pointer ${
                                filterMetric.metricKey === "Transcribed" &&
                                "active"
                              }`}
                              onClick={(e) =>
                                onUserMetricClick(
                                  `Transcribed`,
                                  insight?.transcriptionInsight.metricKeyLabel
                                )
                              }
                            >
                              <div className="kpiListTile">
                                <div className="kpiListTile-i fs-13 p-r-1">
                                  <i className="bi bi-justify-right"></i>
                                </div>
                                <div className="kpiListTile-tx fs-12">
                                  {`${
                                    translationsLoading
                                      ? "Transcribed"
                                      : fetchLabelKeyTranslation(
                                          "TextTranscribed",
                                          "Transcribed"
                                        )
                                  }`}
                                </div>
                              </div>
                              <div className="kpiListval">
                                <div className="kpiListval-v fs-22">
                                  {insight?.transcriptionInsight.transcribed ??
                                    0}
                                </div>
                                <div className="kpiListval-bdr bg-purpleLight"></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </PanelBarItem>
                  </PanelBar>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row p-b-12">
        <div className="col-md-12">
          <div className="card cardEffect">
            <div className="card-body p-0">
              <div className="expandableNav navTitleCenter expandBox">
                <div className="panelbar-wrapper">
                  <PanelBar
                    animation={true}
                    onSelect={(e: any) => {
                      onUserMetricClick(
                        `Total`,
                        insight?.qualityAssuranceInsight.metricKeyLabel
                      );
                    }}
                  >
                    <PanelBarItem
                      title={`${
                        translationsLoading
                          ? "Total Evaluated"
                          : fetchLabelKeyTranslation(
                              "QAText",
                              "Total Evaluated"
                            )
                      } (${avgEvaluatedCalls}%) ${
                        insight?.qualityAssuranceInsight.total ?? 0
                      }`}
                    >
                      <div className="qaList p-t-7 p-r-10 p-b-7 p-l-10">
                        <div className="kpiListRow float-left w-100">
                          <div className="kpiList">
                            <div
                              className={`kpiList-a bdr-success cursor-pointer ${
                                filterMetric.metricKey === "Good" && "active"
                              }`}
                              onClick={(e) =>
                                onUserMetricClick(
                                  `Good`,
                                  insight?.qualityAssuranceInsight
                                    .metricKeyLabel
                                )
                              }
                            >
                              <div className="kpiListTile">
                                <div className="kpiListTile-tx fs-13">
                                  {`${
                                    translationsLoading
                                      ? "Good"
                                      : fetchLabelKeyTranslation(
                                          "TextGood",
                                          "Good"
                                        )
                                  }`}
                                </div>
                              </div>
                              <div className="kpiListval">
                                <div className="kpiListval-v fs-22">
                                  {insight?.qualityAssuranceInsight.good ?? 0}
                                </div>
                                <div className="kpiListval-bdr bg-success"></div>
                              </div>
                            </div>
                            <div
                              className={`kpiList-a bdr-warning cursor-pointer ${
                                filterMetric.metricKey === "Average" && "active"
                              }`}
                              onClick={(e) =>
                                onUserMetricClick(
                                  `Average`,
                                  insight?.qualityAssuranceInsight
                                    .metricKeyLabel
                                )
                              }
                            >
                              <div className="kpiListTile">
                                <div className="kpiListTile-tx fs-13">
                                  {`${
                                    translationsLoading
                                      ? "Average"
                                      : fetchLabelKeyTranslation(
                                          "TextAverage",
                                          "Average"
                                        )
                                  }`}
                                </div>
                              </div>
                              <div className="kpiListval">
                                <div className="kpiListval-v fs-22">
                                  {insight?.qualityAssuranceInsight.average ??
                                    0}
                                </div>
                                <div className="kpiListval-bdr bg-warning"></div>
                              </div>
                            </div>
                            <div
                              className={`kpiList-a bdr-danger cursor-pointer ${
                                filterMetric.metricKey === "Poor" && "active"
                              }`}
                              onClick={(e) =>
                                onUserMetricClick(
                                  `Poor`,
                                  insight?.qualityAssuranceInsight
                                    .metricKeyLabel
                                )
                              }
                            >
                              <div className="kpiListTile">
                                <div className="kpiListTile-tx fs-12">
                                  {`${
                                    translationsLoading
                                      ? "Poor"
                                      : fetchLabelKeyTranslation(
                                          "TextPoor",
                                          "Poor"
                                        )
                                  }`}
                                </div>
                              </div>
                              <div className="kpiListval">
                                <div className="kpiListval-v fs-22">
                                  {insight?.qualityAssuranceInsight.poor ?? 0}
                                </div>
                                <div className="kpiListval-bdr bg-danger"></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </PanelBarItem>
                  </PanelBar>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row p-b-12">
        <div className="col-md-12">
          <div className="card cardEffect">
            <div className="card-body p-0">
              <div className="expandableNav expandBox">
                <div className="SentimentList p-t-7 p-r-10 p-b-7 p-l-10">
                  <div className=" d-flex justify-content-between align-items-center">
                    <div className="float-left fs-14 font-weight-semi">
                      {`${
                        translationsLoading
                          ? "Sentiment"
                          : fetchLabelKeyTranslation(
                              "TextSentiment",
                              "Sentiment"
                            )
                      }`}
                    </div>
                    <div className="iconRow d-flex">
                      <div
                        className={`iconCol d-flex align-items-center p-r-10 p-l-10 ${
                          filterMetric.metricKey === "Positive" && "activeBox"
                        }`}
                        onClick={(e) =>
                          onUserMetricClick(
                            `Positive`,
                            insight?.sentimentInsight.metricKeyLabel
                          )
                        }
                      >
                        <div
                          className="iconCol-i p-r-5 cursor-pointer"
                          style={{ height: "22px" }}
                        >
                          <i className="bi bi-emoji-smile-fill fs-21 text-success"></i>
                        </div>
                        <div className="iconCol-val fs-20 text-black-11 cursor-pointer">
                          {insight?.sentimentInsight.positive ?? 0}
                        </div>
                      </div>
                      <div
                        className={`iconCol d-flex align-items-center p-r-10 p-l-10 cursor-pointer ${
                          filterMetric.metricKey === "Neutral" && "activeBox"
                        }`}
                        onClick={(e) =>
                          onUserMetricClick(
                            `Neutral`,
                            insight?.sentimentInsight.metricKeyLabel
                          )
                        }
                      >
                        <div
                          className="iconCol-i p-r-5 cursor-pointer"
                          style={{ height: "22px" }}
                        >
                          <i className="bi bi-emoji-neutral-fill fs-21 text-warning"></i>
                        </div>
                        <div className="iconCol-val fs-20 text-black-11 cursor-pointer">
                          {insight?.sentimentInsight.neutral ?? 0}
                        </div>
                      </div>
                      <div
                        className={`iconCol d-flex align-items-center p-r-10 p-l-10 cursor-pointer ${
                          filterMetric.metricKey === "Negative" && "activeBox"
                        }`}
                        onClick={(e) =>
                          onUserMetricClick(
                            `Negative`,
                            insight?.sentimentInsight.metricKeyLabel
                          )
                        }
                      >
                        <div
                          className="iconCol-i p-r-5 cursor-pointer"
                          style={{ height: "22px" }}
                        >
                          <i className="bi bi-emoji-frown-fill fs-21 text-danger"></i>
                        </div>
                        <div className="iconCol-val fs-20 text-black-11 cursor-pointer">
                          {insight?.sentimentInsight.negative ?? 0}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row p-b-12">
        <div className="col-md-12">
          <div className="card cardEffect">
            <div className="card-body p-0">
              <div className="expandableNav expandBox">
                <div className="panelbar-wrapper">
                  <PanelBar animation={true}>
                    <PanelBarItem
                      title={
                        <div>
                          {`${
                            translationsLoading
                              ? "Total Flagged"
                              : fetchLabelKeyTranslation(
                                  "TextTotalFlagged",
                                  "Total Flagged"
                                )
                          }`}{" "}
                          <span className="panelTitleBadge fs-13">
                            {insight?.callFlagsInsight.flaggedCalls.length
                              ? insight.callFlagsInsight.flaggedCalls.reduce(
                                  (accumulator, object) => {
                                    return accumulator + object.count;
                                  },
                                  0
                                )
                              : 0}
                          </span>
                        </div>
                      }
                    >
                      <div className="labelListing">
                        <div className="labelLi">
                          {insight &&
                          insight?.callFlagsInsight.flaggedCalls.length > 0 ? (
                            insight?.callFlagsInsight.flaggedCalls.map((f) => {
                              return (
                                <div
                                  key={f.id}
                                  className={`labelLiRow p-b-6 p-t-6 border-bottom-solid border-w-1 border-black-1 d-flex justify-content-between align-items-center cursor-pointer ${
                                    filterMetric.metricKey === f.name &&
                                    "activeBox bg-white"
                                  }`}
                                  onClick={(e) =>
                                    onUserMetricClick(
                                      f.name,
                                      insight?.callFlagsInsight.metricKeyLabel
                                    )
                                  }
                                >
                                  <div className="labelLiRow-p fs-14 cursor-pointer p-l-10">
                                    <span
                                      className="padding-6 p-t-2 p-b-3"
                                      style={{ color: f.hexCode }}
                                    >
                                      <i className="bi bi-flag-fill"></i>
                                    </span>
                                  </div>
                                  <div className="labelLiRow-p fs-20 font-weight-semi text-black-12 cursor-pointer p-r-10">
                                    {f.count}
                                  </div>
                                </div>
                              );
                            })
                          ) : (
                            <div className="p-15">
                              <div className="labelLi-empty text-center text-black-10 p-10 m-b-15 float-left w-100 radius-6 border-black-3 border-w-2 border-dashed">
                                {`${
                                  translationsLoading
                                    ? "No Call flags available."
                                    : fetchLabelKeyTranslation(
                                        "MsgNoFlagCallAvailable",
                                        "No Call flags available."
                                      )
                                }`}
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </PanelBarItem>
                  </PanelBar>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {auth?.checkTopicAvailibility() && (
        <div className="row p-b-12">
          <div className="col-md-12">
            <div className="card cardEffect">
              <div className="card-body p-0">
                <div className="expandableNav expandBox">
                  <div className="panelbar-wrapper">
                    <PanelBar animation={true}>
                      <PanelBarItem
                        id={"1"}
                        title={
                          <div>
                            {translationsLoading
                              ? "Topics"
                              : fetchLabelKeyTranslation(
                                  "TextTopics",
                                  "Topics"
                                )}{" "}
                            <span className="panelTitleBadge fs-13">
                              {insight?.topicsInsight.topics.length ?? 0}
                            </span>
                          </div>
                        }
                        expanded={
                          filterMetric.metricKeyLabel ===
                          insight?.topicsInsight.metricKeyLabel
                        }
                      >
                        <div className="labelListing labelLiFix">
                          <div className="labelLi">
                            {insight?.topicsInsight.topics &&
                            insight?.topicsInsight.topics.length > 0 ? (
                              insight?.topicsInsight.topics.map((m) => (
                                <div
                                  key={m.id}
                                  className={`labelLiRow p-b-6 p-t-6 border-bottom-solid border-w-1 border-black-1 d-flex justify-content-between align-items-center cursor-pointer ${
                                    filterMetric.metricKey === m.name &&
                                    "activeBox bg-white"
                                  }`}
                                >
                                  <div className="labelLiRow-p fs-14 p-l-1 topicLblLeft">
                                    <span className="padding-6 p-t-2 p-b-3 float-left labelLiParaTxtOuter">
                                      {
                                        <i
                                          className={`bi ${
                                            m.isPublic
                                              ? "bi-people"
                                              : "bi-lock-fill"
                                          } m-r-6`}
                                        ></i>
                                      }

                                      <span className="labelLiParaTxtMain">
                                        <span className="labelLiParaTxt">
                                          {m.name.substring(4)}
                                        </span>

                                        <span className="boxLabelAI m-l-4 bg-primary tx-white">
                                          {m.isExtendedSearch
                                            ? "EXTENDED"
                                            : "EXACT"}
                                        </span>
                                      </span>
                                    </span>
                                  </div>
                                  <div className="labelLiRow-p fs-20 font-weight-semi text-black-12 cursor-pointer p-r-1 topicLblRight">
                                    <div className="float-right w-100">
                                      <div className="keywordRow float-right keyWordRight">
                                        {m.isExtendedSearch ? (
                                          <>
                                            <div
                                              className={`keywordTrk m-b-1 fs-15 bg-greenDLL cursor-pointer  w-auto ${
                                                filterMetric.metricKey ===
                                                  m.name &&
                                                filterMetric.metricKeySubLabel ===
                                                  "HighRelevanceTopic" &&
                                                "activeBox bg-white"
                                              }`}
                                              title={"High Relevance"}
                                              onClick={(e) => {
                                                onMetricClickHandler(
                                                  m.name,
                                                  insight?.topicsInsight
                                                    .metricKeyLabel,
                                                  "HighRelevanceTopic"
                                                );
                                              }}
                                            >
                                              {m.highRelevanceCallCnt}
                                            </div>
                                            <div
                                              className={`keywordTrk m-b-1 fs-15 cursor-pointer  w-auto ${
                                                filterMetric.metricKey ===
                                                  m.name &&
                                                filterMetric.metricKeySubLabel ===
                                                  "MediumRelevanceTopic" &&
                                                "activeBox"
                                              }
                                                `}
                                              title={"Medium Relevance"}
                                              onClick={(e) => {
                                                onMetricClickHandler(
                                                  m.name,
                                                  insight?.topicsInsight
                                                    .metricKeyLabel,
                                                  "MediumRelevanceTopic"
                                                );
                                              }}
                                            >
                                              {m.mediumRelevanceCallCnt}
                                            </div>
                                            <div
                                              className={`keywordTrk float-right m-b-1 fs-15 bg-yellowDLL cursor-pointer  w-auto ${
                                                filterMetric.metricKey ===
                                                  m.name &&
                                                filterMetric.metricKeySubLabel ===
                                                  "LowRelevanceTopic" &&
                                                "activeBox"
                                              }`}
                                              title={"Low Relevance"}
                                              onClick={(e) => {
                                                onMetricClickHandler(
                                                  m.name,
                                                  insight?.topicsInsight
                                                    .metricKeyLabel,
                                                  "LowRelevanceTopic"
                                                );
                                              }}
                                            >
                                              {m.lowRelevanceCallCnt}
                                            </div>
                                          </>
                                        ) : (
                                          <div
                                            className={`keywordTrk float-right m-b-1 fs-15 bg-greenDarkDLL cursor-pointer w-auto ${
                                              filterMetric.metricKey ===
                                                m.name &&
                                              filterMetric.metricKeySubLabel ===
                                                "ExactMatchTopic" &&
                                              "activeBox"
                                            }`}
                                            title={"Exact Match"}
                                            onClick={(e) => {
                                              onMetricClickHandler(
                                                m.name,
                                                insight?.topicsInsight
                                                  .metricKeyLabel,
                                                "ExactMatchTopic"
                                              );
                                            }}
                                          >
                                            {m.exactMatchCallCnt}
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ))
                            ) : (
                              <div className="p-15">
                                <div className="labelLi-empty text-center text-black-10 p-10 m-b-15 float-left w-100 radius-6 border-black-3 border-w-2 border-dashed">
                                  {`${
                                    translationsLoading
                                      ? "Topics"
                                      : fetchLabelKeyTranslation(
                                          "TextTopics",
                                          "Topics"
                                        )
                                  } : 
                                  ${
                                    translationsLoading
                                      ? "Not available."
                                      : fetchLabelKeyTranslation(
                                          "NAText",
                                          "Not available."
                                        )
                                  }`}
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      </PanelBarItem>
                    </PanelBar>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {/* <div className="row p-b-12">
        <div className="col-md-12">
          <CustomerDefinedMetricsCard
            title={`${
              translationsLoading
                ? "Exact Search Topic"
                : fetchLabelKeyTranslation("", "Exact Search Topic")
            }`}
            metrics={insight?.exactMatchTopicsInsight.moments}
            metricKeyLabel={insight?.exactMatchTopicsInsight.metricKeyLabel}
            filterMetric={filterMetric}
            onMetricClickHandler={onUserMetricClick}
          />
        </div>
      </div> */}
      <div className="row p-b-12">
        <div className="col-md-12">
          <CustomerDefinedMetricsCard
            title={`${
              translationsLoading
                ? "Outcome"
                : fetchLabelKeyTranslation("TextOutcome", "Outcome")
            }`}
            metrics={insight?.callResultsInsight.callResults}
            metricKeyLabel={insight?.callResultsInsight.metricKeyLabel}
            filterMetric={filterMetric}
            onMetricClickHandler={onUserMetricClick}
          />
        </div>
      </div>
      <div className="row p-b-12">
        <div className="col-md-12">
          <div className="card cardEffect">
            <div className="card-body p-0">
              <div className="expandableNav expandBox">
                <div className="panelbar-wrapper">
                  <PanelBar animation={true}>
                    <PanelBarItem title={<div>Word Cloud</div>}>
                      <div className="labelListing">
                        {insight != undefined && (
                          <ParentSize>
                            {({ width }) => (
                              <WordcloudWrapper
                                width={width}
                                height={310}
                                wordcloudData={mappingForWordCloud(
                                  insight?.wordCloudInsight.keywords
                                )}
                                onMetricClickHandler={onUserMetricClick}
                              />
                            )}
                          </ParentSize>
                        )}
                      </div>
                    </PanelBarItem>
                  </PanelBar>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div></div>
    </div>
  );
};

export default InsightMetricCards;
