import { Button } from "@progress/kendo-react-buttons";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import {
  Field,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { useEffect, useRef, useState } from "react";
import CustomInput from "../../../components/custom/form/CustomInput";
import useLocale from "../../../hooks/useLocale";
import { Dictionary } from "../../../types/Dictionary";
import {
  InsertScorecardQuestion,
  Scorecard,
  ScorecardQuestion,
  UpdateScorecardQuestion,
} from "../../../types/scorecard";
import { axiosWithAuth } from "../../../utils/customAxios";
import useSwal from "../../../hooks/useSwal";
import { SweetAlertOptions } from "sweetalert2";

interface IUpsertQuestionDialogBox {
  toggleDialog: (question: ScorecardQuestion | undefined) => void;
  question: ScorecardQuestion | undefined;
  scorecardId: number;
  scorecardGroupId: number;
  upsertSC: () => void;
}

const UpsertQuestionDialogBox: React.FC<IUpsertQuestionDialogBox> = ({
  toggleDialog,
  question,
  scorecardId,
  scorecardGroupId,
  upsertSC,
}) => {
  const formRef = useRef<any>();
  const localeCtx = useLocale();
  const swal = useSwal();
  const [translationsLoading, setTranslationsLoading] =
    useState<boolean>(false);
  const [translations, setTranslations] = useState<
    Dictionary<string> | undefined
  >(
    localeCtx?.selectedLocale?.current.componentTranslations[
      "UpsertQuestionDialogBox"
    ]
  );

  useEffect(() => {
    fetchTranslations();
  }, [localeCtx?.selectedLocale]);

  const fetchTranslations = async () => {
    try {
      setTranslationsLoading(true);
      const resp = await localeCtx?.setComponentTranslations(
        "UpsertQuestionDialogBox"
      );
      setTranslations(resp);
    } catch (err) {
      console.error(err);
      setTranslations(
        localeCtx?.selectedLocale?.previous.componentTranslations[
          "UpsertQuestionDialogBox"
        ]
      );
      localeCtx?.setPreviousAppLocale("UpsertQuestionDialogBox");
      if (localeCtx?.localeSwitchFailed) {
        const swalOptions: SweetAlertOptions<any, any> = {
          icon: "error",
          title: "Error",
          text: "Couldn't Switch Language",
        };
        swal.fire(swalOptions);
      }
    } finally {
      setTimeout(() => {
        setTranslationsLoading(false);
      }, 100);
    }
  };

  const fetchLabelKeyTranslation = (
    key: string,
    defaultValue: string
  ): string => {
    return translations && translations[key] ? translations[key] : defaultValue;
  };

  const addQuestion = async (newQuestion: InsertScorecardQuestion) => {
    await axiosWithAuth
      .post(
        `scorecard/${scorecardId}/scorecardquestiongroup/${scorecardGroupId}/scorecardquestion`,
        newQuestion
      )
      .then((response) => {
        const swalOptions: SweetAlertOptions<any, any> = {
          icon: "success",
          title: `${
            translationsLoading
              ? "Scorecard Question added"
              : fetchLabelKeyTranslation(
                  "SwtAltQuestionAddedTitle",
                  "Scorecard Question added"
                )
          }`,
          text: `${
            translationsLoading
              ? "Your Scorecard question has been added "
              : fetchLabelKeyTranslation(
                  "SwtAltQuestionAddedText",
                  "Your Scorecard question has been added"
                )
          }`,
          confirmButtonText: `${
            translationsLoading
              ? "OK"
              : fetchLabelKeyTranslation("OKText", "OK")
          }`,
        };
        swal.fire(swalOptions);
      })
      .catch((err) => {
        if (err.response.status === 405) {
          const swalOptions: SweetAlertOptions<any, any> = {
            icon: "info",
            title: `${
              translationsLoading
                ? "Scorecard cannot be updated"
                : fetchLabelKeyTranslation(
                    "SwtAltAddFailedTitle",
                    "Scorecard cannot be updated"
                  )
            }`,
            text: `${
              translationsLoading
                ? "You can archive the Scorecard"
                : fetchLabelKeyTranslation(
                    "SwtAltAddFailedText",
                    "You can archive the Scorecard"
                  )
            }`,
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: `${
              translationsLoading
                ? "Yes, Archive it!"
                : fetchLabelKeyTranslation(
                    "SwtAltArchiveCfnBtnText",
                    "Yes, Archive it!"
                  )
            }`,
            cancelButtonText: `${
              translationsLoading
                ? "Cancel"
                : fetchLabelKeyTranslation("CancelText", "Cancel")
            }`,
          };
          swal.fire(swalOptions).then(async (result) => {
            if (result.isConfirmed) {
              const updatedScorecard: Scorecard = {
                id: scorecardId ? Number(scorecardId) : 0,
                scorecardName: "",
                description: "",
                isArchived: true,
              };
              await axiosWithAuth
                .put(`/scorecard/${scorecardId}`, updatedScorecard)
                .then(async (response) => {
                  const swalOptions: SweetAlertOptions<any, any> = {
                    icon: "success",
                    title: `${
                      translationsLoading
                        ? "Scorecard Archived"
                        : fetchLabelKeyTranslation(
                            "SwtAltArchiveSuccessTitle",
                            "Scorecard Archived"
                          )
                    }`,
                    text: `${
                      translationsLoading
                        ? "Scorecard has been archived Sucessfully"
                        : fetchLabelKeyTranslation(
                            "SwtAltArchiveSuccessText",
                            "Scorecard has been archived Sucessfully"
                          )
                    }`,
                    confirmButtonText: `${
                      translationsLoading
                        ? "OK"
                        : fetchLabelKeyTranslation("OKText", "OK")
                    }`,
                  };
                  swal.fire(swalOptions);
                  await upsertSC();
                });
            }
          });
        } else {
          const swalOptions: SweetAlertOptions<any, any> = {
            icon: "warning",
            title: `${
              translationsLoading
                ? "Something went wrong!"
                : fetchLabelKeyTranslation(
                    "SwtAltFailedTxt",
                    "Something went wrong!"
                  )
            }`,
            text: `${
              translationsLoading
                ? "Scorecard question group updation has been failed"
                : fetchLabelKeyTranslation(
                    "SwtAltFailedText",
                    "Scorecard question group updation has been failed"
                  )
            }`,
            confirmButtonText: `${
              translationsLoading
                ? "OK"
                : fetchLabelKeyTranslation("OKText", "OK")
            }`,
          };
          swal.fire(swalOptions);
        }
      });
    toggleDialog(undefined);
    await upsertSC();
  };

  const editQuestion = async (newQuestion: UpdateScorecardQuestion) => {
    await axiosWithAuth
      .put(
        `scorecard/${scorecardId}/scorecardquestiongroup/${scorecardGroupId}/scorecardquestion/${newQuestion.id}`,
        newQuestion
      )
      .then((response) => {
        const swalOptions: SweetAlertOptions<any, any> = {
          icon: "success",
          title: `${
            translationsLoading
              ? "Scorecard Question updated"
              : fetchLabelKeyTranslation(
                  "SwtAltSCQuesUpdatedSuccessTitle",
                  "Scorecard Question updated"
                )
          }`,
          text: `${
            translationsLoading
              ? "Your Scorecard question has been updated "
              : fetchLabelKeyTranslation(
                  "SwtAltSCQuesUpdatedSuccessText",
                  "Your Scorecard question has been updated"
                )
          }`,
          confirmButtonText: `${
            translationsLoading
              ? "OK"
              : fetchLabelKeyTranslation("OKText", "OK")
          }`,
        };
        swal.fire(swalOptions);
      })
      .catch((err) => {
        if (err.response.status === 405) {
          const swalOptions: SweetAlertOptions<any, any> = {
            icon: "info",
            title: `${
              translationsLoading
                ? "Scorecard cannot be updated"
                : fetchLabelKeyTranslation(
                    "SwtAltAddFailedTitle",
                    "Scorecard cannot be updated"
                  )
            }`,
            text: `${
              translationsLoading
                ? "You can archive the Scorecard"
                : fetchLabelKeyTranslation(
                    "SwtAltAddFailedText",
                    "You can archive the Scorecard"
                  )
            }`,
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: `${
              translationsLoading
                ? "Yes, Archive it!"
                : fetchLabelKeyTranslation(
                    "SwtAltArchiveCfnBtnText",
                    "Yes, Archive it!"
                  )
            }`,
            cancelButtonText: `${
              translationsLoading
                ? "Cancel"
                : fetchLabelKeyTranslation("CancelText", "Cancel")
            }`,
          };
          swal.fire(swalOptions).then(async (result) => {
            if (result.isConfirmed) {
              const updatedScorecard: Scorecard = {
                id: scorecardId ? Number(scorecardId) : 0,
                scorecardName: "",
                description: "",
                isArchived: true,
              };
              await axiosWithAuth
                .put(`/scorecard/${scorecardId}`, updatedScorecard)
                .then(async (response) => {
                  const swalOptions: SweetAlertOptions<any, any> = {
                    icon: "success",
                    title: `${
                      translationsLoading
                        ? "Scorecard Archived"
                        : fetchLabelKeyTranslation(
                            "SwtAltArchiveSuccessTitle",
                            "Scorecard Archived"
                          )
                    }`,
                    text: `${
                      translationsLoading
                        ? "Scorecard has been archived Sucessfully"
                        : fetchLabelKeyTranslation(
                            "SwtAltArchiveSuccessText",
                            "Scorecard has been archived Sucessfully"
                          )
                    }`,
                    confirmButtonText: `${
                      translationsLoading
                        ? "OK"
                        : fetchLabelKeyTranslation("OKText", "OK")
                    }`,
                  };
                  swal.fire(swalOptions);
                  await upsertSC();
                });
            }
          });
        } else {
          const swalOptions: SweetAlertOptions<any, any> = {
            icon: "warning",
            title: `${
              translationsLoading
                ? "Something went wrong!"
                : fetchLabelKeyTranslation(
                    "SwtAltFailedTxt",
                    "Something went wrong!"
                  )
            }`,
            text: `${
              translationsLoading
                ? "Scorecard question group updation has been failed"
                : fetchLabelKeyTranslation(
                    "SwtAltFailedText",
                    "Scorecard question group updation has been failed"
                  )
            }`,
            confirmButtonText: `${
              translationsLoading
                ? "OK"
                : fetchLabelKeyTranslation("OKText", "OK")
            }`,
          };
          swal.fire(swalOptions);
        }
      });
    toggleDialog(undefined);
    await upsertSC();
  };

  const nameValidator = (value: string) => {
    if (!value) {
      return `${
        translationsLoading
          ? "Question Text cannot be empty"
          : fetchLabelKeyTranslation(
              "SCQuestionNameValidationEmptyMsg",
              "Question Text cannot be empty"
            )
      }`;
    }
    if (value.length > 500) {
      return `${
        translationsLoading
          ? "Question Text length cannot be more than 500!"
          : fetchLabelKeyTranslation(
              "SCQuestionNameValidationLengthMsg",
              "Question Text length cannot be more than 500!"
            )
      }`;
    }

    return "";
  };

  const handleUpsert = async () => {
    const form = formRef.current as Form;
    const createNewQuestion: InsertScorecardQuestion = {
      questionText: form.values.questionText,
    };
    const updateNewQuestion: UpdateScorecardQuestion = {
      id: question?.id ?? 0,
      questionText: form.values.questionText,
    };
    question
      ? await editQuestion(updateNewQuestion)
      : await addQuestion(createNewQuestion);
  };
  return (
    <Form
      ref={formRef}
      initialValues={{
        questionText: question?.questionText ? question?.questionText : "",
      }}
      render={(formRenderProps: FormRenderProps) => (
        <FormElement style={{ maxWidth: 650 }}>
          <Dialog
            title={
              question
                ? `${
                    translationsLoading
                      ? "Edit Question"
                      : fetchLabelKeyTranslation(
                          "TitleEditQuestion",
                          "Edit Question"
                        )
                  }`
                : `${
                    translationsLoading
                      ? "Add Question"
                      : fetchLabelKeyTranslation(
                          "TitleAddQuestion",
                          "Add Question"
                        )
                  }`
            }
            onClose={() => toggleDialog(undefined)}
          >
            <div className="formAdd" style={{ marginBottom: "0px" }}>
              <div className="formBoxRow p-t-5 p-b-5">
                <div className="formBoxAction">
                  <div className="formInput">
                    <Field
                      id="questionText"
                      name="questionText"
                      style={{ height: "32px" }}
                      value={formRenderProps.valueGetter("questionText")}
                      placeholder={`${
                        translationsLoading
                          ? "Enter question ..."
                          : fetchLabelKeyTranslation(
                              "FieldQuesTextPlaceholder",
                              "Enter question ..."
                            )
                      }`}
                      component={CustomInput}
                      validator={nameValidator}
                    />
                  </div>
                </div>
              </div>
            </div>
            <DialogActionsBar>
              <Button
                className={`btn bg-black-5`}
                onClick={() => toggleDialog(undefined)}
              >
                {`${
                  translationsLoading
                    ? "Cancel"
                    : fetchLabelKeyTranslation("CancelText", "Cancel")
                }`}
              </Button>
              <Button
                className={`btn bg-primary text-white ${
                  !formRenderProps.valid ? "disabledBtn" : ""
                }`}
                disabled={!formRenderProps.valid}
                onClick={handleUpsert}
              >
                {question
                  ? `${
                      translationsLoading
                        ? "Edit"
                        : fetchLabelKeyTranslation("EditBtnText", "Edit")
                    }`
                  : `${
                      translationsLoading
                        ? "Add"
                        : fetchLabelKeyTranslation("AddButtonText", "Add")
                    }`}
              </Button>
            </DialogActionsBar>
          </Dialog>
        </FormElement>
      )}
    />
  );
};

export default UpsertQuestionDialogBox;
